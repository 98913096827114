var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"input-wrapper",class:{
    'input-wrapper--invalid': _vm.invalid,
    'input-wrapper--datepicker': _vm.datepicker,
    'input-wrapper--white':
      _vm.$route.name === 'newVacation' || _vm.$route.name === 'editVacation',
  }},[(_vm.label)?_c('label',[_vm._v(" "+_vm._s(_vm.label)+" ")]):_vm._e(),(_vm.list)?[_c('div',{staticClass:"dropdown-toggle"},[(!_vm.value)?[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.search),expression:"search"}],ref:"dd",attrs:{"type":"text","placeholder":_vm.placeholder,"disabled":_vm.disabled},domProps:{"value":(_vm.search)},on:{"focus":function($event){(_vm.open = true), _vm.$emit('focus')},"blur":function($event){_vm.open = false},"input":function($event){if($event.target.composing)return;_vm.search=$event.target.value}}}),_c('span',{staticClass:"icon",class:{ icon__reversed: _vm.up },on:{"click":function($event){return _vm.$refs.dd.focus()}}},[_vm._v(" E ")])]:[_c('input',{attrs:{"type":"text","disabled":"true"},domProps:{"value":_vm.prop ? _vm.value[_vm.prop] : _vm.value}}),(!_vm.disabled)?_c('span',{staticClass:"icon",on:{"click":function($event){return _vm.$emit('input', null)}}},[_vm._v("g")]):_vm._e()]],2),(_vm.open)?_c('div',{staticClass:"input-wrapper__dropdown",class:{ 'input-wrapper__dropdown--up': _vm.up }},[_vm._l((_vm.filteredList),function(l,i){return _c('p',{key:`dd-l-${i}`,on:{"mousedown":function($event){_vm.$emit('input', l), _vm.$emit('item-selected'), (_vm.open = false)}}},[_vm._v(" "+_vm._s(_vm.prop ? l[_vm.prop] : l)+" ")])}),(!_vm.filteredList.length)?_c('p',{staticClass:"empty"},[_vm._v("няма резултати")]):_vm._e()],2):_vm._e()]:(_vm.datepicker)?_c('div',{staticClass:"fake-input",class:{
      'fake-input--active': _vm.isDatepickerModalOpen,
      'fake-input--disabled': _vm.disabled,
    },on:{"click":function($event){_vm.disabled ? null : (_vm.isDatepickerModalOpen = true)}}},[(_vm.month)?[_vm._v(" "+_vm._s(_vm._f("formatDateMonth")(_vm.value))+" ")]:[_vm._v(" "+_vm._s(_vm._f("formatDate")(_vm.value))+" ")]],2):(_vm.timepicker)?_c('div',{staticClass:"fake-input",class:{
      'fake-input--active': _vm.isTimepickerModalOpen,
      'fake-input--disabled': _vm.disabled,
    },on:{"click":function($event){_vm.disabled ? null : (_vm.isTimepickerModalOpen = true)}}},[_vm._v(" "+_vm._s(_vm.value || "ЧЧ:ММ")+" ")]):(_vm.type === 'file')?_c('label',{staticClass:"fake-input fake-input--file",class:{ 'fake-input--active': false, 'fake-input--disabled': _vm.disabled },attrs:{"for":`file-${_vm._uid}`}},[(!_vm.files.length)?_c('span',[_vm._v("Прикачване на документи (ако има такива)")]):_c('span',[(_vm.files.length === 1)?[_vm._v("1 избран файл")]:[_vm._v(_vm._s(_vm.files.length)+" избрани файла")]],2),_c('span',{staticClass:"icon"},[_vm._v("G")]),_c('input',{attrs:{"type":"file","id":`file-${_vm._uid}`,"multiple":"","disabled":_vm.disabled},on:{"change":_vm.filesSelected}})]):(_vm.type === 'textarea')?_c('textarea',{ref:"ta",attrs:{"type":_vm.type || 'text',"placeholder":_vm.placeholder,"maxlength":_vm.maxlength ? _vm.maxlength : false,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){_vm.$emit('input', $event.target.value), _vm.resize($event)},"focus":function($event){return _vm.resize($event)}}}):_c('input',{attrs:{"type":_vm.type || 'text',"placeholder":_vm.placeholder,"maxlength":_vm.maxlength ? _vm.maxlength : false,"disabled":_vm.disabled},domProps:{"value":_vm.value},on:{"input":function($event){return _vm.$emit('input', $event.target.value)}}}),_c('transition',{attrs:{"name":"fade"}},[(_vm.isDatepickerModalOpen)?_c('DatepickerModal',{attrs:{"value":_vm.value,"disabledDates":_vm.disabledDates,"month":_vm.month},on:{"selected":_vm.dateSelected,"close":function($event){_vm.isDatepickerModalOpen = false}}}):_vm._e()],1),_c('transition',{attrs:{"name":"fade"}},[(_vm.isTimepickerModalOpen)?_c('TimepickerModal',{attrs:{"value":_vm.value,"min":_vm.min,"max":_vm.max},on:{"input":function($event){return _vm.$emit('input', $event)},"close":function($event){_vm.isTimepickerModalOpen = false}}}):_vm._e()],1)],2)
}
var staticRenderFns = []

export { render, staticRenderFns }