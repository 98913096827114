<template>
  <div class="schedule__group">
    <header>
      <div class="label-and-input">
        <p>Групов график</p>
        <Input
          placeholder="Групов график"
          v-model="scheduleGroup"
          prop="name"
          :list="scheduleGroups"
          @input="scheduleGroupSelected"
          @focus="getScheduleGroups"
          @search="getScheduleGroups"
        />
      </div>
      <template v-if="currentUser.roles.admin || currentUser.roles.hr || currentUser.roles.supervisor">
        <Button class="button--green" @click="getExcelFile()" v-if="scheduleGroup && dates.length"
          >Свали Excel файл</Button
        >
      </template>
      <!-- @search="getSupervisors" -->
    </header>
    <main v-if="scheduleGroup && dates.length">
      <table>
        <thead>
          <tr>
            <th></th>
            <th :class="{ holiday: d.holiday || d.weekend }" v-for="(d, i) in dates" :key="`date-${i}`">
              <div>
                <span>{{ d.date }}</span>
                <span>{{ days[d.day] }}</span>
              </div>
            </th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          <template v-for="(sc, i) in scheduleGroup.schedules">
            <tr :key="`sc-${i}`" v-if="sc.dates">
              <td>{{ sc.name }}</td>
              <td v-for="(d, j) in dates" :key="`sc-date-${j}`" :class="{ holiday: d.holiday || d.weekend }">
                <div>
                  <span
                    v-if="sc.dates[j] > -1"
                    class="circle circle--solid"
                    :class="`circle--${scheduleGroup.skipHolidays && d.holiday ? 0 : sc.dates[j]}`"
                    :key="`shift-sp-${j}`"
                  >
                    <template v-if="sc.dates[j] && !(scheduleGroup.skipHolidays && d.holiday)">
                      {{ scheduleGroup.shifts[sc.dates[j] - 1].name }}
                    </template>
                    <template v-else>П</template>
                  </span>
                </div>
              </td>
              <td></td>
            </tr>
            <!-- styling only -->
            <tr class="empty" :key="`sc-bot-${i}`">
              <td></td>
              <td v-for="(d, j) in dates" :key="`empty-${i}-${j}`" :class="{ holiday: d.holiday || d.weekend }">
                <div></div>
              </td>
              <td></td>
            </tr>
          </template>
        </tbody>
      </table>
    </main>
  </div>
</template>

<script>
import Input from '@/components/inputs/Input';
import Button from '@/components/Button';
import { mapGetters } from 'vuex';
export default {
  components: {
    Input,
    Button,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    $route(to, from) {
      if (to.query.date !== from.query.date) this.getScheduleGroups();
    },
    scheduleGroup(to) {
      this.$router
        .push({
          path: this.$route.path,
          query: { ...this.$route.query, scheduleGroup: to ? to._id : '' },
        })
        .catch(() => {});
    },
  },
  data() {
    return {
      days: {
        0: 'Н',
        1: 'П',
        2: 'В',
        3: 'С',
        4: 'Ч',
        5: 'П',
        6: 'С',
      },
      scheduleGroups: [],
      scheduleGroup: null,
      dates: [],
      date: null,
    };
  },
  methods: {
    getScheduleGroups(search, initial) {
      const selectedScheduleGroup = this.scheduleGroup ? this.scheduleGroup._id : null;

      this.date = this.$route.query.date ? new Date(this.$route.query.date) : new Date();
      this.scheduleGroup = null;
      this.scheduleGroups = [];
      this.dates = [];

      let url = `/schedule-groups?date=${this.date}`;

      if (search) {
        url += `&search=${search}`;
      }

      this.$apiService.get(url).then((res) => {
        this.scheduleGroups = res.data;

        if (this.scheduleGroups.length) {
          let i = -1;
          // when initializing select the first schedule
          if (initial) {
            for (let j = 0; j < this.scheduleGroups.length; j++) {
              if (i > -1) break;
              for (let k = 0; k < this.scheduleGroups[j].schedules.length; k++) {
                const s = this.scheduleGroups[j].schedules[k];
                if (s.name === this.currentUser.schedule) {
                  i = j;
                  break;
                }
              }
            }

            if (i < 0) i = 0;
          }
          // if there was a selected scheduleGroup and it still exists in the new array select it
          else if (selectedScheduleGroup) {
            i = this.scheduleGroups.map((s) => s._id).indexOf(selectedScheduleGroup);
          }

          if (i > -1) {
            this.scheduleGroup = this.scheduleGroups[i];

            this.getDates();
          }
        }
      });
    },
    scheduleGroupSelected() {
      if (this.scheduleGroup) {
        this.getDates();
        this.previousScheduleGroup = this.scheduleGroup._id;
      }
    },
    getDates() {
      this.$apiService.get(`/days/month/${this.date}`).then((res) => {
        this.dates = res.data;

        if (this.dates.length) this.generatePatterns();
      });
    },
    generatePatterns() {
      // TODO: use the same one from the api
      const firstDayOfScheduleGroup = new Date(this.scheduleGroup.dateFrom);
      const firstDayOfMonth = new Date(this.dates[0].fullDate);
      const difference = Math.ceil(Math.abs(firstDayOfMonth - firstDayOfScheduleGroup) / (1000 * 60 * 60 * 24));
      let offset = 1;
      if (firstDayOfScheduleGroup < firstDayOfMonth) {
        offset = difference % this.scheduleGroup.pattern.length;
      }

      for (let sc of this.scheduleGroup.schedules) {
        this.$set(sc, 'dates', []);

        let position = offset ? offset - 1 : sc.pattern.length - 1;

        for (let i = 0; i < this.dates.length; i++) {
          if (this.scheduleGroup.dateFrom > this.dates[i].fullDate) {
            sc.dates.push(-1);
            continue;
          } else if (this.scheduleGroup.dateTo < this.dates[i].fullDate) {
            break;
          }
          sc.dates.push(sc.pattern[position]);

          if (position < sc.pattern.length - 1) position++;
          else position = 0;
        }
      }
    },
    getExcelFile() {
      window.open(`${process.env.VUE_APP_BASE_URI}/schedule-groups/download/${this.scheduleGroup._id}/${this.date}`);
    },
  },
  mounted() {
    this.getScheduleGroups(null, true);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.schedule__group {
  height: 100%;
  padding: 10px;
  width: 100%;
  > header {
    align-items: center;
    border-bottom: 1px solid #d3d3d3;
    color: #747474;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
    .label-and-input {
      align-items: center;
      display: flex;
      > p {
        margin-right: 8px;
      }
    }
  }
  main {
    padding-top: 1rem;
    overflow: auto;
    table {
      border-collapse: collapse;
      width: 100%;
      th,
      td {
        padding: 0;
        min-width: 40px;
        width: 1%;
        &:not(:first-child) {
          text-align: center;
        }
        &:last-child {
          border-bottom-right-radius: 4px;
          border-top-right-radius: 4px;
          max-width: 10px;
          min-width: 10px;
          padding: 0;
          width: 0.01%;
        }
        &.holiday {
          div {
            background-color: rgba(255, 0, 0, 0.08);
          }
        }
      }
      thead {
        tr {
          th {
            padding: 0 1.5px;
            div {
              align-items: center;
              background-color: rgba(71, 87, 103, 0.08);
              border-top-left-radius: 4px;
              border-top-right-radius: 4px;
              display: flex;
              flex-direction: column;
              justify-content: center;
              min-height: 70px;
            }
            span {
              display: block;
            }

            &.holiday {
              color: #ff0000;
            }
          }
        }
      }
      tbody {
        tr {
          background-color: rgba(71, 87, 103, 0.08);
          td {
            padding: 0 1.5px;
            &:first-child {
              border-bottom-left-radius: 4px;
              border-top-left-radius: 4px;
              color: #333;
              font-size: $px14;
              font-weight: 700;
              padding: 1.5px 10px;
              vertical-align: middle;
              white-space: nowrap;
            }
            div {
              align-items: center;
              background-color: rgba(71, 87, 103, 0.08);
              display: flex;
              justify-content: center;
              min-height: 40px;
              .circle {
                margin: 0;
              }
            }
          }

          &.empty {
            background-color: transparent;
            td {
              div {
                min-height: 10px;
              }
            }
            &:last-child {
              td {
                div {
                  border-bottom-left-radius: 4px;
                  border-bottom-right-radius: 4px;
                }
              }
            }
          }
        }
      }
    }
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .schedule__group {
    > header {
      flex-direction: column;
      .label-and-input {
        margin-bottom: 10px;
      }
    }
  }
}

@media screen and (max-width: $xs) {
  .schedule__group {
    > header {
      .button {
        width: 100%;
      }
    }
  }
}
</style>
