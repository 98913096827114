<template>
  <div
    class="absence-modal"
    :class="{ 'absence-modal--sidepanel': isSidePanelOpen }"
    @click.self="close()"
  >
    <transition name="modal" appear>
      <div class="modal">
        <header>
          <p v-if="type === 'shift'">Промяна на смяната</p>
          <p v-else-if="type === 'absence'">Добави непланирано отсъствие</p>
          <p v-else>Промени в графика на служителя</p>
          <span class="icon close" @click="close()">X</span>
        </header>
        <template v-if="type === null">
          <section class="select-type">
            <Button class="button--blue" @click="toggleShiftChange"
              >ПРОМЯНА НА СМЯНА</Button
            >
            <Button class="button--darker-blue" @click="type = 'absence'"
              >ДОБАВЯНЕ НА НЕПЛАНИРАНО ОТСЪСТВИЕ</Button
            >
          </section>
        </template>
        <template v-else>
          <section class="with-background" v-if="type === 'shift'">
            <p>ИЗБЕРЕТЕ НОВА СМЯНА:</p>
            <div class="row row--wrap">
              <span
                class="circle"
                :class="`circle--orange ${
                  absence.shift === s ? 'circle--solid' : ''
                } ${s === shift ? 'circle--disabled' : 'circle--clickable'}`"
                v-for="(s, i) in shifts"
                :key="`shift-${i}`"
                @click="s === shift ? null : (absence.shift = s)"
                >{{ s }}</span
              >
              <span
                class="circle circle--orange"
                :class="{
                  'circle--nonsolid': absence.shift !== null,
                  'circle--solid': !absence.shift,
                  'circle--clickable': shift,
                  'circle--disabled': !shift,
                }"
                @click="shift ? (absence.shift = null) : null"
                >П</span
              >
            </div>
          </section>
          <template v-else-if="type === 'absence'">
            <section>
              <p>ДАТИ НА ОТСЪСТВИЕ:</p>
              <div class="row">
                <Input
                  label="От дата:"
                  :datepicker="true"
                  :disabledDates="disabledDates"
                  v-model="absence.dateFrom"
                />
                <Input
                  label="До дата включително:"
                  :datepicker="true"
                  :disabledDates="disabledDates"
                  v-model="absence.dateTo"
                />
              </div>
            </section>
            <section>
              <p>ТИП ОТСЪСТВИЕ:</p>
              <div class="row">
                <span
                  class="absence-type absence-type--illness"
                  :class="{
                    'absence-type--active': absence.type === 'illness',
                  }"
                  @click="absence.type = 'illness'"
                >
                  БОЛНИЧЕН
                </span>
                <span
                  class="absence-type absence-type--maternity"
                  :class="{
                    'absence-type--active': absence.type === 'maternity',
                  }"
                  @click="absence.type = 'maternity'"
                >
                  МАЙЧИНСТВО
                </span>
                <span
                  class="absence-type absence-type--self-release"
                  :class="{
                    'absence-type--active': absence.type === 'self-release',
                  }"
                  @click="absence.type = 'self-release'"
                >
                  САМООТЛЪЧКА
                </span>
              </div>
            </section>
          </template>
          <section>
            <p>КОМЕНТАР</p>
            <div class="row">
              <Input
                placeholder="Коментар (незадължителен)"
                v-model="absence.comment"
              />
            </div>
          </section>
          <footer>
            <Button
              class="button--red"
              @click="deleteAbsence()"
              v-if="absence._id"
              >Изтрий</Button
            >
            <div v-else></div>
            <Button class="button--blue" @click="save" :disabled="loading"
              >Запиши</Button
            >
          </footer>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import Input from "@/components/inputs/Input";
import Button from "@/components/Button";
import { mapGetters } from "vuex";
export default {
  components: {
    Input,
    Button,
  },
  props: ["user", "date", "scheduleGroup", "schedule", "shift"],
  computed: {
    ...mapGetters(["currentUser", "isSidePanelOpen"]),
    disabledDates() {
      const disabled = {};
      if (!this.currentUser.roles.admin && !this.currentUser.roles.hr) {
        const to = new Date();

        if (to.getDate() <= 2) {
          to.setMonth(to.getMonth() - 1);
        }

        to.setDate(0);
        to.setHours(23, 59, 59, 999);

        disabled.to = to;
      }

      return disabled;
    },
  },
  data() {
    return {
      loading: false,
      type: null,
      shifts: [],
      absence: {
        user: null,
        dateFrom: null,
        dateTo: null,
        type: null,
      },
    };
  },
  methods: {
    toggleShiftChange() {
      this.type = "shift";
      this.absence.type = "shift-change";
      this.getShifts();
    },
    getShifts() {
      this.shifts = [];
      this.$apiService.get(`/schedule-groups?date=${this.date}`).then((res) => {
        if (res.data) {
          for (let s of res.data) {
            for (let i = 0; i < s.totalShifts; i++) {
              if (this.shifts.indexOf(s.shifts[i].name) < 0) {
                this.shifts.push(s.shifts[i].name);
              }
            }
          }
        }
      });
    },
    getAbsence() {
      this.$apiService
        .get(`/absence/${this.date}/${this.user._id}`)
        .then((res) => {
          if (res.data) {
            this.absence = res.data;
            if (this.absence.type === "shift-change") {
              this.type = "shift";
              this.getShifts(true);
            } else {
              this.type = "absence";
            }
          } else {
            this.absence = {
              user: this.user,
              scheduleGroup: this.scheduleGroup,
              schedule: this.schedule,
              shift: null,
              dateFrom: new Date(this.date),
              dateTo: new Date(this.date),
              type: null,
            };
          }

          if (!this.currentUser.roles.admin && !this.currentUser.roles.hr) {
            if (new Date(this.absence.dateFrom) < this.disabledDates.to) {
              this.$snotify.error(
                "Този действие не може да бъде извършено, тъй като е за изтекъл период"
              );
              this.close();
            }
          }
        });
    },
    close(update) {
      this.$emit("close", update);
    },
    isValid() {
      if (!this.absence.type) {
        this.$snotify.error("Не сте избрали тип отсъствие");
        return false;
      } else if (this.absence.type === "shift-change") {
        const s = this.shift ? this.shift._id : null;
        const as = this.absence.shift;
        if (s === as) {
          this.$snotify.error("Трябва да изберете различна смяна от текущата!");
          return false;
        }
      }
      return true;
    },
    save() {
      if (this.isValid()) {
        this.loading = true;
        this.$apiService
          .post("/absence", this.absence)
          .then((res) => {
            this.close(res.data);
          })
          .catch((err) => {
            this.$snotify.error(err.response.data.message);
            this.loading = false;
          });
      }
    },
    deleteAbsence() {
      const type =
        this.absence.type === "shift-change"
          ? "променената смяна"
          : "отсъствието";
      const msg = `Сигурни ли сте, че искате да изтриете ${type}`;
      if (confirm(msg)) {
        this.$apiService.delete(`/absence/${this.absence._id}`).then((res) => {
          this.close({ ...res.data, deleted: true });
        });
      }
    },
  },
  mounted() {
    this.getAbsence();
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.absence-modal {
  align-items: center;
  background-color: rgba($color: #000, $alpha: 0.4);
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  @include transition($transition);

  &.absence-modal--sidepanel {
    padding-left: $sidepanel;
  }

  > .modal {
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 0 60px 0 rgba($color: #000, $alpha: 0.2);
    width: 384px;

    header {
      align-items: flex-end;
      border-bottom: 1px solid #d3d3d3;
      display: flex;
      justify-content: space-between;
      padding: 14px 20px 20px;
      p {
        font-size: $px14;
      }
      .close {
        align-items: center;
        display: flex;
        height: 18px;
        justify-content: center;
        width: 18px;
        @include hover();
      }
    }

    section {
      border-bottom: 1px solid #d3d3d3;
      padding: 17.5px 20px;
      &.with-background {
        background-color: #efefef;
      }
      &.select-type {
        display: flex;
        flex-direction: column;
        .button {
          width: 100%;
          &:not(:last-child) {
            margin-bottom: 0.75rem;
          }
        }
      }
      > p {
        color: #373737;
        font-size: $px11;
        font-weight: 700;
        margin-bottom: 10px;
      }
      .row {
        display: flex;
        &.row--wrap {
          flex-wrap: wrap;
          .circle {
            margin-bottom: 2.5px;
            margin-top: 2.5px;
          }
        }
        &:not(:last-child) {
          margin-bottom: 10px;
        }
        .input-wrapper {
          width: 100%;
          &:not(:first-child) {
            margin-left: 15px;
          }
        }
        .absence-type {
          align-items: center;
          background-color: #a4a4a4;
          border: 1px solid #a4a4a4;
          border-radius: 1px;
          color: #fff;
          display: flex;
          font-size: $px10;
          font-weight: 500;
          height: 28px;
          justify-content: center;
          width: 100%;
          @include hover();
          @include noselect();
          &.absence-type--active {
            &.absence-type--illness {
              background-color: #ffcccc;
              border: 1px solid #ce614b;
              color: #ce614b;
            }
            &.absence-type--maternity {
              background-color: #fdcbfb;
              border: 1px solid #8400b2;
              color: #8400b2;
            }
            &.absence-type--self-release {
              background-color: #d4d4d4;
              border: 1px solid #505050;
              color: #505050;
            }
          }
          &:not(:first-child) {
            margin-left: 7px;
          }
        }
      }
    }
    footer {
      display: flex;
      justify-content: space-between;
      padding: 10.5px 20px;
    }
  }
}
</style>
