<template>
  <div class="vacations-hourly">
    <template v-if="loaded">
      <Table>
        <thead>
          <tr>
            <th>№</th>
            <th>ДАТА</th>
            <th>ВИД ОТСЪСТВИЕ</th>
            <th>СЛ. НОМЕР</th>
            <th>ЗА СЛУЖИТЕЛ</th>
            <th>ПРЯК РЪК-Л</th>
            <th>ДАТА/ПЕРИОД ОТСЪСТВИЕ</th>
            <th>ЧАС ОТ</th>
            <th>ЧАС ДО</th>
            <th>
              <div class="status" :class="{ active: statusDropdown }">
                <span class="status__toggle" @click="statusDropdown = !statusDropdown">
                  СТАТУС <span class="icon">H</span>
                </span>
                <div class="status__dropdown" v-if="statusDropdown">
                  <p :class="{ active: $route.query.stage === '' }" @click="selectStage('')">Покажи всички</p>
                  <p :class="{ active: $route.query.stage === 'finished' }" @click="selectStage('finished')">
                    Одобрена
                  </p>
                  <p :class="{ active: $route.query.stage === 'waiting' }" @click="selectStage('waiting')">Чакаща</p>
                  <p :class="{ active: $route.query.stage === 'annulled' }" @click="selectStage('annulled')">
                    Анулирана
                  </p>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="vacationsHourly.length">
          <tr v-for="v of vacationsHourly" :key="v._id" @click="openVacationHourly(v._id)">
            <td>{{ v.number ? `ПО${v.number}` : '' }}</td>
            <td>{{ v.dateOfSigning | formatDate }}</td>
            <td>
              <div class="reports-status">
                <p>
                  {{ v.template[`typeName${v.type}`] }}
                </p>
                <section
                  v-if="v.type === '0016' && v.reportsStatus && v.finalizedAt && !v.annulled"
                  @click.stop="openReportPeriodModal(v)"
                  :class="v.reportsStatus.class"
                >
                  <span class="icon">p</span>
                  <span>{{ v.reportsStatus.name }}</span>
                </section>
              </div>
            </td>
            <td>
              {{ v.userNumber }}
            </td>
            <td>{{ v.userName }}</td>
            <td>{{ v.supervisorFirst.name }}</td>
            <template v-if="v.type === '0012' || v.type === '0016' || v.type === '0045' || v.type === '0046'">
              <td>
                <span>{{ v.dateFrom | formatDate }}</span>
                <span> - {{ v.dateTo | formatDate }}</span>
              </td>
              <td></td>
              <td></td>
            </template>
            <template v-else>
              <td>
                <span>{{ v.dateFrom | formatDate }}</span>
              </td>
              <td>{{ v.hourFrom }}</td>
              <td>{{ v.hourTo }}</td>
            </template>
            <td>
              <img src="@/assets/img/stages/mini-annulled.svg" v-if="v.annulled" />
              <img src="@/assets/img/stages/mini-finalized.svg" v-else-if="v.finalizedAt" />
              <img src="@/assets/img/stages/mini-waiting.svg" v-else />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-if="$route.query.search">
            <td colspan="10">
              Няма резултати от търсенето. Проверете дали периода, името, или служебния номер са коректни.
            </td>
          </tr>
          <tr v-else>
            <td colspan="10">
              Няма резултати за периода
              <span v-if="$route.query.type">от този тип отпуск</span>
            </td>
          </tr>
        </tbody>
      </Table>
      <Pagination :total="total" :size="size" />

      <ReportsPeriodModal
        v-if="isReportModalOpen"
        :reports="selectedVh.reports"
        :vh="selectedVh"
        @getReports="getWorkDays()"
        @close="closeReportPeriodModal"
      />
    </template>
  </div>
</template>

<script>
import Table from '@/components/Table';
import Pagination from '@/components/Pagination';
import ReportsPeriodModal from '@/components/ReportsPeriodModal';
import { mapGetters } from 'vuex';
export default {
  components: {
    Table,
    Pagination,
    ReportsPeriodModal,
  },
  computed: {
    ...mapGetters(['activeCompany']),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return {
      total: 0,
      size: 20,
      vacationsHourly: [],
      loaded: false,
      statusDropdown: false,
      isReportModalOpen: false,
      selectedVh: '',
    };
  },
  watch: {
    $route: {
      handler: 'getVacationsHourly',
      immediate: true,
    },
  },
  methods: {
    // gets reportsStatus of vacation hourlies
    async getWorkDays() {
      let promises = []

      for (let vh of this.vacationsHourly) {
        if (vh.type === '0016' && vh.user) {
          promises.push(
            await new Promise((resolve) => {
              let url = `/reports?creator=${vh.user._id}&activeCompany=${this.activeCompany}&noDrafts=true`;
              if (vh.dateFrom && vh.dateTo) url += `&dateFrom=${vh.dateFrom}&dateTo=${vh.dateTo}`;

              this.$apiService
                .get(url)
                .then(async (res) => {
                    this.$set(vh, 'reports', res.data);


                    await new Promise((resolve) => {
                      this.$apiService
                        .get(`/days/period/${vh.dateFrom}/${vh.dateTo}`)
                        .then((res) => {
                          let workDays = [];
                          for (let i = 0; i < res.data.length; i++) {
                            let date = res.data[i];
                            if (this.activeCompany === 'КЦМ АД' && vh.shifts[i]) workDays.push(date);
                            if (this.activeCompany === 'КЦМ 2000' && !date.weekend) workDays.push(date);
                          }

                          const lastPossibleDate = new Date();
                          lastPossibleDate.setDate(lastPossibleDate.getDate() - 3);
                          let lastPossibleDateMidnight = new Date(lastPossibleDate.setHours(0, 0, 0, 0));

                          let dayCopy = new Date(vh.dateTo);
                          let vhLastDayMidnight = new Date(dayCopy.setHours(0, 0, 0, 0));

                          // let withoutDrafts = vh.reports ? vh.reports.filter((x) => !x.draft) : [];
                          let canReportStartDays = workDays.length ? workDays.filter((x) => x.canReportStart) : [];

                          if (workDays.length === vh.reports.length) {
                            this.$set(vh, 'reportsStatus', { name: 'ЗАВЪРШЕН ОТЧЕТ', class: 'green' });
                          } else if (lastPossibleDateMidnight > vhLastDayMidnight && !vh.reports.length) {
                            this.$set(vh, 'reportsStatus', { name: 'ЗАВЪРШЕН ОТЧЕТ', class: 'red' });
                          } else if (vh.reports.length && lastPossibleDateMidnight > vhLastDayMidnight) {
                            this.$set(vh, 'reportsStatus', { name: 'ЗАВЪРШЕН ОТЧЕТ', class: 'yellow' });
                          } else if (canReportStartDays.length === workDays.length) {
                            this.$set(vh, 'reportsStatus', { name: 'ОТЧИТАНЕ', class: 'blue' });
                          } else if (canReportStartDays.length !== workDays.length) {
                            this.$set(vh, 'reportsStatus', { name: 'ОТЧИТАНЕ', class: 'yellow' });
                          }
                          resolve();
                        })
                        .catch((err) => {
                          this.error = err.response.data.message;
                        });
                    });
                  resolve();
                })
                .catch((err) => {
                  this.error = err.response.data.message;
                });
            })
          );
        }
      }

      if (promises.length) {
        Promise.all(promises)
          .then(() => { })
          .catch((err) => {
            console.log(err);
          });
      }
    },
    openReportPeriodModal(vh) {
      this.selectedVh = vh;
      this.isReportModalOpen = true;
    },
    closeReportPeriodModal() {
      // this.getReports(this.selectedVh);
      this.isReportModalOpen = false;
      this.selectedVh = '';
    },
    selectStage(s) {
      const r = {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          stage: s,
          t: new Date().getTime(),
        },
      };

      if (r.query.page) {
        delete r.query.page;
      }

      this.$router.push(r);
      this.statusDropdown = false;
    },
    getReports(vh) {
      if (vh.user) {
        let url = '/reports';
        url += `?creator=${vh.user._id}`;
        if (vh.dateFrom && vh.dateTo) url += `&dateFrom=${vh.dateFrom}&dateTo=${vh.dateTo}`;

        this.$apiService.get(url).then((res) => {
          this.$set(vh, 'reports', res.data);
        });
      }
    },
    getVacationsHourly() {
      this.loaded = true;
      this.loaded = false;
      this.vacationsHourly = [];
      this.statusDropdown = false;
      let url = `/vacations-hourly?size=${this.size}&page=${this.$route.query.page || 1}`;
      if (this.$route.query.search) {
        url += `&search=${this.$route.query.search}`;
      }
      if (this.$route.query.type) {
        url += `&type=${this.$route.query.type}`;
      }
      if (this.$route.query.dateFrom && this.$route.query.dateTo) {
        url += `&dateFrom=${this.$route.query.dateFrom}&dateTo=${this.$route.query.dateTo}`;
      }
      if (!this.$route.query.dateFrom || !this.$route.query.dateTo) {
        return;
      }

      if (this.$route.query.stage) {
        url += `&stage=${this.$route.query.stage}`;
      }
      this.$apiService
        .get(url)
        .then((res) => {
          this.vacationsHourly = res.data.vacationsHourly;
          this.total = res.data.total;
        })
        .then(() => {
          if (this.vacationsHourly.length) {
            this.getWorkDays()
            // for (let vh of this.vacationsHourly) {
            //   if (vh.type === '0016') {
                // this.getReports(vh);
                // this.getWorkDays(vh);
            //   }
            // }
          }
          setTimeout(() => {
            this.loaded = true;
          }, 1000);
        });
    },
    openVacationHourly(v) {
      this.$router.push(`/vacations-hourly/${v}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.vacations-hourly {
  height: 100%;
  padding: 10px;
  width: 100%;
}
.table-wrapper {
  min-height: 250px;
}
.table {
  tr {
    th:last-child,
    td:last-child {
      text-align: center;
      width: 1%;
      img {
        width: 87px;
      }
    }
  }
}
.reports-status {
  display: flex;
  gap: 10px;

  section {
    align-items: center;
    color: #e17800;
    display: flex;
    gap: 3px;
    &.blue {
      color: #35aadf;
    }
    &.red {
      color: #d80000;
    }
    &.green {
      color: #419500;
    }
    span {
      font-size: $px13;
      &:not(.icon) {
        text-decoration: underline;
        font-weight: bold;
      }
    }
  }
}
.status {
  position: relative;
  .status__toggle {
    align-items: center;
    display: inline-flex;
    position: inherit;
    z-index: 2;
    @include hover();
    @include noselect();
    .icon {
      font-weight: 400;
      margin-left: 0.25rem;
      opacity: 0.3;
    }
  }
  &.active {
    .status__toggle {
      color: #fff;
    }
  }
  .status__dropdown {
    background-color: #7d95ac;
    border-radius: 10px;
    position: absolute;
    right: 0;
    top: -0.5rem;
    padding-top: 2rem;
    z-index: 1;
    p {
      color: #fff;
      cursor: pointer;
      padding: 2px 10px;
      text-align: right;
      white-space: nowrap;
      @include transition($transition);
      &.active,
      &:hover {
        background-color: rgba($color: #000, $alpha: 0.1);
      }
    }
  }
}
</style>