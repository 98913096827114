var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"absence-modal",class:{ 'absence-modal--sidepanel': _vm.isSidePanelOpen },on:{"click":function($event){if($event.target !== $event.currentTarget)return null;return _vm.close()}}},[_c('transition',{attrs:{"name":"modal","appear":""}},[_c('div',{staticClass:"modal"},[_c('header',[(_vm.type === 'shift')?_c('p',[_vm._v("Промяна на смяната")]):(_vm.type === 'absence')?_c('p',[_vm._v("Добави непланирано отсъствие")]):_c('p',[_vm._v("Промени в графика на служителя")]),_c('span',{staticClass:"icon close",on:{"click":function($event){return _vm.close()}}},[_vm._v("X")])]),(_vm.type === null)?[_c('section',{staticClass:"select-type"},[_c('Button',{staticClass:"button--blue",on:{"click":_vm.toggleShiftChange}},[_vm._v("ПРОМЯНА НА СМЯНА")]),_c('Button',{staticClass:"button--darker-blue",on:{"click":function($event){_vm.type = 'absence'}}},[_vm._v("ДОБАВЯНЕ НА НЕПЛАНИРАНО ОТСЪСТВИЕ")])],1)]:[(_vm.type === 'shift')?_c('section',{staticClass:"with-background"},[_c('p',[_vm._v("ИЗБЕРЕТЕ НОВА СМЯНА:")]),_c('div',{staticClass:"row row--wrap"},[_vm._l((_vm.shifts),function(s,i){return _c('span',{key:`shift-${i}`,staticClass:"circle",class:`circle--orange ${
                _vm.absence.shift === s ? 'circle--solid' : ''
              } ${s === _vm.shift ? 'circle--disabled' : 'circle--clickable'}`,on:{"click":function($event){s === _vm.shift ? null : (_vm.absence.shift = s)}}},[_vm._v(_vm._s(s))])}),_c('span',{staticClass:"circle circle--orange",class:{
                'circle--nonsolid': _vm.absence.shift !== null,
                'circle--solid': !_vm.absence.shift,
                'circle--clickable': _vm.shift,
                'circle--disabled': !_vm.shift,
              },on:{"click":function($event){_vm.shift ? (_vm.absence.shift = null) : null}}},[_vm._v("П")])],2)]):(_vm.type === 'absence')?[_c('section',[_c('p',[_vm._v("ДАТИ НА ОТСЪСТВИЕ:")]),_c('div',{staticClass:"row"},[_c('Input',{attrs:{"label":"От дата:","datepicker":true,"disabledDates":_vm.disabledDates},model:{value:(_vm.absence.dateFrom),callback:function ($$v) {_vm.$set(_vm.absence, "dateFrom", $$v)},expression:"absence.dateFrom"}}),_c('Input',{attrs:{"label":"До дата включително:","datepicker":true,"disabledDates":_vm.disabledDates},model:{value:(_vm.absence.dateTo),callback:function ($$v) {_vm.$set(_vm.absence, "dateTo", $$v)},expression:"absence.dateTo"}})],1)]),_c('section',[_c('p',[_vm._v("ТИП ОТСЪСТВИЕ:")]),_c('div',{staticClass:"row"},[_c('span',{staticClass:"absence-type absence-type--illness",class:{
                  'absence-type--active': _vm.absence.type === 'illness',
                },on:{"click":function($event){_vm.absence.type = 'illness'}}},[_vm._v(" БОЛНИЧЕН ")]),_c('span',{staticClass:"absence-type absence-type--maternity",class:{
                  'absence-type--active': _vm.absence.type === 'maternity',
                },on:{"click":function($event){_vm.absence.type = 'maternity'}}},[_vm._v(" МАЙЧИНСТВО ")]),_c('span',{staticClass:"absence-type absence-type--self-release",class:{
                  'absence-type--active': _vm.absence.type === 'self-release',
                },on:{"click":function($event){_vm.absence.type = 'self-release'}}},[_vm._v(" САМООТЛЪЧКА ")])])])]:_vm._e(),_c('section',[_c('p',[_vm._v("КОМЕНТАР")]),_c('div',{staticClass:"row"},[_c('Input',{attrs:{"placeholder":"Коментар (незадължителен)"},model:{value:(_vm.absence.comment),callback:function ($$v) {_vm.$set(_vm.absence, "comment", $$v)},expression:"absence.comment"}})],1)]),_c('footer',[(_vm.absence._id)?_c('Button',{staticClass:"button--red",on:{"click":function($event){return _vm.deleteAbsence()}}},[_vm._v("Изтрий")]):_c('div'),_c('Button',{staticClass:"button--blue",attrs:{"disabled":_vm.loading},on:{"click":_vm.save}},[_vm._v("Запиши")])],1)]],2)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }