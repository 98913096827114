var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidepanel__vacation"},[_c('main',[_c('Button',{staticClass:"button--big button--grey",on:{"click":_vm.backToAllVacationsHourly}},[_c('span',{staticClass:"icon"},[_vm._v("B")]),_vm._v(" НАЗАД КЪМ ВСИЧКИ ОТСЪСТВИЯ ")]),(_vm.loaded)?[(_vm.vh._id)?[(_vm.vh.annulled)?_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/sa.svg")}}):(_vm.vh.type === '0030' && _vm.activeCompany !== 'КЦМ 2000')?[(_vm.vh.finalizedAt)?_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s3-3.svg")}}):(_vm.vh.signatures.supervisorFirst)?_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s3-2.svg")}}):(_vm.vh.signatures.user)?_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s3-1.svg")}}):_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s3-0.svg")}})]:(
          _vm.vh.type === '0012' ||
          _vm.vh.type === '0045' ||
          _vm.vh.type === '0046' ||
          !((_vm.vh.type === '0030' || _vm.vh.type === '0016') && _vm.activeCompany === 'КЦМ 2000')
        )?[(_vm.vh.finalizedAt)?_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s1-1.svg")}}):_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s1-0.svg")}})]:(
          _vm.vh.type === '0020' ||
          _vm.vh.type === '0025' ||
          _vm.vh.type === '0016' ||
          _vm.vh.type === '0019' ||
          (_vm.vh.type === '0030' && _vm.activeCompany === 'КЦМ 2000')
        )?[(_vm.vh.finalizedAt)?_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s2-2.svg")}}):(_vm.vh.signatures.supervisorFirst)?_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s2-1.svg")}}):_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s2-0.svg")}})]:_vm._e()]:[void 0,(_vm.$route.query.type === '0030' && _vm.activeCompany !== 'КЦМ 2000')?_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s3-0.svg")}}):(
          _vm.$route.query.type === '0012' ||
          _vm.$route.query.type === '0045' ||
          _vm.$route.query.type === '0046' ||
          !((_vm.$route.query.type === '0030' || _vm.$route.query.type === '0016') && _vm.activeCompany === 'КЦМ 2000')
        )?_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s1-0.svg")}}):(
          _vm.$route.query.type === '0020' ||
          _vm.$route.query.type === '0025' ||
          _vm.$route.query.type === '0016' ||
          _vm.$route.query.type === '0019' ||
          (_vm.$route.query.type === '0030' && _vm.activeCompany === 'КЦМ 2000')
        )?_c('img',{attrs:{"src":require("@/assets/img/sidepanel/stages/s2-0.svg")}}):_vm._e()]]:_vm._e()],2),(_vm.$route.name === 'newVacationHourly')?[(_vm.currentUser.roles.employee)?_c('Button',{staticClass:"button--big button--blue",on:{"click":function($event){return _vm.openFile('employees-po.mp4')}}},[_c('span',{staticClass:"icon"},[_vm._v("k")]),_vm._v(" КАК ДА ПУСНА ЗАЯВЛЕНИЕ ЗА ПОЧАСОВО ОТСЪСТВИЕ? ")]):_vm._e(),(_vm.currentUser.roles.supervisor)?_c('Button',{staticClass:"button--big button--lighter-green",on:{"click":function($event){return _vm.openFile('supervisors-po.mp4')}}},[_c('span',{staticClass:"icon"},[_vm._v("k")]),_vm._v(" КАК ДА ОДОБРЯ ЗАЯВЛЕНИЕ ЗА ПОЧАСОВО ОТСЪСТВИЕ? ")]):_vm._e()]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }