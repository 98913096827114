<template>
  <div class="sidepanel__vacations">
    <InputSearch type="text" placeholder="Търсене на работници и служители" v-model="search" @enter="triggerSearch" />
    <Button class="button--big button--blue" @click="newVacation"> НОВО ПОЧАСОВО ОТСЪСТВИЕ </Button>
    <a
      class="sidepanel__link sidepanel__link--my-vacations-hourly"
      :href="`${config.loginPath}/users/${currentUser._id}/vacations-hourly`"
    >
      <span class="icon">8</span>
      <span><strong>ВСИЧКИ ПОЧАСОВИ ОТСЪСТВИЯ</strong></span>
    </a>
    <div class="sidepanel__vacations__dates">
      <span class="icon">C</span>
      <div class="dates">
        <span class="date-toggle" @click="selectDate = 'dateFrom'">
          <strong>От </strong>
          <span v-if="dateFrom">{{ dateFrom | formatDate }}</span>
          <span v-else>Избери</span>
        </span>
        <span class="date-toggle" @click="selectDate = 'dateTo'">
          <strong>До </strong>
          <span v-if="dateTo">{{ dateTo | formatDate }}</span>
          <span v-else>Избери</span>
        </span>
      </div>
    </div>
    <transition name="fade" mode="out-in">
      <p
        v-if="totalWaitingVo"
        class="sidepanel__link sidepanel__link--remaining sidepanel__link--orange"
        @click="openSignatureModal = true"
      >
        ПОДПИШИ ВСИЧКИ ЧАКАЩИ ОТСЪСТВИЯ
      </p>
    </transition>

    <div class="sidepanel__vacations__types" v-if="template._id">
      <div
        class="sidepanel__vacations__type"
        :class="{ active: $route.name !== 'remainingVacations' && type === '' }"
        @click="selectType('')"
      >
        <div class="texts">
          <p>ВСИЧКИ ВИДОВЕ ОТСЪСТВИЯ</p>
          <span>{{ totals.total }} бр. в избрания период</span>
        </div>
      </div>
      <div
        v-for="n in ['0030', '0025', '0020', '0012', '0045', '0016', '0019', '0046']"
        :key="`selectVacationHourly${n}`"
        class="sidepanel__vacations__type"
        :class="{ active: type === n }"
        @click="selectType(n)"
      >
        <div class="texts">
          <p>{{ template[`typeName${n}`] }}</p>
          <span>{{ totals[n] }} бр. в избрания период</span>
        </div>
      </div>
    </div>
    <!-- MODALS -->
    <transition name="fade">
      <DatepickerModal v-if="selectDate" :value="this[selectDate]" @selected="selectedDate" @close="selectDate = ''" />
    </transition>

    <SignatureModal
      v-if="openSignatureModal"
      @signed="finishWaitingV"
      @close="openSignatureModal = false"
      :director="totalWaitingVo"
    />
  </div>
</template>

<script>
import InputSearch from '@/components/inputs/InputSearch';
import DatepickerModal from '@/components/inputs/DatepickerModal';
import Button from '@/components/Button';
import SignatureModal from '@/components/signature/SignatureModal';
import { mapGetters } from 'vuex';
import config from '../../../config-public.json';
export default {
  components: {
    InputSearch,
    DatepickerModal,
    Button,
    SignatureModal,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  watch: {
    $route: {
      handler: 'checkIfNeedsAnotherLoad',
    },
  },
  data() {
    return {
      config,
      selectDate: '',
      search: '',
      openSignatureModal: false,
      waitingVacationsHourly: [],
      totalWaitingVo: 0,
      dateFrom: null,
      dateTo: null,
      type: '',
      initialPage: null,
      initialStage: null,
      template: {},
      totals: {
        total: 0,
        '0020': 0,
        '0025': 0,
        '0030': 0,
        '0012': 0,
        '0016': 0,
        '0045': 0,
        '0019': 0,
        '0046': 0,
      },
    };
  },
  methods: {
    finishWaitingV(sign) {
      const promises = [];

      for (let vh of this.waitingVacationsHourly) {
        this.$set(vh.signatures, 'supervisorFirst', sign);

        promises.push(
          new Promise((resolve) => {
            this.$apiService
              .put(`/vacations-hourly/hr`, vh)
              .then(() => {
                this.success = true;
              })
              .catch((err) => {
                console.log(err);
                if (err.response.data.message) {
                  this.$snotify.error(err.response.data.message);
                }
                this.success = false;
              })
              .then(() => {
                resolve();
              });
          })
        );
      }

      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            if (this.success) {
              this.$snotify.success('Успешно записахте почасовите отсъствия');
              this.$router.push('/vacations-hourly');
              this.getVacationsHourly();
            } else {
              this.success = undefined;
            }
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getVacationsHourly() {
      this.waitingVacationsHourly = [];
      this.totalWaitingVo = 0;

      let url = `/vacations-hourly?stage=waiting`;

      this.$apiService
        .get(url)
        .then((res) => {
          this.waitingVacationsHourly = res.data.vacationsHourly;
          this.totalWaitingVo = res.data.total;
        })
        .then(() => (this.loaded = true));
    },
    getTemplates() {
      this.$apiService.get(`/templates/vacation-hourly`).then((res) => {
        this.template = res.data || {};
      });
    },
    newVacation() {
      let url = '/vacations-hourly/new';

      if (this.$route.query.type) url += `?type=${this.$route.query.type}`;

      if (!this.currentUser.roles.admin && !this.currentUser.roles.hr && !this.currentUser.roles.supervisor) {
        if (this.currentUser.supervisorFirst && this.currentUser.supervisorSecond) {
          this.$router.push(url);
        } else {
          return this.$snotify.error('Служителят няма избрани първо и/или второ ниво одобряващи');
        }
      } else {
        this.$router.push(url);
      }
    },
    triggerSearch() {
      if (this.$route.query.search !== this.search) this.pushToRoute();
    },
    selectedDate(e) {
      this[this.selectDate] = e;
      this.selectDate = '';

      this.pushToRoute();
      this.getTotals();
    },
    selectType(t) {
      if (this.type !== t || this.$route.name === 'remainingVacations') {
        this.type = t;
        this.pushToRoute();
      }
    },
    pushToRoute() {
      const query = {};

      if (this.search) {
        query.search = this.search;
      }

      if (this.dateFrom && this.dateTo) {
        query.dateFrom = this.dateFrom.toISOString();
        query.dateTo = this.dateTo.toISOString();
      }

      if (this.type) {
        query.type = this.type;
      }

      if (this.initialPage) {
        query.page = this.initialPage;
        this.initialPage = null;
      }

      if (this.initialStage) {
        query.stage = this.initialStage;
        this.initialStage = null;
      }

      query.t = new Date().getTime();

      this.$router.push({
        path: '/vacations-hourly',
        query: query,
      });
    },
    getTotals() {
      // TODO: get all totals with one request
      for (let t in this.totals) {
        this.$apiService
          .get(
            `/vacations-hourly?count=true${
              t === 'total' ? null : `&type=${t}`
            }&dateFrom=${this.dateFrom.toISOString()}&dateTo=${this.dateTo.toISOString()}`
          )
          .then((result) => {
            this.totals[t] = result.data || 0;
          });
      }
    },
    checkIfNeedsAnotherLoad() {
      if (!Object.keys(this.$route.query).length && this.$route.name !== 'remainingVacations') {
        this.pushToRoute();
      }
    },
  },
  mounted() {
    if (this.$route.query.dateFrom && this.$route.query.dateTo) {
      this.dateFrom = new Date(this.$route.query.dateFrom);
      this.dateTo = new Date(this.$route.query.dateTo);
    } else {
      const date = new Date();
      this.dateFrom = new Date(date.getFullYear(), date.getMonth() - 1, 1);
      this.dateTo = new Date(date.getFullYear(), date.getMonth() + 2, 0);
    }

    if (this.$route.query.search) {
      this.search = this.$route.query.search;
    }

    if (this.$route.query.type) {
      this.type = this.$route.query.type;
    }

    if (this.$route.query.page) {
      this.initialPage = this.$route.query.page;
    }

    if (this.$route.query.stage) {
      this.initialStage = this.$route.query.stage;
    }

    this.getTotals();
    this.pushToRoute();
    this.getTemplates();

    if (this.currentUser.number === '3324') {
      this.getVacationsHourly();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.sidepanel__vacations {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.button {
  font-weight: 700;
  margin: 10px 0;
}
.sidepanel__vacations__dates {
  align-items: center;
  border-bottom: 1px solid #ffffff80;
  border-top: 1px solid #ffffff80;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  > .icon {
    font-size: 18px;
  }
  .date-toggle {
    @include hover();
    &:last-child {
      margin-left: 1rem;
    }
  }
}

.sidepanel__link--remaining {
  background-color: #176fbf;
  border-radius: 2px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: $px12;
  font-weight: 600;
  justify-content: center;
}

.sidepanel__link--remaining::after {
  font-size: 9px;
  right: 13px;
}
.sidepanel__link--remaining:hover {
  mix-blend-mode: difference;
}

.sidepanel__link--my-vacations-hourly {
  background-color: #004d94;
  display: flex;
  font-size: $px12;
  font-weight: initial;
  gap: 10px;
  width: 330px;

  .icon {
    font-size: 23px;
  }
}

.sidepanel__link--orange {
  background-color: #e65100;
  cursor: pointer;
}

.sidepanel__vacations__types {
  color: #fff;
  margin-top: 10px;
  width: 100%;
}
.sidepanel__vacations__type {
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  margin: 3px 0;
  padding: 5px;
  position: relative;
  width: 100%;
  @include transition($transition);
  @include noselect();
  .texts {
    display: flex;
    flex-direction: column;
    height: 30px;
    padding: 1px 0;
    justify-content: space-between;
    width: 100%;
    p {
      font-size: $px12;
      line-height: $px12;
      max-width: 100%;
      font-weight: 700;
      width: 100%;
    }
    span {
      font-size: $px10;
    }
  }
  &::before {
    align-items: center;
    background-color: $lightBlue;
    border-radius: 5px;
    color: #fff;
    content: 'Z';
    display: inline-flex;
    font-family: 'icons';
    font-size: 18px;
    height: 30px;
    justify-content: center;
    margin-right: 9px;
    min-width: 30px;
    width: 30px;
  }
  &::after {
    content: 'V';
    font-family: 'icons';
    font-size: 9px;
    position: absolute;
    right: 10px;
    top: 50%;
    @include transform(translateY(-50%));
  }
  &:hover {
    background-color: rgba($color: #e0eaf3, $alpha: 0.1);
  }
  &.active {
    background-color: #e0eaf3;
    color: $darkBlue;
  }
}

@media screen and (max-width: $xs) {
  .sidepanel__vacations__type {
    .texts {
      justify-content: center;
      p {
        font-size: $px10;
        line-height: $px10;
      }
      span {
        font-size: $px9;
      }
    }
  }
}
</style>
