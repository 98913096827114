<template>
  <div class="settings__holidays">
    <div class="tiny__content">
      <p class="tiny__content__title">ФИКСИРАНИ ОФИЦИАЛНИ ПРАЗНИЦИ</p>
      <hr class="line" />
      <p class="tiny__content__subtitle">ЕДНИ И СЪЩИ ВСЯКА ГОДИНА</p>
      <div class="holidays-wrapper holiday-wrapper--constants">
        <span v-for="c in constants" class="holiday" :key="c">{{ c }}</span>
      </div>
      <p class="tiny__content__subtitle">
        РЪЧНО ОТБЕЛЯЗВАНЕ НА ПОЧИВНИТЕ ДНИ, РАЗЛИЧНИ ОТ ФИКСИРАНИТЕ (ОТПОЧИВАНЕ
        И ВЕЛИКДЕН)
      </p>
      <hr class="line" />
      <button class="button--new-date" @click="isDatepickerModalActive = true">
        <span class="icon">A</span>
        ДАТА
      </button>
      <template v-for="(y, i) in holidays">
        <p class="tiny__content__subtitle" :key="i">
          {{ y[0].date.split('/').shift() }} Г.
        </p>
        <div class="holidays-wrapper" :key="`holidays-${i}`">
          <span v-for="d in y" class="holiday" :key="d.date"
            >{{ d.date | formatHoliday }}
            <span class="icon" @click="remove(d)">X</span>
          </span>
        </div>
      </template>
    </div>
    <DatepickerModal
      v-if="isDatepickerModalActive"
      @selected="selected"
      @close="isDatepickerModalActive = false"
    />
  </div>
</template>

<script>
import DatepickerModal from '@/components/inputs/DatepickerModal';
export default {
  components: {
    DatepickerModal,
  },
  data() {
    return {
      isDatepickerModalActive: false,
      constants: [],
      holidays: [],
    };
  },
  filters: {
    formatHoliday(s) {
      let h = '';
      if (s) {
        h = s.split('/');
        h.shift();
        h = h
          .map((n) => ('0' + n).slice(-2))
          .reverse()
          .join('/');
      }
      return h;
    },
  },
  methods: {
    getConstants() {
      this.$apiService.get('/days/constants').then((res) => {
        this.constants = res.data;
      });
    },
    getHolidays() {
      this.holidays = [];
      this.$apiService.get('/days/holidays').then((res) => {
        const years = {};
        if (res.data) {
          for (let h of res.data) {
            const y = h.date.split('/').shift();
            if (!years[y]) this.$set(years, y, []);
            years[y].push(h);
          }
        }

        // reversing
        for (let y in years) {
          this.holidays.unshift(
            years[y].slice().sort((a, b) => new Date(a.date) - new Date(b.date))
          );
        }
      });
    },
    selected(d) {
      this.$apiService
        .post('/days/holidays', { date: d })
        .then(() => {
          this.$snotify.success('Успешно добавен почивен ден');
          this.getHolidays();
        })
        .catch((err) => {
          this.$snotify.error(err.response.data.message);
        });

      this.isDatepickerModalActive = false;
    },
    remove(h) {
      if (confirm('Сигурни ли сте, че искате да изтриете почивния ден')) {
        this.$apiService
          .delete(`/days/holidays/${h._id}`)
          .then(() => {
            this.$snotify.success(`${h.date} е успешно изтрит`);
            this.getHolidays();
          })
          .catch((err) => {
            console.log(err);
          });
      }
    },
  },
  mounted() {
    this.getConstants();
    this.getHolidays();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.tiny__content > .line {
  margin-bottom: 1rem;
}
.holidays-wrapper {
  align-items: center;
  background-color: #f6f6f6;
  border: 1px solid #d3d3d3;
  border-radius: 2px;
  display: flex;
  flex-wrap: wrap;
  margin-bottom: $px11 / 2;
  padding: 2.5px 3.5px;
  &.holiday-wrapper--constants {
    margin-bottom: 4rem;
  }
}
.holiday {
  align-items: center;
  background-color: #fafafa;
  border: 1px solid #8e8e8e;
  border-radius: 4px;
  color: #6e6e6e;
  display: inline-flex;
  font-size: $px12;
  margin: 2.5px;
  padding: 5px 10px;
  .icon {
    font-size: 0.5rem;
    line-height: 0.5rem;
    margin-left: $px11 / 2;
    @include hover();
  }
}

.button--new-date {
  align-items: center;
  background-color: #e2e9ee;
  border-radius: 4px;
  border: none;
  display: flex;
  font-size: $px11;
  height: 30px;
  justify-content: center;
  margin-bottom: $px11 / 2;
  outline: none;
  width: 100%;
  @include hover();

  .icon {
    color: #005a9e;
    font-size: 1.5rem;
    margin-right: 0.5rem;
  }
}
</style>
