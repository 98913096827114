import Vacations from '@/views/vacations/Vacations.vue';
import Vacation from '@/views/vacations/Vacation.vue';

export default [
  {
    path: '/vacations',
    name: 'vacations',
    component: Vacations,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vacations/new',
    name: 'newVacation',
    component: Vacation,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/vacations/:id',
    name: 'editVacation',
    component: Vacation,
    meta: {
      requiresAuth: true
    }
  }
];
