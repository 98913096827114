import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import axios from 'axios';
import moment from 'moment';
import Snotify from 'vue-snotify';

import { ApiService } from './common/api.service';

import './registerServiceWorker';

Vue.config.productionTip = false;

Vue.prototype.$apiService = ApiService;

Vue.prototype.$http = axios;
Vue.prototype.$moment = moment;
Vue.prototype.$moment.locale('bg');

Vue.use(Snotify, {
  toast: {
    position: 'rightTop',
    showProgressBar: false,
    timeout: 3000
  }
});

Vue.filter('formatDate', date => {
  return date ? moment(date).format('DD/MM/YYYY') : 'ДД/ММ/ГГГГ';
});
Vue.filter('formatDateFull', date => {
  return date ? moment(date).format('DD/MM/YYYY - HH:mm') : 'ДД/ММ/ГГГГ - чч:мм';
});
Vue.filter('formatDateMini', date => {
  return date ? moment(date).format('DD/MM') : 'ДД/ММ';
});
Vue.filter('formatDateMonth', date => {
  let output = date ? moment(date).format('MMMM, YYYY') : 'МММM, ГГГГ';
  output = output[0].toUpperCase() + output.slice(1);
  return output;
});
Vue.filter('formatDayName', date => {
  return date ? moment(date).format('dddd') : 'ден';
});

Date.prototype.getFirstDayOfWeek = function() {
  return new Date(this.setDate(this.getDate() - this.getDay() + (this.getDay() == 0 ? -6 : 1)));
};
Date.prototype.getLastDayOfWeek = function() {
  return new Date(this.setDate(this.getDate() - (this.getDay() == 0 ? 7 : this.getDay()) + 7));
};

new Vue({
  router,
  store,
  beforeCreate() {
    Vue.$snotify = this.$snotify;
  },
  render: h => h(App)
}).$mount('#app');
// }
