import Group from '@/views/schedule/Group.vue'
import Shift from '@/views/schedule/Shift.vue'
import F76 from '@/views/schedule/F76.vue'

export default [{
    path: '/schedule',
    redirect: '/schedule/group',
    meta: {
      requiresAuth: true,
      companiesAllowed: ['КЦМ АД'],
    },
  },
  {
    path: '/schedule/group',
    name: "scheduleGroup",
    component: Group,
    meta: {
      requiresAuth: true,
      companiesAllowed: ['КЦМ АД'],
    },
  },
  {
    path: '/schedule/shift',
    name: "scheduleShift",
    component: Shift,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['supervisor'],
      companiesAllowed: ['КЦМ АД'],
    },
  },
  {
    path: '/schedule/shift-all',
    name: "scheduleShiftAll",
    component: Shift,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin', 'hr'],
      companiesAllowed: ['КЦМ АД'],
    },
  },
  {
    path: '/schedule/f76',
    name: "F76",
    component: F76,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['f76'],
      companiesAllowed: ['КЦМ АД'],
    },
  },
]