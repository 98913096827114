import {
  ApiService
} from '@/common/api.service'


const state = {
  isImporting: false
}

const getters = {
  isImporting: state => state.isImporting,
}

const actions = {
  startImporting(context) {
    context.commit('setImporting', true)
  },
  checkImporting(context) {
    return new Promise((res) => {
      ApiService.get("/importing")
        .then((response) => {
          context.commit('setImporting', response.data)
        })
        .catch(() => {
          res(false)
        });
    })
  },
}

const mutations = {
  setImporting(state, importing) {
    state.isImporting = importing;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};