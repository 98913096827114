const state = {
  isSidePanelOpen: true
}

const getters = {
  isSidePanelOpen: state => state.isSidePanelOpen
}

const actions = {
  toggleSidePanel(context) {
    context.commit('toggleSidePanel')
  }
}

const mutations = {
  toggleSidePanel(state) {
    state.isSidePanelOpen = !state.isSidePanelOpen;
  },
}

export default {
  state,
  actions,
  mutations,
  getters
};