<template>
  <div
    class="timepicker-modal"
    :class="{ 'timepicker-modal--sidepanel': isSidePanelOpen }"
    @click.self="$emit('close')"
  >
    <transition name="modal" appear>
      <div class="modal">
        <template v-if="selectingHour">
          <p>Избери час</p>
          <div class="hours">
            <span
              v-for="n of 24"
              :key="`h-${n - 1}`"
              :class="{
                active: hour === n - 1,
                disabled: maxHour
                  ? n - 1 > maxHour
                  : false || minHour
                  ? minMinute === 59
                    ? n - 1 <= minHour
                    : n - 1 < minHour
                  : false,
              }"
              @click="
                (
                  maxHour
                    ? n - 1 > maxHour
                    : false || minHour
                    ? minMinute === 59
                      ? n - 1 <= minHour
                      : n - 1 < minHour
                    : false
                )
                  ? null
                  : selectHour(n - 1)
              "
            >
              {{ ("0" + (n - 1)).slice(-2) }}
            </span>
          </div>
        </template>
        <template v-else>
          <p>Избери минута</p>
          <div class="minutes">
            <span
              v-for="n of 60"
              :key="`m-${n - 1}`"
              :class="{
                active: minute === n - 1,
                disabled:
                  typeof maxMinute === 'number'
                    ? maxHour === +hour
                      ? n > maxMinute
                      : false
                    : false || typeof minMinute === 'number'
                    ? minHour === +hour
                      ? minMinute >= n - 1
                      : false
                    : false,
              }"
              @click="
                (
                  typeof maxMinute === 'number'
                    ? maxHour === +hour
                      ? n > maxMinute
                      : false
                    : false || typeof minMinute === 'number'
                    ? minHour === +hour
                      ? minMinute >= n - 1
                      : false
                    : false
                )
                  ? null
                  : selectMinute(n - 1)
              "
            >
              {{ formatted(n - 1) }}
            </span>
          </div>
        </template>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  props: ["value", "min", "max"],
  computed: {
    ...mapGetters(["isSidePanelOpen"]),
    disabledBelow() {
      return "";
    },
  },
  data() {
    return {
      selectingHour: true,
      hour: "",
      minute: "",

      minHour: "",
      minMinute: "",
      maxHour: "",
      maxMinute: "",
    };
  },
  methods: {
    formatted(n) {
      return ("0" + n).slice(-2);
    },
    selectHour(h) {
      this.hour = this.formatted(h);
      this.selectingHour = false;
    },
    selectMinute(m) {
      this.minute = this.formatted(m);
      this.$emit("input", `${this.hour}:${this.minute}`);
      this.$emit("close");
    },
  },
  mounted() {
    if (this.value) {
      const time = this.value.split(":");
      this.hour = +time[0];
      this.minute = +time[1];
    }
    if (this.min) {
      const time = this.min.split(":");
      this.minHour = +time[0];
      this.minMinute = +time[1];
    }
    if (this.max) {
      const time = this.max.split(":");
      this.maxHour = +time[0];
      this.maxMinute = +time[1];
    }
  },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";
.timepicker-modal {
  align-items: center;
  background-color: rgba($color: #000, $alpha: 0.4);
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  @include transition($transition);

  .modal {
    background-color: #fff;
    box-shadow: 0 0 60px 0 rgba($color: #000, $alpha: 0.2);
    border-radius: 4px;
    > p {
      margin: 0.5rem 0;
      text-align: center;
    }
    .hours,
    .minutes {
      display: flex;
      flex-wrap: wrap;
      max-width: 90vw;
      width: 300px;
      span {
        align-items: center;
        border-radius: 4px;
        cursor: pointer;
        display: inline-flex;
        height: 36px;
        justify-content: center;
        margin: 2px;
        width: calc((100% / 6) - 4px);
        @include transition($transition);

        &:not(.disabled):hover {
          background-color: rgba($color: $lightAccent, $alpha: 0.2);
        }
        &.active {
          background-color: $lightAccent;
          color: #fff;
        }
        &.disabled {
          cursor: default;
          opacity: 0.2;
        }
      }
    }
    .minutes {
      width: 420px;
      span {
        width: calc(10% - 4px);
      }
    }
  }
}

@media screen and (min-width: $sm) {
  .timepicker-modal {
    &.timepicker-modal--sidepanel {
      padding-left: $sidepanel;
    }
  }
}
</style>
