<template>
  <div class="settings__templates">
    <div class="tiny__content">
      <p class="tiny__content__title">РЕДАКЦИЯ НА БЛАНКИТЕ ЗА ОТПУСК</p>
      <hr class="line" />
      <p class="text">
        <strong>
          Основният темплейт на всяка бланка трябва да се запази като структура.
          Не трябва да има големи разлики в количеството текст.
        </strong>
      </p>
      <p class="text">
        <strong>
          При промяна на бланка, всички заварени и попълнени отпуски, вкл.
          незавършени такива няма да се променят. Промените ще се приложат само
          към нови отпуски, пуснати след промяната
        </strong>
      </p>
      <router-link
        class="settings__templates__link"
        to="/settings/templates/common"
      >
        <p>
          <strong> РЕДАКТОР ОТПУСКИ </strong>
        </p>
        <p>Последна промяна - {{ updatedAtVacations | formatDateFull }}</p>
      </router-link>
      <router-link
        class="settings__templates__link"
        to="/settings/templates/vacation-hourly"
      >
        <p>
          <strong> РЕДАКТОР ПОЧАСОВИ ОТПУСКИ </strong>
        </p>
        <p>
          Последна промяна - {{ updatedAtVacationsHourly | formatDateFull }}
        </p>
      </router-link>
      <!-- <p class="tiny__content__title">ВСИЧКИ БЛАНКИ</p>
      <hr class="line" />
      <router-link
        class="settings__templates__link"
        v-for="(t, i) of types"
        :to="`/settings/templates/${i}`"
        :key="`type-${i}`"
      >
        <p>
          <strong>
            {{ t }}
          </strong>
        </p>
        <p>Последна промяна - ...</p>
      </router-link> -->
    </div>
  </div>
</template>

<script>
import types from '@/data/vacation-types.json';
export default {
  data() {
    return {
      types,
      updatedAtVacations: null,
      updatedAtVacationsHourly: null,
    };
  },
  methods: {
    getUpdated() {
      const c = this.$store.getters.activeCompany;
      this.$apiService.get(`/templates/common/${c}?updated=1`).then((res) => {
        if (res.data._id) {
          this.updatedAtVacations = res.data.updatedAt;
        }
      });
      this.$apiService
        .get(`/templates/vacation-hourly/${c}?updated=1`)
        .then((res) => {
          if (res.data._id) {
            this.updatedAtVacationsHourly = res.data.updatedAt;
          }
        });
    },
  },
  mounted() {
    this.getUpdated();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.tiny__content__title:not(:first-child) {
  margin-top: 1rem;
}
.text {
  margin-bottom: $px11 / 2;
}
.settings__templates__link {
  background-color: #f6f7fb;
  border: 1px solid #cbd3df;
  border-radius: 10px;
  display: block;
  margin-bottom: $px11 / 2;
  margin-top: 1rem;
  padding: 0.5rem 1.25rem;
  p {
    color: rgba($color: #000, $alpha: 0.7);
    font-size: $px11;
    strong {
      color: #000;
      font-size: $px13;
      text-transform: uppercase;
    }
  }
}
</style>
