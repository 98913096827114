<template>
  <div class="sidepanel__vacation">
    <Button class="button--big button--grey" @click="backToAllVacations">
      <span class="icon">B</span>
      НАЗАД КЪМ ВСИЧКИ ОТПУСКИ
    </Button>
    <template v-if="loaded">
      <img src="@/assets/img/sidepanel/stages/sa.svg" alt="Отказан" v-if="!allowed || !allowedByHR" />
      <img src="@/assets/img/sidepanel/stages/sa.svg" alt="Анулиран" v-else-if="stage === 'annulled'" />
      <template
        v-else-if="type === 'paid' || type === 'paid-schedule' || type === 'unpaid' || activeCompany === 'КЦМ 2000'"
      >
        <img src="@/assets/img/sidepanel/stages/s3-0.svg" alt="Чернова" v-if="!stage" />
        <img src="@/assets/img/sidepanel/stages/s3-1.svg" alt="Заявление" v-else-if="stage === 'application'" />
        <img
          src="@/assets/img/sidepanel/stages/s3-2.svg"
          alt="Потвърден от първо ниво"
          v-else-if="stage === 'confirmedByFirstSupervisor'"
        />
        <img
          src="@/assets/img/sidepanel/stages/s3-3.svg"
          alt="Потвърден от второ ниво"
          v-else-if="stage === 'confirmedBySecondSupervisor'"
        />
      </template>
      <template v-else>
        <img src="@/assets/img/sidepanel/stages/s4-0.svg" alt="Чернова" v-if="!stage" />
        <img src="@/assets/img/sidepanel/stages/s4-1.svg" alt="Заявление" v-else-if="stage === 'application'" />
        <img
          src="@/assets/img/sidepanel/stages/s4-2.svg"
          alt="Потвърден от първо ниво"
          v-else-if="stage === 'confirmedByFirstSupervisor'"
        />
        <img
          src="@/assets/img/sidepanel/stages/s4-3.svg"
          alt="Потвърден от второ ниво"
          v-else-if="stage === 'confirmedBySecondSupervisor'"
        />
        <img src="@/assets/img/sidepanel/stages/s4-4.svg" alt="Потвърден от ЧР" v-else-if="stage === 'confirmedByHR'" />
      </template>
    </template>
    <template v-if="$route.name === 'newVacation'">
      <Button class="button--big button--blue" @click="openFile('employees.mp4')" v-if="currentUser.roles.employee">
        <span class="icon">k</span>
        КАК ДА ПУСНА ЗАЯВЛЕНИЕ ЗА ОТПУСК?
      </Button>
      <Button
        class="button--big button--lighter-green"
        @click="openFile('supervisors.mp4')"
        v-if="currentUser.roles.supervisor"
      >
        <span class="icon">k</span>
        КАК ДА ОДОБРЯ ЗАЯВЛЕНИЕ ЗА ОТПУСК?
      </Button>
    </template>
  </div>
</template>

<script>
import Button from '../Button';
import { mapGetters } from 'vuex';
export default {
  components: {
    Button,
  },
  data() {
    return {
      stage: '',
      type: '',
      allowed: true,
      allowedByHR: true,
      loaded: false,
    };
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
  },
  watch: {
    $route: {
      handler: 'getStage',
      immediate: true,
    },
  },
  methods: {
    backToAllVacations() {
      this.$router.push('/vacations');
    },
    openFile(f) {
      const url = `${process.env.VUE_APP_STATIC_URI}/${f}`;
      window.open(url);
    },
    getStage() {
      if (this.$route.params.id) {
        this.$apiService
          .get(`/vacations/${this.$route.params.id}/stage`)
          .then((res) => {
            this.stage = res.data.stage;
            this.type = res.data.type;
            this.allowed = res.data.allowed;
            this.allowedByHR = res.data.allowedByHR;
          })
          .catch((err) => {
            console.log(err);
          })
          .then(() => (this.loaded = true));
      } else {
        this.loaded = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.sidepanel__vacation {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 70px 10px 10px;

  > img {
    display: block;
    margin: 6.5rem 0 6.5rem;
    width: 100px;
  }

  .button:not(:first-child) {
    margin-top: 5px;
  }
}
</style>
