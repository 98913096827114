<template>
  <div class="sidepanel__schedules">
    <!-- <InputSearch
      type="text"
      placeholder="Търсене на работници и служители"
      v-model="search"
      @enter="pushToRoute"
    /> -->
    <div>
      <Datepicker
        :language="bg"
        :inline="true"
        :monday-first="true"
        :minimumView="
          $route.name === 'scheduleShift' || $route.name === 'scheduleShiftAll'
            ? 'day'
            : 'month'
        "
        :key="
          $route.name === 'scheduleShift' || $route.name === 'scheduleShiftAll'
            ? 'dp-day'
            : 'dp-month'
        "
        :full-month-name="true"
        :highlighted="highlighted"
        v-model="date"
        @selected="dateSelected"
      />
      <p class="label">ТИП ГРАФИК</p>
      <router-link
        class="sidepanel__link"
        :class="{ 'router-link-exact-active': $route.name === 'scheduleGroup' }"
        :to="`/schedule/group?date=${date.toISOString()}`"
        >ГРУПОВ ГРАФИК</router-link
      >
      <router-link
        class="sidepanel__link"
        :class="{ 'router-link-exact-active': $route.name === 'F76' }"
        :to="`/schedule/f76?date=${date.toISOString()}`"
        v-if="currentUser.roles.f76"
        >ФОРМА 76</router-link
      >
      <router-link
        class="sidepanel__link"
        :class="{
          'router-link-exact-active': $route.name === 'scheduleShift',
        }"
        :to="`/schedule/shift?date=${date.toISOString()}`"
        v-if="currentUser.roles.supervisor"
        >Поименен списък работници – служители</router-link
      >
      <router-link
        class="sidepanel__link"
        :class="{
          'router-link-exact-active': $route.name === 'scheduleShiftAll',
        }"
        :to="`/schedule/shift-all?date=${date.toISOString()}`"
        v-if="currentUser.roles.admin || currentUser.roles.hr"
        >Всички работници – служители</router-link
      >
    </div>
    <transition name="fade" appear>
      <div
        class="legend"
        key="legendScheduleGroup"
        v-if="$route.name === 'scheduleGroup' && scheduleGroup._id"
      >
        <p class="label">ЛЕГЕНДА</p>
        <p
          class="legend-shift"
          v-for="(n, i) in scheduleGroup.totalShifts"
          :key="`shift-${i}`"
        >
          <span class="circle circle--solid" :class="`circle--${n}`">{{
            scheduleGroup.shifts[i].name
          }}</span>
          <span>{{ scheduleGroup.shifts[i].hours }}</span>
        </p>
        <p class="legend-shift">
          <span class="circle circle--solid circle--null">П</span>
          <span>почивка</span>
        </p>
      </div>
      <div
        class="legend"
        key="legendScheduleShift"
        v-if="
          $route.name === 'scheduleShift' || $route.name === 'scheduleShiftAll'
        "
      >
        <p class="label">ЛЕГЕНДА</p>
        <p class="legend-item legend-item--planned">ПЛАНИРАН ГРАФИК</p>
        <p class="legend-item legend-item--presence">ПРИСЪСТВИЕ</p>
        <p class="legend-item legend-item--vacation">ОДОБРЕН ОТПУСК</p>
        <p class="legend-item legend-item--maternity">МАЙЧИНСТВО</p>
        <p class="legend-item legend-item--illness">БОЛНИЧЕН</p>
        <p class="legend-item legend-item--self-release">САМООТЛЪЧКА</p>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
// import InputSearch from '@/components/inputs/InputSearch';
import Datepicker from "vuejs-datepicker";
import { bg } from "vuejs-datepicker/dist/locale";
export default {
  components: {
    // InputSearch,
    Datepicker,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    $route: {
      handler: "checkScheduleGroupQuery",
      immediate: true,
    },
  },
  data() {
    return {
      bg,
      date: this.$route.query.date
        ? new Date(this.$route.query.date)
        : new Date(),
      search: "",
      highlighted: {
        from: null,
        to: null,
      },
      scheduleGroup: {},
    };
  },
  methods: {
    checkScheduleGroupQuery(to) {
      if (to.query.scheduleGroup) {
        this.getScheduleGroup(to.query.scheduleGroup);
      } else {
        this.scheduleGroup = {};
      }
    },
    getScheduleGroup(s) {
      this.$apiService.get(`/schedule-groups/${s}`).then((res) => {
        this.scheduleGroup = res.data;
      });
    },
    dateSelected(d) {
      const now = new Date();

      const route = {
        scheduleGroup: "group",
        scheduleShift: "shift",
        scheduleShiftAll: "shift-all",
        F76: "f76",
      };

      this.$router.push(
        `/schedule/${
          route[this.$route.name]
        }?date=${d.toISOString()}&t=${now.getTime()}`
      );

      this.updateWeek(d);
    },
    updateWeek(d) {
      const date = new Date(d);
      this.highlighted.from = new Date(date).getFirstDayOfWeek();
      this.highlighted.to = new Date(date).getLastDayOfWeek();
      this.highlighted.from.setHours(
        this.highlighted.from.getHours(),
        this.highlighted.from.getMinutes(),
        0,
        0
      );
      this.highlighted.to.setHours(
        this.highlighted.to.getHours(),
        this.highlighted.to.getMinutes(),
        0,
        0
      );
    },
    pushToRoute() {
      // this.$router.push('/settings/schedule-groups');
    },
  },
  mounted() {
    this.updateWeek(this.date);
  },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";
.sidepanel__schedules {
  align-items: center;
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
  padding: 10px;

  > div {
    width: 100%;
  }

  .vdp-datepicker {
    color: #fff;
    width: 100%;
    .vdp-datepicker__calendar {
      background: transparent;
      border-color: transparent;
      width: 100%;
      header {
        .prev,
        .next {
          border-bottom-right-radius: 4px;
          border-top-left-radius: 4px;
          color: transparent;
          text-indent: initial;
          @include transition($transition);
          &::after {
            align-items: center;
            border: 0;
            content: "D";
            color: #fff;
            display: flex;
            font-size: 0.8rem;
            font-family: "icons";
            height: 100%;
            justify-content: flex-start;
            left: 0;
            margin: 0;
            opacity: 1;
            top: 0;
            width: 100%;
            @include transform(initial);
          }
          &.disabled::after {
            border: 0;
            opacity: 0.1;
          }
        }
        .next {
          border-radius: 0;
          border-bottom-left-radius: 4px;
          border-top-right-radius: 4px;
          &::after {
            content: "V";
            justify-content: flex-end;
          }
        }
        .prev:not(.disabled):hover,
        .next:not(.disabled):hover,
        .up:not(.disabled):hover {
          background-color: transparent;
          opacity: 0.5;
        }
        span {
          font-size: $px14;
          font-weight: 700;
          text-transform: uppercase;
        }
      }
      .cell:not(.blank):not(.disabled).day:hover,
      .cell:not(.blank):not(.disabled).month:hover,
      .cell:not(.blank):not(.disabled).year:hover {
        border-color: transparent;
        opacity: 0.75;
      }
      .cell {
        font-weight: 700;
        @include transition($transition);
        &.day-header {
          opacity: 0.5;
          text-transform: uppercase;
        }
        &.selected {
          background: transparent;
          color: $darkOrange;
        }
        &.highlighted {
          background: #dcf7ff;
          &:not(.selected) {
            color: #3d3d3d;
          }
          &.highlight-start {
            border-bottom-left-radius: 100px;
            border-top-left-radius: 100px;
          }
          &.highlight-end {
            border-bottom-right-radius: 100px;
            border-top-right-radius: 100px;
          }
        }
      }
    }
  }
  .label {
    border-bottom: 1px solid #fff;
    color: #fff;
    font-size: $px11;
    font-weight: 700;
    margin-bottom: 0.5rem;
    padding: 0.5rem 0;
    width: 100%;
  }

  .legend {
    width: 100%;
    .legend-item {
      background-color: #fff;
      border: 1px solid #95989a;
      border-radius: 2px;
      font-size: $px11;
      font-weight: 500;
      padding: 6px 14px;
      &:not(:last-child) {
        margin-bottom: 3px;
      }
      &.legend-item--presence {
        background-color: #edffc8;
        border: 1px solid #6c9f01;
      }
      &.legend-item--vacation {
        background-color: #cbfdfd;
        border: 1px solid #14d2f9;
      }
      &.legend-item--maternity {
        background-color: #fdcbfb;
        border: 1px solid #8400b2;
      }
      &.legend-item--illness {
        background-color: #ffcccc;
        border: 1px solid #ce614b;
      }
      &.legend-item--self-release {
        background-color: #d4d4d4;
        border: 1px solid #505050;
      }
    }
    .legend-shift {
      color: #fff;
      margin: 6px 0;
      > span:not(.circle) {
        font-size: $px11;
        font-weight: 700;
      }
      &:last-child {
        margin-bottom: 0;
      }
    }
  }
}
</style>
