var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidepanel no-print",class:{
    'sidepanel--in': _vm.isAuthenticated,
    'sidepanel--hidden': !_vm.isSidePanelOpen || _vm.isAuthenticated === undefined,
  }},[_c('div',{staticClass:"sidepanel__toggle",on:{"click":function($event){return _vm.$store.dispatch('toggleSidePanel')}}},[_c('img',{attrs:{"src":require("@/assets/img/sidepanel/trapezoid.svg"),"alt":"Trapezoid for sidepanel opening/closing"}}),_c('img',{staticClass:"arrow",attrs:{"src":require("@/assets/img/sidepanel/arrow.svg"),"alt":"Arrow"}}),_c('Tooltip',{attrs:{"tooltip":_vm.getTooltip}})],1),_c('transition',{attrs:{"name":"fade","mode":"out-in"}},[(_vm.$route.name === 'vacations' || _vm.$route.name === 'remainingVacations')?_c('Vacations',{key:"sidepanel__vacations"}):(_vm.$route.name === 'newVacation' || _vm.$route.name === 'editVacation')?_c('Vacation',{key:"sidepanel__vacation"}):(_vm.$route.name === 'vacationsHourly')?_c('VacationsHourly',{key:"sidepanel__vacations"}):(_vm.$route.name === 'newVacationHourly' || _vm.$route.name === 'editVacationHourly')?_c('VacationHourly',{key:"sidepanel__vacation"}):(
        _vm.$route.name === 'settingsHolidays' ||
        _vm.$route.name === 'settingsScheduleGroups' ||
        _vm.$route.name === 'settingsTemplates' ||
        _vm.$route.name === 'settingsImportExport' ||
        _vm.$route.name === 'settingsF76Names' ||
        _vm.$route.name === 'settingsHomeOfficeCategories'
      )?_c('Settings',{key:"sidepanel__settings"}):(_vm.$route.name === 'settingsTemplatesEdit' || _vm.$route.name === 'settingsTemplatesVacationHourlyEdit')?_c('Templates',{key:"sidepanel__settings-template"}):(_vm.$route.name === 'settingsScheduleGroup')?_c('settingsScheduleGroup',{key:"sidepanel__settings-schedule"}):(
        _vm.$route.name === 'schedule' ||
        _vm.$route.name === 'scheduleShift' ||
        _vm.$route.name === 'scheduleShiftAll' ||
        _vm.$route.name === 'scheduleGroup' ||
        _vm.$route.name === 'F76'
      )?_c('Schedule',{key:"sidepanel__schedule"}):_c('div',{key:"default-state",staticClass:"portal-animation"},[_c('img',{attrs:{"src":require("@/assets/img/sidepanel/portal-animation.svg")}})]),_vm._v(" "+_vm._s(_vm.$route.name)+" ")],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }