<template>
  <div class="sidepanel__vacations">
    <InputSearch type="text" placeholder="Търсене на работници и служители" v-model="search" @enter="triggerSearch" />
    <Button class="button--big button--blue" @click="newVacation"> НОВ ОТПУСК </Button>
    <div class="sidepanel__vacations__dates">
      <span class="icon">C</span>
      <div class="dates">
        <span class="date-toggle" @click="selectDate = 'dateFrom'">
          <strong>От </strong>
          <span v-if="dateFrom">{{ dateFrom | formatDate }}</span>
          <span v-else>Избери</span>
        </span>
        <span class="date-toggle" @click="selectDate = 'dateTo'">
          <strong>До </strong>
          <span v-if="dateTo">{{ dateTo | formatDate }}</span>
          <span v-else>Избери</span>
        </span>
      </div>
    </div>
    <div class="sidepanel__vacations__types">
      <a
        class="sidepanel__link sidepanel__link--remaining"
        :href="`${config.loginPath}/users/${currentUser._id}/vacations?backUrl=${url}&backLabel=НАЗАД КЪМ ОТПУСКИ`"
      >
      ИЗПОЛЗВАН ОТПУСК И ОСТАТЪК
      </a>

      <transition name="fade" mode="out-in">
        <p
          v-if="totalWaitingV"
          class="sidepanel__link sidepanel__link--remaining sidepanel__link--orange"
          @click="openSignatureModal = true"
        >
          ПОДПИШИ ВСИЧКИ ЧАКАЩИ ОТПУСКИ
        </p>
      </transition>

      <div
        class="sidepanel__vacations__type"
        :class="{ active: $route.name !== 'remainingVacations' && type === '' }"
        @click="selectType('')"
      >
        <div class="texts">
          <p>ВСИЧКИ ВИДОВЕ ОТПУСК</p>
          <span>{{ totals.total }} бр. в избрания период</span>
        </div>
      </div>
      <div class="sidepanel__vacations__type" :class="{ active: type === 'paid' }" @click="selectType('paid')">
        <div class="texts">
          <p>Платен годишен отпуск</p>
          <span>{{ totals.paid }} бр. в избрания период</span>
        </div>
      </div>
      <div class="sidepanel__vacations__type" :class="{ active: type === 'death' }" @click="selectType('death')">
        <div class="texts">
          <p>Отпуск за смърт</p>
          <span>{{ totals.death }} бр. в избрания период</span>
        </div>
      </div>
      <div class="sidepanel__vacations__type" :class="{ active: type === 'marriage' }" @click="selectType('marriage')">
        <div class="texts">
          <p>Отпуск за брак</p>
          <span>{{ totals.marriage }} бр. в избрания период</span>
        </div>
      </div>
      <div
        class="sidepanel__vacations__type"
        :class="{ active: type === 'blood-donation' }"
        @click="selectType('blood-donation')"
      >
        <div class="texts">
          <p>Платен за кръводаряване</p>
          <span>{{ totals['blood-donation'] }} бр. в избрания период</span>
        </div>
      </div>
      <div
        class="sidepanel__vacations__type"
        :class="{ active: type === 'paid-official' }"
        @click="selectType('paid-official')"
        v-if="activeCompany === 'КЦМ АД'"
      >
        <div class="texts">
          <p>Платен служебен отпуск</p>
          <span>{{ totals['paid-official'] }} бр. в избрания период</span>
        </div>
      </div>
      <div
        class="sidepanel__vacations__type"
        :class="{ active: type === 'paid-father' }"
        @click="selectType('paid-father')"
      >
        <div class="texts">
          <p>Отпуск за баща, при раждане на дете</p>
          <span>{{ totals['paid-father'] }} бр. в избрания период</span>
        </div>
      </div>
      <div
        class="sidepanel__vacations__type"
        :class="{ active: type === 'paid-student' }"
        @click="selectType('paid-student')"
      >
        <div class="texts">
          <p>Платен ученически отпуск</p>
          <span>{{ totals['paid-student'] }} бр. в избрания период</span>
        </div>
      </div>
      <div
        class="sidepanel__vacations__type"
        :class="{ active: type === 'paid-graduation' }"
        @click="selectType('paid-graduation')"
      >
        <div class="texts">
          <p>Платен ученически отпуск за дипломиране</p>
          <span>{{ totals['paid-graduation'] }} бр. в избрания период</span>
        </div>
      </div>
      <div
        class="sidepanel__vacations__type"
        :class="{ active: type === 'unpaid-student' }"
        @click="selectType('unpaid-student')"
      >
        <div class="texts">
          <p>Неплатен ученически отпуск</p>
          <span>{{ totals['unpaid-student'] }} бр. в избрания период</span>
        </div>
      </div>
      <div class="sidepanel__vacations__type" :class="{ active: type === 'unpaid' }" @click="selectType('unpaid')">
        <div class="texts">
          <p>Неплатен отпуск</p>
          <span>{{ totals.unpaid }} бр. в избрания период</span>
        </div>
      </div>
      <div
        class="sidepanel__vacations__type"
        :class="{ active: type === 'paid-union' }"
        @click="selectType('paid-union')"
        v-if="activeCompany === 'КЦМ АД'"
      >
        <div class="texts">
          <p>Платен отпуск синдикални дейци</p>
          <span>{{ totals['paid-union'] }} бр. в избрания период</span>
        </div>
      </div>
      <div
        class="sidepanel__vacations__type"
        :class="{ active: type === 'paid-schedule' }"
        @click="selectType('paid-schedule')"
        v-if="activeCompany === 'КЦМ АД'"
      >
        <div class="texts">
          <p>Отпуск по график</p>
          <span>{{ totals['paid-schedule'] }} бр. в избрания период</span>
        </div>
      </div>
    </div>
    <!-- MODALS -->
    <transition name="fade">
      <DatepickerModal v-if="selectDate" :value="this[selectDate]" @selected="selectedDate" @close="selectDate = ''" />
    </transition>
    <SignatureModal
      v-if="openSignatureModal"
      @signed="finishWaitingV"
      @close="openSignatureModal = false"
      :director="totalWaitingV"
    />
  </div>
</template>

<script>
import InputSearch from '@/components/inputs/InputSearch';
import DatepickerModal from '@/components/inputs/DatepickerModal';
import Button from '@/components/Button';
import SignatureModal from '@/components/signature/SignatureModal';
import { mapGetters } from 'vuex';
import config from '../../../config-public.json';
export default {
  components: {
    InputSearch,
    DatepickerModal,
    Button,
    SignatureModal,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
    url() {
      return location.href;
    },
  },
  data() {
    return {
      config,
      selectDate: '',
      openSignatureModal: false,
      waitingVacations: [],
      totalWaitingV: 0,
      search: '',
      dateFrom: null,
      dateTo: null,
      type: '',
      initialPage: null,
      initialStage: null,
      totals: {
        total: 0,
        paid: 0,
        unpaid: 0,
        'paid-official': 0,
        'paid-student': 0,
        'paid-graduation': 0,
        'unpaid-student': 0,
        'paid-father': 0,
        'paid-union': 0,
        'paid-schedule': 0,
        death: 0,
        marriage: 0,
        'blood-donation': 0,
      },
    };
  },
  watch: {
    $route: {
      handler: 'checkIfNeedsAnotherLoad',
    },
  },
  methods: {
    finishWaitingV(sign) {
      const promises = [];

      for (let v of this.waitingVacations) {
        if(!v.signatures.supervisorFirst) this.$set(v.signatures, 'supervisorFirst', sign);
        this.$set(v.signatures, 'supervisorSecond', sign);

        promises.push(
          new Promise((resolve) => {
            this.$apiService
              .put(`/vacations/second`, v)
              .then(() => {
                this.success = true;
              })
              .catch((err) => {
                console.log(err);
                if (err.response.data.message) {
                  this.$snotify.error(err.response.data.message);
                }
                this.success = false;
              })
              .then(() => {
                resolve();
              });
          })
        );
      }

      Promise.all(promises)
        .then(() => {
          setTimeout(() => {
            if (this.success) {
              this.$snotify.success('Отпуските са запазени успешно');
              this.$router.push('/vacations');
              this.getVacations();
            } else {
              this.success = undefined;
            }
          }, 1000);
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getVacations() {
      this.waitingVacations = [];
      this.totalWaitingV = 0;

      // let url = `/vacations?stage=confirmedByFirstSupervisor&signaturesIncluded=true`;
      let url = `/vacations?stage=waiting&signaturesIncluded=true`;

      this.$apiService
        .get(url)
        .then((res) => {
          this.waitingVacations = res.data.vacations;
          this.totalWaitingV = res.data.total;
        })
        .then(() => (this.loaded = true));
    },
    newVacation() {
      if (!this.currentUser.roles.admin && !this.currentUser.roles.hr && !this.currentUser.roles.supervisor) {
        if (this.currentUser.supervisorFirst && this.currentUser.supervisorSecond) {
          this.$router.push('/vacations/new');
        } else {
          return this.$snotify.error('Служителят няма избрани първо и/или второ ниво одобряващи');
        }
      } else {
        this.$router.push('/vacations/new');
      }
    },
    triggerSearch() {
      if (this.$route.query.search !== this.search) this.pushToRoute();
    },
    selectedDate(e) {
      this[this.selectDate] = e;
      this.selectDate = '';

      this.pushToRoute();
      this.getTotals();
    },
    selectType(t) {
      if (this.type !== t || this.$route.name === 'remainingVacations') {
        this.type = t;
        this.pushToRoute();
      }
    },
    pushToRoute() {
      const query = {};

      if (this.search) {
        query.search = this.search;
      }

      if (this.dateFrom && this.dateTo) {
        query.dateFrom = this.dateFrom.toISOString();
        query.dateTo = this.dateTo.toISOString();
      }

      if (this.type) {
        query.type = this.type;
      }

      if (this.initialPage) {
        query.page = this.initialPage;
        this.initialPage = null;
      }

      if (this.initialStage) {
        query.stage = this.initialStage;
        this.initialStage = null;
      }

      query.t = new Date().getTime();

      this.$router.push({
        path: '/vacations',
        query: query,
      });
    },
    getTotals() {
      // TODO: get all totals with one request
      for (let t in this.totals) {
        this.$apiService
          .get(
            `/vacations?count=true${
              t === 'total' ? null : `&type=${t}`
            }&dateFrom=${this.dateFrom.toISOString()}&dateTo=${this.dateTo.toISOString()}`
          )
          .then((result) => {
            this.totals[t] = result.data || 0;
          });
      }
    },
    load() {
      if (this.$route.query.dateFrom && this.$route.query.dateTo) {
        this.dateFrom = new Date(this.$route.query.dateFrom);
        this.dateTo = new Date(this.$route.query.dateTo);
      } else {
        const date = new Date();
        this.dateFrom = new Date(date.getFullYear(), date.getMonth() - 1, 1);
        this.dateTo = new Date(date.getFullYear(), date.getMonth() + 2, 0);
      }

      if (this.$route.query.search) {
        this.search = this.$route.query.search;
      }

      if (this.$route.query.type) {
        this.type = this.$route.query.type;
      }

      if (this.$route.query.page) {
        this.initialPage = this.$route.query.page;
      }

      if (this.$route.query.stage) {
        this.initialStage = this.$route.query.stage;
      }
    },
    checkIfNeedsAnotherLoad() {
      if (!Object.keys(this.$route.query).length && this.$route.name !== 'remainingVacations') {
        this.pushToRoute();
      }
    },
  },
  mounted() {
    this.load();

    this.getTotals();
    this.pushToRoute();

    if (this.currentUser.number === '3324') {
      this.getVacations();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.sidepanel__vacations {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
}
.button {
  font-weight: 700;
  margin: 10px 0;
}
.sidepanel__vacations__dates {
  align-items: center;
  border-bottom: 1px solid #ffffff80;
  border-top: 1px solid #ffffff80;
  color: #fff;
  display: flex;
  justify-content: space-between;
  padding: 10px;
  width: 100%;
  > .icon {
    font-size: 18px;
  }
  .date-toggle {
    @include hover();
    &:last-child {
      margin-left: 1rem;
    }
  }
}

.sidepanel__link--remaining {
  background-color: #176fbf;
  border-radius: 2px;
  color: white;
  font-family: Arial, Helvetica, sans-serif;
  font-size: $px12;
  font-weight: 600;
  justify-content: center;
}

.sidepanel__link--orange {
  background-color: #e65100;
  cursor: pointer;
}

.sidepanel__link--remaining::after {
  font-size: 9px;
  right: 13px;
}
.sidepanel__link--remaining:hover {
  mix-blend-mode: difference;
}

.sidepanel__vacations__types {
  color: #fff;
  margin-top: 10px;
  width: 100%;
}
.sidepanel__vacations__type {
  align-items: center;
  border-radius: 2px;
  cursor: pointer;
  display: flex;
  margin: 3px 0;
  padding: 5px;
  position: relative;
  width: 100%;
  @include transition($transition);
  @include noselect();
  .texts {
    display: flex;
    flex-direction: column;
    height: 30px;
    padding: 1px 0;
    justify-content: space-between;
    width: 100%;
    p {
      font-size: $px12;
      line-height: $px12;
      max-width: 100%;
      font-weight: 700;
      width: 100%;
    }
    span {
      font-size: $px10;
    }
  }
  &::before {
    align-items: center;
    background-color: $lightBlue;
    border-radius: 5px;
    color: #fff;
    content: 'Z';
    display: inline-flex;
    font-family: 'icons';
    font-size: 18px;
    height: 30px;
    justify-content: center;
    margin-right: 9px;
    min-width: 30px;
    width: 30px;
  }
  &::after {
    content: 'V';
    font-family: 'icons';
    font-size: 9px;
    position: absolute;
    right: 10px;
    top: 50%;
    @include transform(translateY(-50%));
  }
  &:hover {
    background-color: rgba($color: #e0eaf3, $alpha: 0.1);
  }
  &.active {
    background-color: #e0eaf3;
    color: $darkBlue;
  }
}

@media screen and (max-width: $xs) {
  .sidepanel__vacations__type {
    .texts {
      justify-content: center;
      p {
        font-size: $px10;
        line-height: $px10;
      }
      span {
        font-size: $px9;
      }
    }
  }
}
</style>
