import Holidays from '@/views/settings/Holidays.vue'
import ScheduleGroups from '@/views/settings/ScheduleGroups.vue'
import ScheduleGroup from '@/views/settings/ScheduleGroup.vue'
import Templates from '@/views/settings/Templates.vue'

export default [{
    path: '/settings',
    beforeEnter: (to, from, next) => {
      next({
        path: '/settings/holidays'
      })
    }
  },
  {
    path: '/settings/holidays',
    name: "settingsHolidays",
    component: Holidays,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin']
    },
  },
  {
    path: '/settings/schedule-groups',
    name: "settingsScheduleGroups",
    component: ScheduleGroups,
    meta: {
      requiresAuth: true,
      companiesAllowed: ['КЦМ АД'],
      rolesAllowed: ['admin'],
    },
  },
  {
    path: '/settings/schedule-group/:id?',
    name: "settingsScheduleGroup",
    component: ScheduleGroup,
    meta: {
      requiresAuth: true,
      companiesAllowed: ['КЦМ АД'],
      rolesAllowed: ['admin'],
    },
  },
  {
    path: '/settings/templates',
    name: "settingsTemplates",
    component: Templates,
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin']
    },
  },
  {
    path: '/settings/templates/common',
    name: "settingsTemplatesEdit",
    component: () => import('@/views/settings/Template.vue'),
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin']
    },
  },
  {
    path: '/settings/templates/vacation-hourly',
    name: "settingsTemplatesVacationHourlyEdit",
    component: () => import('@/views/settings/TemplateVacationHourly.vue'),
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin']
    },
  },
  {
    path: '/settings/import-export',
    name: "settingsImportExport",
    component: () => import('@/views/settings/ImportExport.vue'),
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin']
    },
  },
  {
    path: '/settings/f76-names',
    name: "settingsF76Names",
    component: () => import('@/views/settings/F76Names.vue'),
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin', 'hr'],
      companiesAllowed: ['КЦМ АД'],
    },
  },
  {
    path: '/settings/home-office-categories',
    name: "settingsHomeOfficeCategories",
    component: () => import('@/views/settings/HomeOfficeCategories.vue'),
    meta: {
      requiresAuth: true,
      rolesAllowed: ['admin', 'hr'],
      companiesAllowed: ['КЦМ АД'],
    },
  },
]