var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"schedule"},[(_vm.currentSchedule < _vm.totalSchedules)?[_vm._m(0),_c('p',{staticClass:"center percent"},[_vm._v(" "+_vm._s(Math.floor((_vm.currentSchedule / _vm.totalSchedules) * 100))+"% ")])]:_c('main',[_c('table',[_c('thead',[_c('tr',_vm._l((_vm.dates),function(d,i){return _c('th',{key:`th-${i}`},[_c('p',[_vm._v(_vm._s(_vm._f("formatDateMini")(d.fullDate)))]),_c('span',[_vm._v(_vm._s(_vm._f("formatDayName")(d.fullDate)))])])}),0)]),_c('tbody',_vm._l((_vm.schedules),function(s,i){return _c('tr',{key:`td-shift-${i}`},[_vm._l((_vm.dates),function(d,j){return [(
                d.schedules
                  ? d.schedules[i]
                    ? d.schedules[i].scheduleGroup
                      ? d.schedules[i].scheduleGroup._id
                      : false
                    : false
                  : false
              )?_c('td',{key:`td-${j}`},[(d.schedules[i].loading)?_c('p',{staticClass:"center"},[_vm._v("зареждане...")]):[(
                    d.schedules[i].scheduleGroup
                      ? d.schedules[i].scheduleGroup._id
                      : false
                  )?_c('header',{class:`header--${d.schedules[i].shift}`},[_c('div',{staticClass:"icon-and-shift"},[_c('span',{staticClass:"icon"},[_vm._v("e")]),_c('span',{staticClass:"user-schedule"},[_vm._v(_vm._s(d.schedules[i]._id))])]),_c('span',{key:`shift-sp-${j}`,staticClass:"circle circle--solid",class:`circle--${d.schedules[i].shift}`},[_vm._v(" "+_vm._s(d.schedules[i].shift ? d.schedules[i].scheduleGroup.shifts[ d.schedules[i].shift - 1 ].name : "П")+" ")])]):_vm._e(),(
                    d.schedules[i].users ? d.schedules[i].users.length : false
                  )?_c('main',_vm._l((d.schedules[i].users),function(u){return _c('p',{key:`td-${j}-${u._id}`,staticClass:"user-item",class:{
                      'user-item--presence': u.presence,
                      'user-item--vacation': u.vacations,
                      'user-item--illness': u.absence === 'illness',
                      'user-item--maternity': u.absence === 'maternity',
                      'user-item--self-release': u.absence === 'self-release',
                      'user-item--shift-change': u.absence === 'shift-change',
                      'user-item--clickable': true,
                    },attrs:{"title":u.comment},on:{"click":function($event){return _vm.toggleAbsence(
                        d.fullDate,
                        u,
                        d.schedules[i].scheduleGroup,
                        d.schedules[i]._id,
                        i,
                        d.schedules[i].shift - 1
                      )}}},[_vm._v(" "+_vm._s(u.name)+" "),_c('transition',{attrs:{"name":"fade","appear":""}},[(u.absence === 'shift-change')?_c('span',{staticClass:"circle circle--mini",class:`circle--orange`},[_vm._v(" "+_vm._s(u.shift || "П")+" ")]):_vm._e()])],1)}),0):_c('p',{staticClass:"center"},[(d.schedules[i].scheduleGroup._id)?[_vm._v("Няма данни")]:_vm._e()],2)]],2):_vm._e()]})],2)}),0)])]),(_vm.selectedDate && _vm.selectedUser)?_c('AbsenceModal',{attrs:{"user":_vm.selectedUser,"date":_vm.selectedDate,"scheduleGroup":_vm.selectedScheduleGroup,"schedule":_vm.selectedSchedule,"shift":_vm.selectedShift},on:{"close":_vm.closeAbsence}}):_vm._e()],2)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"loading"},[_c('img',{attrs:{"src":require("@/assets/img/loading_schedule.svg"),"alt":"Зареждане..."}})])
}]

export { render, staticRenderFns }