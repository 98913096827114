var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"sidepanel__schedules"},[_c('div',[_c('Datepicker',{key:_vm.$route.name === 'scheduleShift' || _vm.$route.name === 'scheduleShiftAll'
          ? 'dp-day'
          : 'dp-month',attrs:{"language":_vm.bg,"inline":true,"monday-first":true,"minimumView":_vm.$route.name === 'scheduleShift' || _vm.$route.name === 'scheduleShiftAll'
          ? 'day'
          : 'month',"full-month-name":true,"highlighted":_vm.highlighted},on:{"selected":_vm.dateSelected},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}}),_c('p',{staticClass:"label"},[_vm._v("ТИП ГРАФИК")]),_c('router-link',{staticClass:"sidepanel__link",class:{ 'router-link-exact-active': _vm.$route.name === 'scheduleGroup' },attrs:{"to":`/schedule/group?date=${_vm.date.toISOString()}`}},[_vm._v("ГРУПОВ ГРАФИК")]),(_vm.currentUser.roles.f76)?_c('router-link',{staticClass:"sidepanel__link",class:{ 'router-link-exact-active': _vm.$route.name === 'F76' },attrs:{"to":`/schedule/f76?date=${_vm.date.toISOString()}`}},[_vm._v("ФОРМА 76")]):_vm._e(),(_vm.currentUser.roles.supervisor)?_c('router-link',{staticClass:"sidepanel__link",class:{
        'router-link-exact-active': _vm.$route.name === 'scheduleShift',
      },attrs:{"to":`/schedule/shift?date=${_vm.date.toISOString()}`}},[_vm._v("Поименен списък работници – служители")]):_vm._e(),(_vm.currentUser.roles.admin || _vm.currentUser.roles.hr)?_c('router-link',{staticClass:"sidepanel__link",class:{
        'router-link-exact-active': _vm.$route.name === 'scheduleShiftAll',
      },attrs:{"to":`/schedule/shift-all?date=${_vm.date.toISOString()}`}},[_vm._v("Всички работници – служители")]):_vm._e()],1),_c('transition',{attrs:{"name":"fade","appear":""}},[(_vm.$route.name === 'scheduleGroup' && _vm.scheduleGroup._id)?_c('div',{key:"legendScheduleGroup",staticClass:"legend"},[_c('p',{staticClass:"label"},[_vm._v("ЛЕГЕНДА")]),_vm._l((_vm.scheduleGroup.totalShifts),function(n,i){return _c('p',{key:`shift-${i}`,staticClass:"legend-shift"},[_c('span',{staticClass:"circle circle--solid",class:`circle--${n}`},[_vm._v(_vm._s(_vm.scheduleGroup.shifts[i].name))]),_c('span',[_vm._v(_vm._s(_vm.scheduleGroup.shifts[i].hours))])])}),_c('p',{staticClass:"legend-shift"},[_c('span',{staticClass:"circle circle--solid circle--null"},[_vm._v("П")]),_c('span',[_vm._v("почивка")])])],2):_vm._e(),(
        _vm.$route.name === 'scheduleShift' || _vm.$route.name === 'scheduleShiftAll'
      )?_c('div',{key:"legendScheduleShift",staticClass:"legend"},[_c('p',{staticClass:"label"},[_vm._v("ЛЕГЕНДА")]),_c('p',{staticClass:"legend-item legend-item--planned"},[_vm._v("ПЛАНИРАН ГРАФИК")]),_c('p',{staticClass:"legend-item legend-item--presence"},[_vm._v("ПРИСЪСТВИЕ")]),_c('p',{staticClass:"legend-item legend-item--vacation"},[_vm._v("ОДОБРЕН ОТПУСК")]),_c('p',{staticClass:"legend-item legend-item--maternity"},[_vm._v("МАЙЧИНСТВО")]),_c('p',{staticClass:"legend-item legend-item--illness"},[_vm._v("БОЛНИЧЕН")]),_c('p',{staticClass:"legend-item legend-item--self-release"},[_vm._v("САМООТЛЪЧКА")])]):_vm._e()])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }