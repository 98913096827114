<template>
  <transition name="tooltip" appear>
    <div
      class="tooltip"
      @click.stop="showClose = !showClose"
      v-if="tooltip && !isSidePanelOpen && !closed"
      :key="tooltip"
    >
      <transition name="close">
        <span class="icon" v-if="showClose" @click.stop="close">X</span>
      </transition>
      <p>
        {{ tooltip }}
      </p>
    </div>
  </transition>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  props: ['tooltip'],
  computed: {
    ...mapGetters(['isSidePanelOpen']),
    closed() {
      return this.tt.indexOf(this.tooltip) > -1;
    },
  },
  watch: {
    tooltip() {
      this.showClose = false;
    },
  },
  data() {
    return {
      tt: JSON.parse(localStorage.getItem(`tt`)) || [],
      showClose: false,
    };
  },
  methods: {
    close() {
      this.tt.push(this.tooltip);
      localStorage.setItem('tt', JSON.stringify(this.tt));
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.tooltip {
  background: #008ef4;
  border-radius: 10px;
  bottom: 0;
  color: #fff;
  left: calc(100% + 20px);
  min-width: 200px;
  max-width: 50vw;
  padding: 1rem;
  position: absolute;
  text-align: center;
  z-index: 1;
  @include noselect();
  &::before {
    bottom: 15px;
    content: '';
    border-top: 10px solid transparent;
    border-bottom: 10px solid transparent;
    border-right: 11px solid #008ef4;
    height: 0;
    right: calc(100% - 1px);
    position: absolute;
    width: 0;
  }
  .icon {
    bottom: calc(100% + 10px);
    border-radius: 50%;
    font-size: 0.8rem;
    background-color: #008ef4;
    position: absolute;
    padding: 8px;
    right: 0;
    @include noselect();
  }
}

.tooltip-enter-active {
  @include transition(all 0.4s $cubic);
}
.tooltip-leave-active {
  @include transition(all 0.4s $cubic);
}

.tooltip-enter,
.tooltip-leave-to {
  opacity: 0;
  @include transform(translateX(-25px));
}

.close-enter-active,
.close-leave-active {
  @include transition(all 0.4s $cubic);
}

.close-enter,
.close-leave-to {
  opacity: 0;
  @include transform(translateY(25px));
}
</style>
