<template>
  <transition name="fade" appear>
    <div class="importing">
      <img src="../assets/img/loading.svg" alt="Зареждане..." />
    </div>
  </transition>
</template>

<script>
export default {
  data() {
    return {
      interval: null,
    };
  },
  mounted() {
    this.interval = setInterval(() => {
      this.$store.dispatch('checkImporting');
    }, 1000 * 60);
  },
  destroyed() {
    clearInterval(this.interval);
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.importing {
  align-items: center;
  background-color: $mainColor;
  display: flex;
  height: 100%;
  justify-content: center;
  min-height: 100vh;
  left: 0;
  padding: 1rem;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
  img {
    // width: 37.5vw;
    height: 90vh;
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .importing {
    img {
      width: 90vw;
    }
  }
}
</style>
