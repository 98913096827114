<template>
  <div class="signature-wrapper">
    <label v-if="label">
      <strong>
        {{ label }}
      </strong>
      <template v-if="name">
        {{ name }}
      </template>
    </label>
    <div
      class="signature"
      :class="{ 'signature--disabled': disabled }"
      @click="disabled ? null : (isModalOpen = true)"
    >
      <img :src="signature" alt="Вашият подпис" v-if="signature" />
      <div class="signature__placeholder no-print" v-else>
        <p>{{ placeholder || 'Собственоръчни подпис и име' }}</p>
        <img src="../../assets/img/vacations/signature.svg" alt="Подпиши тук" />
      </div>
    </div>
    <transition name="fade">
      <SignatureModal
        v-if="isModalOpen"
        @signed="signed"
        @close="isModalOpen = false"
      />
    </transition>
  </div>
</template>

<script>
import SignatureModal from './SignatureModal';
export default {
  props: ['signature', 'label', 'placeholder', 'name', 'disabled'],
  components: {
    SignatureModal,
  },
  data() {
    return {
      isModalOpen: false,
    };
  },
  methods: {
    signed(sign) {
      this.$emit('signed', sign);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.signature-wrapper {
}
label {
  color: #373737;
  display: inline-flex;
  flex-direction: column;
  font-size: 0.84615rem;
  margin-bottom: 0.5rem;
}
.signature {
  background-color: #fdfdfd;
  border-radius: 1px;
  border: 1px solid #ababab;
  cursor: pointer;
  height: 80px;
  width: 330px; // 4.125 : 1
  &.signature--disabled {
    cursor: default;
  }
  .signature__placeholder {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: space-between;
    padding: 0 1rem;
    width: 100%;
    @include transition($transition);
    p {
      color: #8e8e8e;
      font-size: $px12;
      font-style: italic;
    }
    img {
      height: 80%;
    }
  }
  > img {
    height: 100%;
    width: 100%;
  }

  &:hover {
    .signature__placeholder {
      background-color: #e0eaf3;
    }
  }
}

// @media screen and (max-width: $s) {
//   .signature {
//     // height: 23.7576vw;
//     // width: 98vw;
//     // height: 22.22vw;
//     // width: 91.66vw;
//   }
// }
@media screen and (max-width: $xxs) {
  .signature {
    height: 67.87px;
    width: 280px;
  }
}

@media print {
  .signature-wrapper {
    align-items: flex-end;
    display: flex;
  }
  label {
    white-space: nowrap;
  }
  .signature {
    background-color: transparent;
    border: initial;
    border-bottom: 1px dashed #505050;
    border-radius: 0;
    height: 40px;
    width: 165px;
  }
}
</style>
