<template>
  <div class="settings__f76">
    <div class="tiny__content">
      <p class="tiny__content__title">ПАРАМЕТРИ ЗА СПРАВКИ ПО ФОРМА 76</p>
      <hr class="line" />
      <p>
        <strong>
          Справка по Форма 76 може да се направи или за график, или за конкретен
          служител
        </strong>
      </p>
      <div class="row">
        <Input
          label="Справка за Форма 76"
          placeholder="Избор на Форма 76"
          v-model="f76"
           prop="name"
          :disabled="loading"
          :list="f76s"
          @input="selected()"
          @focus="getF76Names"
        />
        <Input
          label="Справка за служител"
          placeholder="Име на служител"
          v-model="user"
          prop="name"
          :list="employees"
          @input="selected()"
          @focus="getEmployees"
          @search="getEmployees"
        />
        <!-- <Input
          label="Справка за месец"
          placeholder="Справка за месец"
          v-model="date"
          :month="true"
          :datepicker="true"
          @input="selected()"
          @focus="getEmployees"
          @search="getEmployees"
        /> -->
      </div>
      <div class="row">
        <transition name="fade">
          <Loading :success="success" v-if="loading" />
        </transition>
        <Button
          class="button--green"
          @click="getF76()"
          :disabled="disabled || !f76"
          >Свали Excel файл</Button
        >
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Input from "@/components/inputs/Input";
import Button from "@/components/Button";
import Loading from "@/components/Loading";
export default {
  components: {
    Input,
    Button,
    Loading,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      f76s: [],
      employees: [],
      user: null,
      // f76: null,
      f76: "",
      disabled: true,
      loading: false,
      success: undefined,
    };
  },
  methods: {
    getF76Names() {
      this.f76s = [];
      this.$apiService.get("/f76-names").then((res) => {
        this.f76s = res.data;
      });
    },
    getEmployees(search) {
      this.employees = [];
      let url = "/users/employees";

      if (search) {
        url += `?search=${search}`;
      }

      this.$apiService.get(url).then((res) => {
        this.employees = res.data;
      });
    },
    selected() {
      if (this.f76) {
        this.getF76(true);
      }
    },
    getF76(allow) {
      if (allow) this.loading = true;

      let url = `/f76?date=${this.$route.query.date}`;

      url += `&by=${this.currentUser._id}&f76=${this.f76._id}`;

      if (this.user) {
        url += `&user=${this.user._id}`;
      }

      if (allow) {
        url += "&allow=true";
        this.$apiService
          .get(url)
          .then((res) => {
            this.disabled = !res.data;
            this.success = res.data;
          })
          .catch((err) => {
            console.log(err);
            this.success = false;
            this.$snotify.error("Нещо се обърка. Моля опитайте отново.");
          })
          .then(() => {
            setTimeout(() => {
              this.loading = false;
              this.success = undefined;
            }, 1000);
          });
      } else {
        window.open(`${process.env.VUE_APP_BASE_URI}${url}`, "_blank");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.row {
  align-items: center;
  display: flex;
  margin: 2rem 0;
  .input-wrapper {
    width: 100%;
    &:not(:first-child) {
      margin-left: 1rem;
    }
  }
  &:last-child {
    justify-content: flex-end;
  }
}
</style>