<template>
  <div class="vacations">
    <template v-if="loaded">
      <Table>
        <thead>
          <tr>
            <th>№</th>
            <th>ДАТА</th>
            <th>ВИД ОТПУСК</th>
            <th>СЛ. НОМЕР</th>
            <th>ЗА СЛУЖИТЕЛ</th>
            <th>ПРЯК РЪК-Л</th>
            <th>
              <template v-if="activeCompany === 'КЦМ 2000'">ГЛАВЕН ИЗПЪЛНИТЕЛЕН ДИРЕКТОР</template>
              <template v-else>РЪК-Л ЦП/ОП/ТУ/ЦЕХ/ОТДЕЛ</template>
            </th>
            <th>ПЕРИОД/РАЗМЕР</th>
            <th>ПРИКАЧЕНИ ДОКУМЕНТИ</th>
            <th>
              <div class="status" :class="{ active: statusDropdown }">
                <span class="status__toggle" @click="statusDropdown = !statusDropdown">
                  СТАТУС <span class="icon">H</span>
                </span>
                <div class="status__dropdown" v-if="statusDropdown">
                  <p :class="{ active: !$route.query.stage }" @click="selectStage('')">Покажи всички</p>
                  <p :class="{ active: $route.query.stage === 'waiting' }" @click="selectStage('waiting')">
                    Чакащи за одобрение
                  </p>
                  <p :class="{ active: $route.query.stage === 'application' }" @click="selectStage('application')">
                    Към пряк Рък-л
                  </p>
                  <p
                    :class="{
                      active: $route.query.stage === 'confirmedByFirstSupervisor',
                    }"
                    @click="selectStage('confirmedByFirstSupervisor')"
                  >
                    <template v-if="activeCompany === 'КЦМ 2000'">Към Главен Изпълнителен Директор</template>
                    <template v-else>Към Рък-л ЦП/ОП/ТУ/Цех/Отдел</template>
                  </p>
                  <p
                    :class="{
                      active: $route.query.stage === 'confirmedBySecondSupervisor',
                    }"
                    @click="selectStage('confirmedBySecondSupervisor')"
                    v-if="activeCompany === 'КЦМ АД'"
                  >
                    Към ЧР
                  </p>
                  <p :class="{ active: $route.query.stage === 'annulled' }" @click="selectStage('annulled')">
                    Анулирана
                  </p>
                  <p :class="{ active: $route.query.stage === 'canceled' }" @click="selectStage('canceled')">
                    Отказана
                  </p>
                  <p :class="{ active: $route.query.stage === 'approved' }" @click="selectStage('approved')">
                    Одобрена
                  </p>
                </div>
              </div>
            </th>
          </tr>
        </thead>
        <tbody v-if="vacations.length">
          <tr v-for="v of vacations" :key="v._id" @click="openVacation(v._id)">
            <td>{{ v.number }}</td>
            <td>{{ v.dateOfSigning | formatDate }}</td>
            <td>{{ types[v.type] }}</td>
            <td>{{ v.user.number }}</td>
            <td>{{ v.user.name }}</td>
            <td>{{ v.supervisorFirst.name }}</td>
            <td>{{ v.supervisorSecond.name }}</td>
            <td>
              {{ v.dateFrom | formatDate }} - {{ v.dateTo | formatDate }}

              <template v-if="v.type === 'paid-father'"
                >({{ v.totalDays.length }} {{ v.totalDays.length === 1 ? 'ден' : 'дни' }})</template
              >
              <template v-else>({{ v.workingDays.length }} {{ v.workingDays.length === 1 ? 'ден' : 'дни' }})</template>
            </td>
            <td>
              <a
                class="file"
                v-for="(f, i) in v.files"
                :href="`${uploads}/files/${f}`"
                :key="`${v._id}-file-${i}`"
                target="_blank"
                @click.stop
              >
                <span class="icon">a</span>
                {{ i + 1 }}</a
              >
            </td>
            <td v-if="!v.allowed || !v.allowedByHR">
              <img src="@/assets/img/stages/ss-c.svg" alt="Отказан" />
            </td>
            <td v-else-if="v.stage === 'annulled'">
              <img src="@/assets/img/stages/ss-a.svg" alt="Анулиран" />
            </td>
            <td
              v-else-if="
                v.type === 'paid' || v.type === 'paid-schedule' || v.type === 'unpaid' || activeCompany === 'КЦМ 2000'
              "
            >
              <img src="@/assets/img/stages/ssa-1.svg" alt="Заявление" v-if="v.stage === 'application'" />
              <img
                src="@/assets/img/stages/ssa-2.svg"
                alt="Потвърден от първо ниво"
                v-else-if="v.stage === 'confirmedByFirstSupervisor'"
              />
              <img
                src="@/assets/img/stages/ssa-3.svg"
                alt="Потвърден от второ ниво"
                v-else-if="v.stage === 'confirmedBySecondSupervisor'"
              />
            </td>
            <td v-else>
              <img src="@/assets/img/stages/ss-1.svg" alt="Заявление" v-if="v.stage === 'application'" />
              <img
                src="@/assets/img/stages/ss-2.svg"
                alt="Потвърден от първо ниво"
                v-else-if="v.stage === 'confirmedByFirstSupervisor'"
              />
              <img
                src="@/assets/img/stages/ss-3.svg"
                alt="Потвърден от второ ниво"
                v-else-if="v.stage === 'confirmedBySecondSupervisor'"
              />
              <img src="@/assets/img/stages/ss-4.svg" alt="Потвърден от ЧР" v-else-if="v.stage === 'confirmedByHR'" />
            </td>
          </tr>
        </tbody>
        <tbody v-else>
          <tr v-if="$route.query.search">
            <td colspan="10">
              Няма резултати от търсенето. Проверете дали периода, името, или служебния номер са коректни.
            </td>
          </tr>
          <tr v-else>
            <td colspan="10">
              Няма резултати за периода
              <span v-if="$route.query.type">от този тип отпуск</span>
            </td>
          </tr>
        </tbody>
      </Table>
      <Pagination :total="total" :size="size" />
    </template>
  </div>
</template>

<script>
import Table from '@/components/Table';
import Pagination from '@/components/Pagination';
import types from '@/data/vacation-types.json';
import { mapGetters } from 'vuex';
export default {
  components: {
    Table,
    Pagination,
  },
  computed: {
    ...mapGetters(['activeCompany']),
    uploads() {
      return process.env.VUE_APP_UPLOADS_URI;
    },
  },
  data() {
    return {
      types,
      total: 0,
      size: 20,
      vacations: [],
      loaded: false,
      statusDropdown: false,
    };
  },
  watch: {
    $route: {
      handler: 'getVacations',
      immediate: true,
    },
  },
  methods: {
    selectStage(s) {
      const r = {
        path: this.$route.path,
        query: {
          ...this.$route.query,
          stage: s,
          t: new Date().getTime(),
        },
      };

      if (r.query.page) {
        delete r.query.page;
      }

      this.$router.push(r);
      this.statusDropdown = false;
    },
    getVacations() {
      this.loaded = false;
      this.vacations = [];
      this.statusDropdown = false;
      let url = `/vacations?size=${this.size}&page=${this.$route.query.page || 1}`;

      if (this.$route.query.search) {
        url += `&search=${this.$route.query.search}`;
      }

      if (this.$route.query.type) {
        url += `&type=${this.$route.query.type}`;
      }

      if (this.$route.query.dateFrom && this.$route.query.dateTo && this.$route.query.stage !== 'waiting') {
        url += `&dateFrom=${this.$route.query.dateFrom}&dateTo=${this.$route.query.dateTo}`;
      }

      if ((!this.$route.query.dateFrom || !this.$route.query.dateTo) && this.$route.query.stage !== 'waiting') {
        return;
      }

      if (this.$route.query.stage) {
        url += `&stage=${this.$route.query.stage}`;
      }

      this.$apiService
        .get(url)
        .then((res) => {
          this.vacations = res.data.vacations;
          this.total = res.data.total;
        })
        .then(() => (this.loaded = true));
    },
    openVacation(v) {
      this.$router.push(`/vacations/${v}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.vacations {
  height: 100%;
  padding: 10px;
  width: 100%;
}
.table-wrapper {
  min-height: 250px;
}
.table {
  tr {
    th:last-child,
    td:last-child {
      text-align: center;
      width: 1%;
      img {
        width: 87px;
      }
    }
  }
}
.file {
  align-items: center;
  color: #000;
  display: inline-flex;
  @include transition($transition);
  &:not(:last-child) {
    margin-right: 0.7rem;
  }
  .icon {
    font-size: 1.5rem;
    margin-right: 3px;
  }
  &:hover {
    opacity: 0.5;
  }
}
.status {
  position: relative;
  .status__toggle {
    align-items: center;
    display: inline-flex;
    position: inherit;
    z-index: 2;
    @include hover();
    @include noselect();
    .icon {
      font-weight: 400;
      margin-left: 0.25rem;
      opacity: 0.3;
    }
  }
  &.active {
    .status__toggle {
      color: #fff;
    }
  }
  .status__dropdown {
    background-color: #7d95ac;
    border-radius: 10px;
    position: absolute;
    right: 0;
    top: -0.5rem;
    padding-top: 2rem;
    z-index: 1;
    p {
      color: #fff;
      cursor: pointer;
      padding: 2px 10px;
      text-align: right;
      white-space: nowrap;
      @include transition($transition);
      &.active,
      &:hover {
        background-color: rgba($color: #000, $alpha: 0.1);
      }
    }
  }
}
</style>