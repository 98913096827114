<template>
  <main class="create-document-wrapper">
    <label
      class="create-document-input"
      :class="{
        'create-document-input--pointer': type === 'dropdown',
        'create-document-input--invalid': invalid,
        'create-document-input--disabled': disabled,
        'create-document-input--locked': locked,
        'create-document-input--auto-fill': autoFill,
      }"
      v-click-outside="clickedOutside"
    >
      <main>
        <span v-if="icon" class="icon">{{ icon }}</span>
        <span v-else-if="img" class="icon"><img :src="`${require(`@/assets/img/${img}`)}`" alt="" /></span>

        <div>
          <input
            v-if="type === 'dropdown'"
            :class="getValue ? 'has-value' : false"
            type="text"
            :placeholder="
              isDropdownOpen ? (timepicker ? 'Изберете...' : 'Търсене...') : getValue ? getValue : placeholder
            "
            v-model="search"
            :disabled="disabled || locked"
            @input="$emit('fetch', search)"
            @focus="$emit('focus'), $emit('fetch', search), (isDropdownOpen = true)"
            @blur="$emit('blur')"
            ref="dd"
          />
          <input
            v-else
            type="text"
            :readonly="autoFill || disabled || locked"
            :maxlength="maxlength ? maxlength : false"
            :placeholder="placeholder"
            :disabled="disabled"
            :value="getValue"
            @input="$emit('input', $event.target.value)"
            @focus="$emit('focus')"
            @blur="$emit('blur')"
          />
          <p>{{ label }}</p>
        </div>
        <span
          v-if="type === 'dropdown' && !disabled && !locked"
          class="icon"
          :class="{ reversed: isDropdownOpen }"
          @click.prevent="(isDropdownOpen = !isDropdownOpen), $emit('fetch', search)"
          >E</span
        >
        <span class="tooltips" v-if="autoFill">Данните се попълват автоматично</span>
      </main>

      <div
        class="create-document-input__dropdown"
        v-if="type === 'dropdown' && isDropdownOpen && !timepicker"
        @click.stop
      >
        <p
          v-for="(l, i) in getList"
          :class="{ selected: isSelected(l) }"
          :key="`dd-l-${i}`"
          @click.prevent="listItemSelected(l)"
        >
          {{ prop ? l[prop] : l }}
        </p>
        <p v-if="!getList.length && search" class="empty">няма резултати</p>
      </div>
      <div
        class="create-document-input__dropdown timepicker-dropdown"
        v-if="type === 'dropdown' && isDropdownOpen && timepicker"
        @click.stop
      >
        <section>
          <p>ЧАСОВЕ</p>
          <div class="options">
            <section>
              <span @click="setReportHour(0)" :class="{ selected: value ? isSelected(0, true) : 0 === reportHour }">0</span>
            </section>
            <section>
              <span
                @click="setReportHour(h)"
                v-for="(h, i) of hours"
                :class="{ selected: value ? isSelected(h, true) : h === reportHour }"
                :key="`${h}-${i}`"
                >{{ h }}</span
              >
            </section>
          </div>
        </section>

        <section>
          <p>МИНУТИ</p>
          <div class="options">
            <span
              @click="setReportMinute(m.value)"
              v-for="(m, i) of minutes"
              :class="{ selected: value ? isSelected(m.value, true, true) : m.value === reportMinute }"
              :key="`${m}-${i}`"
              >{{ m.name }}</span
            >
          </div>
          <img @click.prevent="isDropdownOpen = false" src="@/assets/img/reports/tick.svg" alt="" />
        </section>
      </div>
    </label>
  </main>
</template>

<script>
import ClickOutside from 'vue-click-outside';
export default {
  directives: {
    ClickOutside,
  },
  props: [
    'autoFill',
    'disabled',
    'locked', // same as disabled but without styling
    'identifier',
    'icon',
    'img',
    'label',
    'list',
    'multiple',
    'placeholder',
    'prop',
    'value',
    'type',
    'noCheckmark',
    'invalid',
    'yearFirst',
    'maxView',
    'maxlength',
    'timepicker',
  ],
  data() {
    return {
      isDropdownOpen: false,
      search: '',
      hours: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      minutes: [
        { value: 0, name: 0 },
        { value: 50, name: 30 },
      ],
      reportHour: 0,
      reportMinute: 0,
    };
  },
  computed: {
    getValue() {
      let value = this.value;
      if (value) {
        if (this.type === 'dropdown') {
          if (this.multiple) {
            value = this.prop ? this.value.map((x) => x[this.prop]).join(', ') : this.value.join(', ');
          } else value = this.prop ? this.value[this.prop] : this.value;
        }
      }

      return value;
    },
    getList() {
      if (!this.prop && !this.identifier) {
        return this.list.filter((x) => x.toLowerCase().includes(this.search.toLowerCase()));
      }
      return this.list;
    },
  },
  methods: {
    clickedOutside() {
      if (this.type === 'dropdown') {
        if (this.isDropdownOpen) {
          this.isDropdownOpen = false;
          this.search = '';
        }
      }
    },
    listItemSelected(l) {
      if (this.multiple) {
        if (this.identifier) {
          const i = this.value.map((x) => x[this.identifier]).indexOf(l[this.identifier]);
          if (i > -1) {
            const value = [...this.value];
            value.splice(i, 1);
            this.$emit('input', value);
          } else {
            this.$emit('input', [...this.value, l]);
          }
        }
      } else {
        this.$refs.dd.blur();
        this.isDropdownOpen = false;
        this.$emit('input', l);
        this.search = '';
      }
    },
    isSelected(l, isTimepicker, minutes) {
      if (this.identifier) {
        if (this.multiple) return this.value.map((x) => x[this.identifier]).indexOf(l[this.identifier]) > -1;
        else return this.value[this.identifier] === l[this.identifier];
      } else if (this.value === l) {
        return true;
      } else if (isTimepicker) {
        let reportTime = this.value.toString().split('.');
        if (minutes) {
          if (l === 0 && !reportTime[1]) return true;
          if (l === 50 && reportTime[1]) return true;
        } else {
          return Number(reportTime[0]) === l;
        }
      }
      return;
    },
    setReportMinute(m) {
      this.reportMinute = m;
      let reportTime = '';
      if (this.value) {
        reportTime = this.value.toString().split('.');
      }
      this.$emit('input', Number(`${this.value ? reportTime[0] : this.reportHour}.${m}`));
    },
    setReportHour(h) {
      this.reportHour = h;
      let reportTime = '';
      if (this.value) {
        reportTime = this.value.toString().split('.');
      }
      this.$emit( 'input',Number(`${h}.${this.value ? (reportTime[1] ? reportTime[1] : 0) : this.reportMinute ? this.reportMinute : 0}`) );
    },
  },
};
</script>

<style lang="scss">
@import '@/scss/base.scss';
.create-document-wrapper {
  width: 100%;
}
.create-document-input {
  align-items: center;
  background: #e4e8ec;
  border: 1px solid #9eafc1;
  border-radius: 6px;
  display: flex;
  height: 62px;
  position: relative;
  width: 100%;
  @include transition(all 0.3s $cubic);
  @include noselect();

  &:not(.create-document-input--locked):hover {
    background: #f1f8ff 0% 0% no-repeat padding-box;
    border: 1px solid #3b71aa;
    .tooltips {
      visibility: visible;
      opacity: 1;
    }
  }
  &:not(.create-document-input--auto-fill):focus-within {
    background: #f1f3f6 0% 0% no-repeat padding-box;
    box-shadow: inset 0px -3px 0px #637382b5;
    border: 1px solid #9eafc1;
    input {
      opacity: 0.2;
    }
    input:not(:placeholder-shown) {
      opacity: 1;
    }
  }
  &.create-document-input--invalid {
    background: #fbf4ef 0% 0% no-repeat padding-box;
    box-shadow: inset 0px -3px 0px #637382b5;
    border: 1px solid #d6624a;
  }

  &.create-document-input--pointer:not(.create-document-input--locked) {
    cursor: pointer;
    & * {
      cursor: inherit;
    }
  }
  &.create-document-input--disabled:not(.create-document-input--locked),
  &.create-document-input--auto-fill {
    cursor: default;
    opacity: 0.5;
    & * {
      cursor: inherit;
    }
  }
  &.create-document-input--auto-fill {
    opacity: 1;
  }
  > main {
    align-items: center;
    display: flex;
    height: 100%;
    padding: 0 1rem;
    width: 100%;
    > .icon {
      color: #004d94;
      font-size: 20px;
      img {
        object-fit: scale-down;
        min-width: 30px;
      }
      &:first-child {
        margin-right: 10px;
      }
      &:last-child {
        margin-left: 10px;
        @include transition($transition);
      }
      &.reversed {
        @include transform(scale(-1));
      }
    }
    > div {
      width: 100%;
      input {
        background: inherit;
        border: 0;
        color: #000;
        font-size: $px14;
        font-weight: 600;
        margin-bottom: 0.3rem;
        outline: none;
        padding: 0;
        width: 100%;

        &::placeholder {
          color: #000;
        }
      }
      p {
        font-size: $px12;
        color: rgba($color: #000, $alpha: 0.5);
      }
    }
  }

  .create-document-input__dropdown {
    background: inherit;
    border-radius: inherit;
    border: inherit;
    max-height: 25vh;
    overflow: auto;
    position: fixed;
    width: 90%;
    max-width: 600px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 999999;
    @include scrollbar(5px, #004d94);

    &.timepicker-dropdown {
      box-shadow: 0px 0px 50px #000000a8;
      border: 1px solid #707070;
      border-radius: 10px;
      cursor: default;
      display: flex;
      max-width: 260px;
      justify-content: space-between;

      > section {
        font-size: $px12;
        text-align: center;
        padding: 5px 10px 10px;
        position: relative;
        &:first-child {
          width: 65%;
        }

        .options {
          gap: 5px;
          display: flex;
          section {
            display: flex;
            flex-wrap: wrap;
            gap: 5px;
          }
        }
        p {
          opacity: 0.5;
          margin-bottom: 1rem;
        }
        span {
          background: #5f7387;
          border-radius: 3px;
          color: white;
          height: fit-content;
          cursor: pointer;
          padding: 5px;
          width: 24px;
          &.selected {
            background: #0d66d0;
          }
        }
        img {
          bottom: 10px;
          cursor: pointer;
          right: 10px;
          position: absolute;
        }
      }
    }

    > p {
      padding: 0.5rem 1rem;

      &.empty {
        cursor: default;
      }
      &:not(.empty):hover {
        background: rgba($color: #004d94, $alpha: 0.1);
      }
      &.selected {
        background: rgba($color: #004d94, $alpha: 0.2);
      }
    }
  }
}
</style>