<template>
  <span class="icon loading">
    <transition name="fade">
      <span key="ldg-1" class="loading__active" v-if="success === undefined"
        >f</span
      >
      <span key="ldg-2" class="loading__done" v-else-if="success">W</span>
      <span key="ldg-2" class="loading__error" v-else>g</span>
    </transition>
  </span>
</template>

<script>
export default {
  props: ['success'],
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.loading {
  font-size: 1.5rem;
  height: 30px;
  position: relative;
  width: 30px;
  span {
    left: 50%;
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%) translateX(-50%));

    &.loading__active {
      color: $lightAccent;
      @include animation(1s rotate linear infinite);
    }
    &.loading__done {
      color: #31970a;
    }
    &.loading__error {
      color: $darkRed;
    }
  }
}

@keyframes rotate {
  from {
    @include transform(translateY(-50%) translateX(-50%) rotate(0deg));
  }
  to {
    @include transform(translateY(-50%) translateX(-50%) rotate(360deg));
  }
}
</style>