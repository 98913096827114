<template>
  <div class="settings__templates">
    <div class="tiny__content">
      <p class="tiny__content__title">ГРАФИЦИ И СМЕНИ</p>
      <hr class="line" />
      <p class="text">
        <strong>
          Редактирайте създадените графици, или създайте нов график, базиран
          на някой от съществуващите
        </strong>
      </p>
      <router-link to="/settings/schedule-group" class="new-shift">
        <span class="circle">+</span>
        СЪЗДАЙ НОВ ГРУПОВ ГРАФИК
      </router-link>
      <p class="tiny__content__title">СЪЗДАДЕНИ ГРУПОВИ ГРАФИЦИ</p>
      <hr class="line" />
      <router-link
        class="settings__templates__link"
        v-for="(s, i) of scheduleGroups"
        :to="`/settings/schedule-group/${s._id}`"
        :key="`type-${i}`"
      >
        <p>
          <strong>
            {{ s.name }}
          </strong>
        </p>
        <p>Последна промяна - {{ s.updatedAt | formatDateFull }}</p>
      </router-link>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      scheduleGroups: [],
    };
  },
  methods: {
    getScheduleGroups() {
      this.scheduleGroups = [];
      this.$apiService.get('/schedule-groups').then((res) => {
        this.scheduleGroups = res.data;
      });
    },
  },
  mounted() {
    this.getScheduleGroups();
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.tiny__content__title:not(:first-child) {
  margin-top: 1rem;
}
.text {
  margin-bottom: $px11 / 2;
}

.new-shift {
  background-color: #e2e9ee;
  border-radius: 4px;
  color: #000;
  display: block;
  font-size: $px11;
  margin: 10px 0 20px;
  padding: 5px;
  text-align: center;
  @include noselect();
  @include hover();
  .circle {
    align-items: center;
    background-color: #006fc2;
    color: #fff;
    border-radius: 50%;
    display: inline-flex;
    font-size: $px16;
    font-weight: 700;
    height: 20px;
    justify-content: center;
    margin-right: 5px;
    width: 20px;
    @include noselect();
  }
}

.settings__templates__link {
  background-color: #f6f7fb;
  border: 1px solid #cbd3df;
  border-radius: 10px;
  display: block;
  margin-bottom: $px11 / 2;
  margin-top: 1rem;
  padding: 0.5rem 1.25rem;
  p {
    color: rgba($color: #000, $alpha: 0.7);
    font-size: $px11;
    strong {
      color: #000;
      font-size: $px13;
      text-transform: uppercase;
    }
  }
}
</style>
