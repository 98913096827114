// const companies = [
//   'КЦМ АД',
//   'КЦМ 2000',
//   'КЦМ Технолоджи ЕООД',
//   'Комплексен индустриален сервиз ЕООД',
//   'Металургремонт 21 АД',
//   'Стопански дейности КЦМ ООД',
//   'Стройсервиз ЕООД',
//   'Индустриална зона КЦМ ЕООД',
//   'Лъки Инвест АД',
//   'Върба-Батанци АД',
//   'BLZ Metalikon Ltd',
//   'MCM BV'
// ]

function getCurrentActiveCompany() {
  let output;

  document.cookie.split(';').map(x => {
    const c = x.split('=');
    if (c[0].charAt(0) == ' ') c[0] = c[0].substring(1, c[0].length);
    if (c[0] === 'activeCompany') {
      output = decodeURIComponent(c[1]);
    }
  });

  return output;
}

const state = {
  activeCompany: getCurrentActiveCompany()
};

const getters = {
  activeCompany: state => state.activeCompany
};

const actions = {
  selectCompany(context, value) {
    context.commit('selectCompany', value);
  },
  refreshActiveCompany(context) {
    context.commit('selectCompany', getCurrentActiveCompany());
  }
};

const mutations = {
  selectCompany(state, value) {
    state.activeCompany = value;
    if (value) {
      localStorage.setItem('activeCompany', value);
    }
  }
};

export default {
  state,
  actions,
  mutations,
  getters
};
