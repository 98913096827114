<template>
  <div class="sidepanel__templates">
    <Button class="button--big button--grey" @click="backToAllScheduleGroups">
      <span class="icon">B</span>
      НАЗАД
    </Button>
    <template v-if="currentUser._id">
      <div class="sidepanel__texts">
        <p class="title">
          Групов график
        </p>
        <p v-if="$route.params.id">Редактиране</p>
        <p v-else>Създаване на нов</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '../Button';
import types from '@/data/vacation-types.json';
export default {
  components: {
    Button,
  },
  computed: {
    ...mapGetters(['currentUser']),
  },
  data() {
    return {
      types,
    };
  },
  methods: {
    backToAllScheduleGroups() {
      this.$router.push('/settings/schedule-groups');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.sidepanel__templates {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 70px 10px 10px;
}
</style>
