<template>
  <div class="settings__schedule">
    <div class="tiny__content">
      <p class="tiny__content__title">ГРУПОВ ГРАФИК - СЪЗДАВАНЕ/РЕДАКЦИЯ</p>
      <hr class="line" />
      <p>
        <strong>
          Създаването/редактирането на графика и смените се осъществява от
          няколко параметри</strong
        >
        - определяне на броя работни срещу почивни дни, броя часови зони (брой
        смени в 24 ч.), началото и края на всяка часова зона.
        <br />Следва ръчно нареждане на шаблона - комбинацията на часовите зони
        и почивните дни, така че да се получи част, която да се преизползва.
        <br />Последната стъпка е избора на всеки шаблон за смените в избрания
        график.
      </p>
      <Input
        placeholder="Име на груповия график"
        v-model.trim="scheduleGroup.name"
      />
      <div class="row">
        <Input
          label="Базирай на създаден график"
          placeholder="Базирай на създаден график"
          v-model="scheduleGroup.basedOn"
          :list="scheduleGroups"
          prop="name"
          @focus="getScheduleGroups"
          @search="getScheduleGroups"
          @item-selected="baseScheduleOnSelected"
          @input="basedOnUpdated"
          :disabled="scheduleGroup.pattern.length > 0"
        />
        <Input
          class="input-wrapper--small"
          label="Работни дни"
          :list="[1, 2, 3, 4, 5, 6, 7]"
          v-model="scheduleGroup.totalWorkingDays"
          :disabled="scheduleGroup.pattern.length > 0"
        />
        <Input
          class="input-wrapper--small"
          label="Почивни дни"
          :list="[1, 2, 3, 4, 5, 6, 7]"
          v-model="scheduleGroup.totalRestDays"
          :disabled="scheduleGroup.pattern.length > 0"
        />
        <Input
          label="Начална дата"
          placeholder="Начална дата"
          v-model="scheduleGroup.dateFrom"
          :datepicker="true"
        />
        <Input
          label="Крайна дата"
          placeholder="Крайна дата"
          v-model="scheduleGroup.dateTo"
          :datepicker="true"
        />
      </div>
      <div class="row row--separated">
        <div class="shifts-and-skip-holidays">
          <Input
            class="input-wrapper--small"
            label="Брой смени"
            v-model="scheduleGroup.totalShifts"
            :list="[1, 2, 3]"
            :disabled="scheduleGroup.pattern.length > 0"
          />
          <Checkbox
            v-model="scheduleGroup.skipHolidays"
            :disabled="started"
            label="Почивка при празник"
          />
        </div>
        <div class="shift-inputs">
          <table>
            <tr>
              <td></td>
              <td>Общо работни часове</td>
              <td>От които нощни</td>
              <td>Легенда (работно време)</td>
            </tr>
            <!-- class="shift-input" -->
            <tr v-for="(n, i) in scheduleGroup.totalShifts" :key="`shift-${i}`">
              <td>
                <div class="circle circle--solid" :class="`circle--${n}`">
                  <input
                    type="text"
                    v-model="scheduleGroup.shifts[i].name"
                    @input="shiftNameUpdated(i)"
                    :disabled="scheduleGroup.schedules.length > 0"
                  />
                </div>
              </td>
              <td>
                <Input
                  class="input-wrapper--small"
                  v-model="scheduleGroup.shifts[i].hoursCount"
                  type="number"
                  placeholder="Часове"
                  :disabled="scheduleGroup.schedules.length > 0"
                />
              </td>
              <td>
                <Input
                  class="input-wrapper--small"
                  v-model="scheduleGroup.shifts[i].hoursCountNight"
                  type="number"
                  placeholder="Часове"
                  :disabled="scheduleGroup.schedules.length > 0"
                />
              </td>
              <td>
                <Input
                  class="input-wrapper--small"
                  v-model="scheduleGroup.shifts[i].hours"
                  placeholder="ЧЧ:ММ-ЧЧ:ММ"
                />
              </td>
            </tr>
          </table>
        </div>
      </div>
      <hr class="line" />
      <div class="template-builder-wrapper">
        <p>
          <strong>Шаблон на графика</strong>
          <strong class="reset-toggle" @click="resetPattern" v-if="!started"
            >Изтрий шаблона и почни отново</strong
          >
        </p>
        <div class="template-builder">
          <div
            class="circle"
            :class="`${
              (!scheduleGroup.schedules.length && sp) ||
              !scheduleGroup.totalWorkingDays
                ? 'circle--clickable'
                : ''
            } circle--${sp}`"
            v-for="(sp, i) in scheduleGroup.pattern"
            :key="`sp-${i}`"
            @click="nextPatternShift(i)"
          >
            {{ sp ? scheduleGroup.shifts[sp - 1].name : "П" }}
          </div>
          <span
            class="circle circle--toggle"
            @click="addPatternShift()"
            v-if="!scheduleGroup.schedules.length"
            >+</span
          >
        </div>
      </div>
      <template v-if="scheduleGroup.pattern.length">
        <hr class="line" />
        <div class="new-shift" @click="addShift" v-if="!started">
          <span class="circle circle--solid circle--1">+</span>
          ГРАФИК
        </div>
      </template>
      <div class="schedules-list">
        <div
          v-for="(s, i) in scheduleGroup.schedules"
          class="shift"
          :class="{ 'shift--row': started }"
          :key="`shift-${i}`"
        >
          <div class="shift-controlls">
            <input
              placeholder="Име график"
              v-model.trim="s.name"
              :disabled="started"
            />
            <template v-if="!started">
              <div class="move-toggles">
                <span class="move-toggle" @click="unshiftPattern(i)">
                  <span class="icon">l</span>
                </span>
                <strong>Местене на позициите</strong>
                <span class="move-toggle" @click="shiftPattern(i)">
                  <span class="icon">r</span>
                </span>
              </div>
              <strong class="reset-toggle" @click="removeScheduleGroup(i)"
                >Изтрий графика</strong
              >
            </template>
          </div>
          <div class="template-builder">
            <div
              class="circle"
              :class="`circle--${sp}`"
              v-for="(sp, i) in s.pattern"
              :key="`shift-sp-${i}`"
            >
              {{ sp ? scheduleGroup.shifts[sp - 1].name : "П" }}
            </div>
          </div>
        </div>
      </div>
      <div class="vacation-templates__buttons">
        <Input
          class="input-wrapper--big"
          label="Прилежаща Форма 76"
          placeholder="Прилежаща Форма 76"
          :up="true"
          v-model="scheduleGroup.f76"
          :list="f76s"
          prop="name"
          @focus="getF76Names"
        />
        <div class="buttons">
          <transition name="fade">
            <Loading :success="success" v-if="loading" />
          </transition>
          <Button @click="cancel">Откажи</Button>
          <Button class="button--blue" @click="save" :disabled="loading"
            >Запиши</Button
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Input from "@/components/inputs/Input";
import Button from "@/components/Button";
import Loading from "@/components/Loading";
import Checkbox from "@/components/inputs/Checkbox";
export default {
  components: {
    Input,
    Button,
    Loading,
    Checkbox,
  },
  watch: {
    $route: {
      handler: "getScheduleGroup",
      immediate: true,
    },
  },
  computed: {
    started() {
      const now = new Date();
      let st = false;
      if (new Date(this.scheduleGroup.dateFrom) < now) {
        st = true;
      }
      return st && this.scheduleGroup._id;
    },
  },
  data() {
    return {
      f76s: [],
      loading: false,
      success: undefined,
      scheduleGroups: [],
      scheduleGroup: {
        name: "",
        basedOn: null,
        totalWorkingDays: 3,
        totalRestDays: 2,
        dateFrom: new Date(),
        dateTo: new Date(),
        totalShifts: 1,
        skipHolidays: false,
        f76: "",
        shifts: [
          {
            name: "1",
            hours: "",
            hoursCount: 8,
            hoursCountNight: 0,
          },
          {
            name: "2",
            hours: "",
            hoursCount: 8,
            hoursCountNight: 0,
          },
          {
            name: "3",
            hours: "",
            hoursCount: 8,
            hoursCountNight: 0,
          },
        ],
        pattern: [],
        schedules: [],
      },
    };
  },
  methods: {
    basedOnUpdated() {
      if (!this.scheduleGroup.basedOn) {
        this.scheduleGroup.totalWorkingDays = 3;
        this.scheduleGroup.totalRestDays = 2;
        this.scheduleGroup.dateFrom = new Date();
        this.scheduleGroup.dateTo = new Date();
        this.scheduleGroup.totalShifts = 1;
        this.scheduleGroup.skipHolidays = false;
        this.scheduleGroup.f76 = "";
        this.scheduleGroup.shifts = [
          {
            name: "1",
            hours: "",
            hoursCount: 8,
            hoursCountNight: 0,
          },
          {
            name: "2",
            hours: "",
            hoursCount: 8,
            hoursCountNight: 0,
          },
          {
            name: "3",
            hours: "",
            hoursCount: 8,
            hoursCountNight: 0,
          },
        ];
        this.scheduleGroup.pattern = [];
        this.scheduleGroup.schedules = [];
      }
    },
    baseScheduleOnSelected() {
      this.scheduleGroup.totalWorkingDays =
        this.scheduleGroup.basedOn.totalWorkingDays;
      this.scheduleGroup.totalRestDays =
        this.scheduleGroup.basedOn.totalRestDays;
      this.scheduleGroup.dateFrom = this.scheduleGroup.basedOn.dateFrom;
      this.scheduleGroup.dateTo = this.scheduleGroup.basedOn.dateTo;
      this.scheduleGroup.totalShifts = this.scheduleGroup.basedOn.totalShifts;
      this.scheduleGroup.skipHolidays = this.scheduleGroup.basedOn.skipHolidays;
      this.scheduleGroup.shifts = this.scheduleGroup.basedOn.shifts;
      this.scheduleGroup.pattern = this.scheduleGroup.basedOn.pattern;
      this.scheduleGroup.schedules = this.scheduleGroup.basedOn.schedules;
      this.scheduleGroup.f76 = this.scheduleGroup.basedOn.f76;
    },
    shiftNameUpdated(i) {
      this.$set(
        this.scheduleGroup.shifts[i],
        "name",
        this.scheduleGroup.shifts[i].name.substring(0, 2)
      );
    },
    addPatternShift() {
      const s = this.scheduleGroup;
      const x = s.pattern.length - s.totalWorkingDays;

      if (x < 0 || !s.totalWorkingDays) return s.pattern.push(1);

      for (let i = s.pattern.length - 1; i >= x; i--) {
        if (!s.pattern[i]) return s.pattern.push(1);
      }

      for (let i = 0; i < this.scheduleGroup.totalRestDays; i++) {
        s.pattern.push(null);
      }
    },
    nextPatternShift(i) {
      const sp = this.scheduleGroup.pattern;

      if (!this.scheduleGroup.schedules.length) {
        if (sp[i]) {
          if (sp[i] < this.scheduleGroup.totalShifts) {
            this.$set(sp, i, sp[i] + 1);
          } else {
            if (!this.scheduleGroup.totalWorkingDays) {
              this.$set(sp, i, null);
            } else {
              this.$set(sp, i, 1);
            }
          }
        } else if (!this.scheduleGroup.totalWorkingDays) {
          this.$set(sp, i, 1);
        }
      }
    },
    resetPattern() {
      this.scheduleGroup.schedules = [];
      this.scheduleGroup.pattern = [];
    },
    addShift() {
      if (this.scheduleGroup.pattern.length) {
        const s = {
          name: "",
          pattern: Object.assign([], this.scheduleGroup.pattern),
        };
        this.scheduleGroup.schedules.push(s);
      }
    },
    unshiftPattern(i) {
      const x = this.scheduleGroup.schedules[i].pattern.shift();
      this.scheduleGroup.schedules[i].pattern.push(x);
    },
    shiftPattern(i) {
      const x = this.scheduleGroup.schedules[i].pattern.pop();
      this.scheduleGroup.schedules[i].pattern.unshift(x);
    },
    removeScheduleGroup(i) {
      this.scheduleGroup.schedules.splice(i, 1);
    },
    cancel() {
      this.$router.push("/settings/schedule-groups");
    },
    isValid() {
      if (!this.scheduleGroup.name) {
        this.$snotify.error("Името на графика е задължително");
        return false;
      }
      // if (!this.scheduleGroup.totalWorkingDays) {
      //   this.$snotify.error('Полето за работни дни е задължително');
      //   return false;
      // }
      // if (!this.scheduleGroup.totalRestDays) {
      //   this.$snotify.error('Полето за почивни дни е задължително');
      //   return false;
      // }
      if (this.scheduleGroup.dateFrom > this.scheduleGroup.dateTo) {
        this.$snotify.error("Началната дата трябва да е по-ранна от крайната");
        return false;
      }
      if (!this.scheduleGroup.totalShifts) {
        this.$snotify.error("Полето за брой смени е задължително");
        return false;
      }
      if (!this.scheduleGroup.pattern.length) {
        this.$snotify.error("Шаблонът не може да бъде празен");
        return false;
      }
      if (!this.scheduleGroup.schedules.length) {
        this.$snotify.error("Трябва да създадете поне един график");
        return false;
      } else {
        const names = [];
        for (let s of this.scheduleGroup.schedules) {
          if (!s.name) {
            this.$snotify.error(
              "Всички добавени графици трябва да имат попълнено 'Име график'"
            );
            return false;
          } else {
            if (names.indexOf(s.name) === -1) {
              names.push(s.name);
            } else {
              this.$snotify.error(
                "Не може да има повтарящи се имена в графиците"
              );
              return false;
            }
          }
        }
      }

      return true;
    },
    save() {
      if (this.isValid()) {
        this.loading = true;

        this.$apiService
          .post("/schedule-groups", this.scheduleGroup)
          .then(() => {
            this.success = true;
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.message) {
              this.$snotify.error(err.response.data.message);
            }
            this.success = false;
          })
          .then(() => {
            setTimeout(() => {
              if (this.success) {
                this.$snotify.success("Груповият график е запазен успешно");
                this.cancel();
              } else {
                this.loading = false;
                this.success = undefined;
              }
            }, 1000);
          });
      }
    },
    getScheduleGroup() {
      if (this.$route.params.id) {
        this.$apiService
          .get(`/schedule-groups/${this.$route.params.id}`)
          .then((res) => {
            this.scheduleGroup = res.data;
          });
      }
    },
    getScheduleGroups(search) {
      this.scheduleGroups = [];
      let url = "/schedule-groups";

      if (search) {
        url += `?search=${search}`;
      }

      this.$apiService.get(url).then((res) => {
        this.scheduleGroups = res.data;
      });
    },
    getF76Names() {
      this.f76s = [];
      this.$apiService.get("/f76-names").then((res) => {
        this.f76s = res.data;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.tiny__content {
  width: 865px;
  > p + .input-wrapper {
    margin-top: $px16;
  }
  > .input-wrapper {
    margin-bottom: $px16 / 2;
    width: 100%;
  }
}

.row {
  align-items: center;
  display: flex;
  margin-bottom: $px16 / 2;
  &.row--separated {
    align-items: flex-start;
    justify-content: space-between;
    table {
      tr {
        &:first-child {
          td {
            white-space: nowrap;
            padding-bottom: 0.5rem;
          }
        }
        td {
          &:nth-child(2) {
            .input-wrapper--small {
              width: 125px;
            }
          }
          &:nth-child(3) {
            .input-wrapper--small {
              width: 100px;
            }
          }
          &:last-child {
            .input-wrapper--small {
              width: 150px;
            }
          }
        }
      }
    }
  }
  & > .input-wrapper {
    margin-left: 5px;
    &:first-child {
      margin-left: 0;
      &:not(.input-wrapper--small) {
        width: 40%;
      }
    }
  }
}

.shifts-and-skip-holidays {
  align-items: flex-end;
  display: flex;
}

.shift-inputs {
  align-items: center;
  display: flex;
  .shift-input {
    align-items: center;
    display: flex;
    .input-wrapper {
      width: 110px;
    }

    &:not(:last-child) {
      margin-right: $px16;
    }
  }
}

.template-builder-wrapper {
  > p {
    display: flex;
    justify-content: space-between;
    margin: 1rem 0 0.5rem;
  }
}
.reset-toggle {
  color: #ff0000;
  cursor: pointer;
  text-decoration: underline;
  @include hover();
}
.template-builder {
  background-color: #e2e9ee;
  border-radius: 4px;
  padding: 10px;
  text-align: center;
  .circle {
    margin: 2.5px;
    // &:not(.circle--toggle) {
    //   // background-color: ;
    // }
  }
}
.template-builder-wrapper + .line {
  margin: $px18 0;
}

.new-shift {
  background-color: #e2e9ee;
  border-radius: 4px;
  color: #000;
  font-size: $px11;
  margin-bottom: 10px;
  padding: 5px;
  text-align: center;
  @include noselect();
  @include hover();
  .circle {
    font-size: $px16;
    height: 20px;
    width: 20px;
  }
}

.shift {
  background-color: #e2e9ee;
  border-radius: 4px;
  margin-bottom: 10px;
  &.shift--row {
    display: flex;
    justify-content: space-between;
  }
}

.shift-controlls {
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 5px;
  input {
    background-color: #fff;
    border: 1px solid #d3d3d3;
    border-radius: 4px;
    height: 36px;
    margin-right: 10px;
    outline-color: #505050;
    padding: 0 10px;
    width: 100px;

    &::placeholder {
      color: #505050;
    }
  }
  .move-toggles {
    align-items: center;
    display: flex;
    strong {
      margin: 0 9px;
    }
    .move-toggle {
      color: #005a9e;
      font-size: 24px;
      height: 24px;
      width: 24px;
      @include hover();
      @include noselect();
    }
  }
}

.vacation-templates__buttons {
  align-items: flex-end;
  display: flex;
  justify-content: space-between;
  margin-top: 1.3rem;

  .buttons {
    align-items: center;
    display: flex;
  }

  .button:not(:last-child) {
    margin-right: 10px;
  }
}
</style>
