<template>
  <div class="permission-switch-wrapper no-print">
    <div
      class="permission-switch"
      :class="{
        'permission-switch--edit': edit,
        'permission-switch--false': !value,
        'permission-switch--disabled': disabled,
      }"
    >
      <span
        :class="{ active: value }"
        @click="disabled ? null : $emit('input', true)"
        >ДА</span
      >
      <span
        :class="{ active: !value }"
        @click="disabled ? null : $emit('input', false)"
      >
        <template v-if="edit">РЕДАКЦИЯ</template>
        <template v-else>НЕ</template>
      </span>
    </div>
    <template v-if="!stateless">
      <transition name="fade" mode="out-in">
        <p v-if="value" key="I do allow">{{ yes || "Разрешавам отпуска." }}</p>
        <p v-else key="I do not allow">{{ no || "Не разрешавам отпуска." }}</p>
      </transition>
    </template>
  </div>
</template>

<script>
export default {
  props: ["value", "disabled", "yes", "no", "stateless", "edit"],
};
</script>
<style lang="scss" scoped>
@import "@/scss/base.scss";
.permission-switch-wrapper {
  p {
    font-size: $px14;
    margin-top: 0.5rem;
  }
}
.permission-switch {
  background-color: #fff;
  border: 1px solid #d8d2bd;
  display: flex;
  border-radius: 2px;
  height: 36px;
  padding: 3px;
  position: relative;
  width: 126px;
  @include transition($transition);
  &::before {
    content: "";
    background-color: $permissionGreen;
    height: calc(100% - 6px);
    left: 3px;
    position: absolute;
    top: 3px;
    width: calc(50% - 3px);
    @include transition(inherit);
  }
  &.permission-switch--edit {
    width: 168px;
  }
  &.permission-switch--false {
    &::before {
      background-color: $darkOrange;
      left: 50%;
    }
  }
  > span {
    align-items: center;
    border-radius: 2px;
    color: #000;
    display: inline-flex;
    font-size: $px12;
    font-weight: 700;
    height: 100%;
    justify-content: center;
    position: relative;
    width: 50%;
    z-index: 1;
    @include noselect();
    @include transition(inherit);
    &.active {
      color: #fff;
    }
  }

  &:not(.permission-switch--disabled) {
    > span {
      cursor: pointer;
      &:not(.active):hover {
        background-color: rgba($color: #d8d2bd, $alpha: 0.5);
      }
    }
    &:hover {
      border: 1px solid $permissionGreen;
    }
    &.permission-switch--false:hover {
      border: 1px solid $darkOrange;
    }
  }
}
</style>
