<template>
  <div class="vacation" v-if="currentUser._id && vacation.template._id" ref="vacation">
    <p v-if="vacation.stage === 'annulled'" class="annulled-p">ОТПУСКЪТ Е АНУЛИРАН</p>
    <div class="vacation__content">
      <header class="header--reversed">
        <p>
          {{ vacation.template.application1 }}<br />
          {{ vacation.template.application2 }}
        </p>
      </header>
      <p class="vacation__content__title">
        {{ vacation.template.application3 }}
      </p>
      <div class="row">
        <Input
          :label="vacation.template.application4"
          :placeholder="vacation.template.application5"
          v-model="vacation.user"
          prop="name"
          :disabled="
            vacation._id || (!currentUser.roles.admin && !currentUser.roles.hr && !currentUser.roles.supervisor)
          "
          :list="employees"
          @item-selected="employeeSelected"
          @focus="getEmployees"
          @search="getEmployees"
        />
      </div>
      <div class="row row--mobile-wrap row--mobile-space-between" v-if="vacation.user">
        <Input
          class="input-wrapper--1 input-wrapper--mobile-40"
          :label="vacation.template.application6"
          :placeholder="vacation.template.application6"
          :value="vacation.user ? vacation.user.number : null"
          disabled="true"
        />
        <Input
          class="input-wrapper--2 input-wrapper--mobile-order-3"
          :label="vacation.template.application7"
          :placeholder="vacation.template.application7"
          :value="vacation.user.position ? vacation.user.position.name : null"
          disabled="true"
        />
        <Input
          class="input-wrapper--1 input-wrapper--mobile-40 no-print"
          :label="vacation.template.application8"
          :placeholder="vacation.template.application8"
          :value="getRemaining"
          disabled="true"
        />
      </div>
      <p class="vacation__content__subtitle print-add-margin-top">
        <strong>{{ vacation.template.application9 }}</strong>
      </p>
      <p class="vacation__content__subtitle">
        <strong>{{ vacation.template.application10 }}</strong>
      </p>
      <VacationType
        v-model="vacation.type"
        :disabled="vacation._id"
        :template="vacation.template"
        v-if="$route.name === 'newVacation' || vacation._id"
      />
      <div class="row row--mobile-equal-inputs">
        <Input
          class="input-wrapper--1"
          :label="vacation.template.application11"
          :placeholder="vacation.template.application11"
          :datepicker="true"
          :disabled="vacation._id && !allowDatesEdit"
          :disabledDates="disabledDatesFrom"
          v-model="vacation.dateFrom"
          @date-selected="firstDateSelected()"
        />
        <Input
          class="input-wrapper--1"
          :label="vacation.template.application12"
          :placeholder="vacation.template.application12"
          :datepicker="true"
          :disabled="vacation._id && !allowDatesEdit"
          :disabledDates="disabledDatesTo"
          v-model="vacation.dateTo"
          @date-selected="getDays"
        />
        <div class="space--25"></div>
        <Input
          class="input-wrapper--1"
          :label="vacation.template.application13"
          :placeholder="vacation.template.application13"
          :value="
            vacation.type === 'paid-father' || vacation.type === 'paid-schedule'
              ? vacation.totalDays.length
              : vacation.workingDays.length
          "
          disabled="true"
        />
      </div>
      <div class="row no-print">
        <Input
          :label="vacation.template.application14"
          :placeholder="vacation.template.application15"
          type="file"
          :disabled="vacation._id"
          :files="files"
          @files-selected="files = $event"
        />
      </div>
      <div class="row row--date-and-text">
        <Input
          class="input-wrapper--1"
          :label="vacation.template.application16"
          :placeholder="vacation.template.application16"
          v-model="vacation.dateOfSigning"
          :disabled="vacation._id"
          :disabledDates="disabledDates"
          :datepicker="true"
        />
        <div class="texts--75">
          <p>{{ vacation.template.application17 }}</p>
          <p>{{ vacation.template.application18 }}</p>
        </div>
      </div>
      <div class="row row--space-between row--mobile-signatures">
        <Signature
          :label="vacation.template.application19"
          :placeholder="vacation.template.application20"
          :name="vacation.user ? vacation.user.name : ''"
          :signature="vacation.signatures.user"
          @signed="vacation.signatures.user = $event"
          :disabled="(vacation._id && vacation.signatures.user) || disabledUserSignature"
        />
        <Signature
          :label="vacation.template.application21"
          :placeholder="vacation.template.application22"
          :name="vacation.supervisorFirst ? vacation.supervisorFirst.name : ''"
          :signature="vacation.signatures.supervisorFirst"
          @signed="vacation.signatures.supervisorFirst = $event"
          :disabled="(vacation._id && vacation.stage !== 'application') || disabledSupervisorFirstSignature"
        />
      </div>
    </div>
    <template v-if="vacation._id">
      <div class="vacation__content vacation__content--order" v-if="vacation.stage !== 'application'">
        <header>
          <p>
            {{ vacation.template.order1 }}
            <br />
            {{ vacation.template.order2 }}
          </p>
        </header>
        <div class="row row--space-between row--print-center row--mobile-column-center">
          <PermissionSwitch
            v-model="vacation.allowed"
            :yes="vacation.template.order3"
            :no="vacation.template.order4"
            :disabled="vacation.stage !== 'confirmedByFirstSupervisor' || disabledSupervisorSecondSignature"
          />
          <div class="row row--mobile-wrap">
            <p class="vacation__content__title">
              {{ vacation.template.order5 }}
            </p>
            <div class="row">
              <Input class="input-wrapper--100" placeholder="№" disabled="true" v-model="vacation.number" />
              <p class="vacation__content__title vacation__content__title--single">/</p>
              <Input
                class="input-wrapper--100"
                placeholder="Дата"
                :datepicker="true"
                :disabled="vacation._id"
                :disabledDates="disabledDates"
                v-model="vacation.dateOfSigning"
              />
            </div>
          </div>
        </div>
        <p class="vacation__content__subtitle">
          {{ vacation.template.order6 }}
        </p>
        <!-- PAID -->
        <div class="row row--print-margin">
          <p>
            <strong v-if="vacation.allowed">{{ vacation.template.order7 }}</strong>
            <strong v-else>{{ vacation.template.order8 }}</strong>
            <template v-if="vacation.user">
              {{ vacation.user.name }}, {{ vacation.template.order9 }} {{ vacation.user.number }},
              {{ vacation.template.order10 }} {{ vacation.user.position ? vacation.user.position.name : '-' }},
            </template>
            <strong v-if="vacation.type === 'paid' || vacation.type === 'paid-schedule'">
              {{ vacation.template.nameAndArticle1.toLowerCase() }} по
              {{ vacation.template.nameAndArticle2 }}
            </strong>
            <!-- UNPAID -->
            <strong v-if="vacation.type === 'unpaid'">
              {{ vacation.template.nameAndArticle23.toLowerCase() }} по
              {{ vacation.template.nameAndArticle24 }}
            </strong>
            <!-- PAID OFFICIAL -->
            <strong v-if="vacation.type === 'paid-official'">
              {{ vacation.template.nameAndArticle11.toLowerCase() }} по
              {{ vacation.template.nameAndArticle12 }}
            </strong>
            <!-- PAID STUDENT -->
            <strong v-if="vacation.type === 'paid-student'">
              {{ vacation.template.nameAndArticle17.toLowerCase() }} по
              {{ vacation.template.nameAndArticle18 }}
            </strong>
            <strong v-if="vacation.type === 'paid-graduation'">
              {{ vacation.template.nameAndArticle19.toLowerCase() }} по
              {{ vacation.template.nameAndArticle20 }}
            </strong>
            <!-- UNPAID STUDENT -->
            <strong v-if="vacation.type === 'unpaid-student'">
              {{ vacation.template.nameAndArticle21.toLowerCase() }} по
              {{ vacation.template.nameAndArticle22 }}
            </strong>
            <!-- PAID FATHER -->
            <strong v-if="vacation.type === 'paid-father'">
              {{ vacation.template.nameAndArticle13.toLowerCase() }} по
              {{ vacation.template.nameAndArticle14 }}
            </strong>
            <!-- PAID UNION -->
            <strong v-if="vacation.type === 'paid-union'">
              {{ vacation.template.nameAndArticle27.toLowerCase() }} по
              {{ vacation.template.nameAndArticle28 }}
            </strong>
            <!-- DEATH -->
            <strong v-if="vacation.type === 'death'">
              {{ vacation.template.nameAndArticle5.toLowerCase() }} по
              {{ vacation.template.nameAndArticle6 }}
            </strong>
            <!-- MARRIAGE -->
            <strong v-if="vacation.type === 'marriage'">
              {{ vacation.template.nameAndArticle7.toLowerCase() }} по
              {{ vacation.template.nameAndArticle8 }}
            </strong>
            <!-- BLOOD DONATION -->
            <strong v-if="vacation.type === 'blood-donation'">
              {{ vacation.template.nameAndArticle9.toLowerCase() }} по
              {{ vacation.template.nameAndArticle10 }}
            </strong>
            <!-- DAYS COUNT -->
            {{ vacation.template.order11 }}
            <template v-if="vacation.type !== 'paid-father' && vacation.type !== 'paid-schedule'">
              {{ vacation.workingDays.length }}
              {{ vacation.workingDays.length === 1 ? vacation.template.order12 : vacation.template.order13 }}
            </template>
            <!-- DAYS COUNT FOR FATHERS AND PAID-SCHEDULE -->
            <template v-else>
              {{ vacation.totalDays.length }}
              {{ vacation.totalDays.length === 1 ? vacation.template.order14 : vacation.template.order15 }}
            </template>
            {{ vacation.template.order16 }}
            {{ vacation.dateFrom | formatDate }}г.
            {{ vacation.template.order17 }}
            {{ vacation.dateTo | formatDate }}г.
          </p>
        </div>
        <div class="row row--mobile-column-center">
          <p class="p--mobile-margin">
            {{ vacation.template.order18 }}
            <strong>{{ vacation.template.order19 }}</strong>
            {{ vacation.template.order20 }}
            {{ vacation.firstDayAfterVacation | formatDate }}г.
            {{ vacation.template.order21 }}
          </p>
          <Signature
            :label="vacation.template.order22"
            :placeholder="vacation.template.order23"
            :name="vacation.supervisorSecond ? vacation.supervisorSecond.name : ''"
            :signature="vacation.signatures.supervisorSecond"
            @signed="vacation.signatures.supervisorSecond = $event"
            :disabled="vacation.stage !== 'confirmedByFirstSupervisor' || disabledSupervisorSecondSignature"
          />
        </div>
      </div>
      <div
        class="vacation__content vacation__content--hr"
        v-if="
          vacation.stage !== 'application' &&
          vacation.stage !== 'confirmedByFirstSupervisor' &&
          vacation.type !== 'paid' &&
          vacation.type !== 'paid-schedule' &&
          vacation.type !== 'unpaid' &&
          vacation.company === 'КЦМ АД'
        "
      >
        <p class="vacation__content__subtitle">
          {{ vacation.template.hr1 }}
          <br />
          {{ vacation.template.hr2 }}
        </p>
        <div class="row row--space-between row--mobile-column-center">
          <PermissionSwitch
            v-model="vacation.allowedByHR"
            :yes="vacation.template.hr3"
            :no="vacation.template.hr4"
            :disabled="vacation.stage !== 'confirmedBySecondSupervisor' || !currentUser.roles.hr"
          />
          <Signature
            :label="vacation.template.hr5"
            :placeholder="vacation.template.hr6"
            :signature="vacation.signatures.hr"
            @signed="vacation.signatures.hr = $event"
            :disabled="vacation.stage !== 'confirmedBySecondSupervisor' || !currentUser.roles.hr"
          />
        </div>
      </div>
    </template>
    <footer class="vacation__buttons no-print">
      <div>
        <Button
          class="button--red"
          :disabled="!vacation._id || loading"
          @click="update('annul')"
          v-if="
            vacation.stage !== 'annulled' && (currentUser.roles.admin || currentUser.roles.hr || !vacation.finalizedAt)
          "
          >Анулирай</Button
        >
        <Button
          class="button--dark"
          :disabled="vacation.stage === 'confirmedBySecondSupervisor' && vacation.stage === 'confirmedByHR'"
          @click="print"
          >Печат</Button
        >
      </div>
      <div>
        <transition name="fade">
          <Loading :success="success" v-if="loading" />
        </transition>
        <input
          v-if="!vacation._id && !currentUser.roles.admin && !currentUser.roles.hr && !currentUser.roles.supervisor"
          type="password"
          placeholder="Потвърди парола"
          class="password-confirmation"
          :class="{ invalid: isPasswordInvalid }"
          v-model="vacation.password"
          @keyup="isPasswordInvalid = false"
        />
        <!-- <Button class="button--draft" @click="create(true)"
          >Запиши като чернова</Button
        > -->
        <!-- TODO: merge the buttons into one -->
        <Button class="button--blue" @click="create" :disabled="loading" v-if="!vacation._id">Запиши и изпрати</Button>
        <Button
          class="button--blue"
          @click="update('first')"
          :disabled="loading"
          v-else-if="vacation.stage === 'application'"
          >Запиши</Button
        >
        <Button
          class="button--blue"
          @click="update('second')"
          :disabled="loading"
          v-else-if="vacation.stage === 'confirmedByFirstSupervisor'"
          >Запиши</Button
        >
        <Button
          class="button--blue"
          @click="update('hr')"
          :disabled="loading"
          v-else-if="
            vacation.stage !== 'annulled' &&
            vacation.stage !== 'confirmedByHR' &&
            vacation.type !== 'paid' &&
            vacation.type !== 'paid-schedule' &&
            vacation.type !== 'unpaid' &&
            vacation.allowed &&
            vacation.company === 'КЦМ АД'
          "
          >Запиши</Button
        >
        <Button
          class="button--blue"
          @click="update('dates')"
          v-else-if="
            allowDatesEdit && vacation.stage !== 'annulled' && (currentUser.roles.admin || currentUser.roles.hr)
          "
          >Запиши</Button
        >
        <Button
          class="button--darker-blue"
          @click="allowingDatesEdit"
          v-else-if="
            vacation.finalizedAt && vacation.stage !== 'annulled' && (currentUser.roles.admin || currentUser.roles.hr)
          "
          >Редактирай</Button
        >
      </div>
    </footer>
  </div>
</template>

<script>
import Input from '@/components/inputs/Input';
import PermissionSwitch from '@/components/inputs/PermissionSwitch';
import Signature from '@/components/signature/Signature';
import VacationType from './VacationType';
import Button from '@/components/Button';
import Loading from '@/components/Loading';
import { mapGetters } from 'vuex';
export default {
  components: {
    Input,
    PermissionSwitch,
    Signature,
    VacationType,
    Button,
    Loading,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
    disabledDates() {
      const disabled = {};
      if (!this.currentUser.roles.hr && !this.currentUser.roles.admin) {
        const to = new Date();

        if (to.getDate() <= 2) {
          to.setMonth(to.getMonth() - 1);
          console.log(to);
        }

        to.setDate(0);
        to.setHours(23, 59, 59, 999);

        disabled.to = to;
      }

      return disabled;
    },
    disabledDatesFrom() {
      const disabled = {
        ...this.disabledDates,
      };
      if (this.vacation.dateTo) {
        disabled.from = new Date(this.vacation.dateTo);
      }
      return disabled;
    },
    disabledDatesTo() {
      const disabled = {
        ...this.disabledDates,
      };
      if (this.vacation.dateTo && disabled.to < this.vacation.dateFrom) disabled.to = new Date(this.vacation.dateFrom);
      return disabled;
    },
    // TODO: refactor the next 3 methods somehow
    disabledUserSignature() {
      const u = this.vacation.user ? this.vacation.user._id : null;
      const sf = this.vacation.supervisorFirst ? this.vacation.supervisorFirst._id : null;
      const ss = this.vacation.supervisorSecond ? this.vacation.supervisorSecond._id : null;

      return (
        this.currentUser._id !== u &&
        this.currentUser._id !== sf &&
        this.currentUser._id !== ss &&
        !this.currentUser.roles.admin &&
        !this.currentUser.roles.hr
      );
    },
    disabledSupervisorFirstSignature() {
      const sf = this.vacation.supervisorFirst ? this.vacation.supervisorFirst._id : null;
      const ss = this.vacation.supervisorSecond ? this.vacation.supervisorSecond._id : null;

      return this.currentUser._id !== sf && this.currentUser._id !== ss && !this.currentUser.roles.admin;
    },
    disabledSupervisorSecondSignature() {
      const ss = this.vacation.supervisorSecond ? this.vacation.supervisorSecond._id : null;

      return this.currentUser._id !== ss && !this.currentUser.roles.admin;
    },
    getRemaining() {
      const quota = ['paid', 'paid-schedule', 'paid-union', 'paid-student', 'paid-graduation', 'unpaid'];
      if (quota.indexOf(this.vacation.type) > -1) {
        return this.remaining[this.vacation.type] || 0;
      } else {
        return 'няма квота';
      }
    },
  },
  data() {
    return {
      loading: false,
      success: undefined,
      isPasswordInvalid: false,
      allowDatesEdit: false,
      employees: [],
      files: [],
      remaining: {},
      vacation: {
        user: null,
        type: 'paid',
        signatures: {
          user: null,
          supervisorFirst: null,
          supervisorSecond: null,
          hr: null,
        },
        dateFrom: new Date(),
        dateTo: null,
        dateOfSigning: new Date(),
        totalDays: [],
        workingDays: [],
        firstDayAfterVacation: null,
        allowed: true,
        allowedByHR: true,
        supervisorFirst: null,
        supervisorSecond: null,
        template: {},
        templateByType: {},
      },
    };
  },
  methods: {
    print() {
      window.print();
    },
    firstDateSelected() {
      if (!this.vacation.dateTo) this.vacation.dateTo = this.vacation.dateFrom;
      this.getDays();
    },
    getVacation() {
      this.$apiService
        .get(`/vacations/${this.$route.params.id}`)
        .then((res) => {
          this.vacation = res.data;
          this.getVacationDays();
        })
        .catch((err) => {
          console.log(err);
        });
    },
    // get total days, working days and holidays
    getDays() {
      const dates = {
        dateFrom: this.vacation.dateFrom,
        dateTo: this.vacation.dateTo,
      };

      this.$apiService
        .post('/days', dates)
        .then((res) => {
          this.vacation.totalDays = res.data.total;
          this.vacation.workingDays = res.data.working;
          this.vacation.firstDayAfterVacation = res.data.first;
        })
        .catch((err) => {
          console.log(err);
        });
    },
    getEmployees(search) {
      this.employees = [];
      let url = `/users/employees`;

      if (search) {
        url += `?search=${search}`;
      }

      this.$apiService.get(url).then((res) => {
        this.employees = res.data;
      });
    },
    getVacationDays() {
      this.$apiService.get(`/users/vacation-days/${this.vacation.user._id}`).then((res) => {
        this.remaining = res.data || {};
      });
    },
    employeeSelected() {
      this.vacation.company = this.activeCompany;
      this.vacation.supervisorFirst = this.vacation.user.supervisorFirst;
      this.vacation.supervisorSecond = this.vacation.user.supervisorSecond;

      this.$apiService.get(`/users/${this.vacation.user.supervisorFirst}?name=true`).then((res) => {
        this.vacation.supervisorFirst = res.data;
      });

      this.$apiService.get(`/users/${this.vacation.user.supervisorSecond}?name=true`).then((res) => {
        this.vacation.supervisorSecond = res.data;
      });

      this.getVacationDays();
    },
    isValid(by) {
      if (!this.vacation._id) {
        if (!this.vacation.user) {
          this.$snotify.error('Не сте избрали служител');
          return false;
        }
        if (!this.vacation.dateTo) {
          this.$snotify.error('Не сте избрали крайна дата на отпуска');
          return false;
        }
        if (!this.vacation.signatures.user && !this.currentUser.roles.hr && !this.currentUser.roles.admin) {
          this.$snotify.error('Служителят не се е подписал');
          return false;
        }
        if (
          !this.currentUser.roles.admin &&
          !this.currentUser.roles.hr &&
          !this.currentUser.roles.supervisor &&
          !this.vacation.password
        ) {
          this.isPasswordInvalid = true;
          return false;
        }
        if (
          (this.vacation.type === 'death' ||
            this.vacation.type === 'marriage' ||
            this.vacation.type === 'blood-donation') &&
          this.vacation.workingDays.length > 2
        ) {
          this.$snotify.error('Не може да пуснете повече от 2 дни за избрания тип отпуск');
          return false;
        }
        if (this.vacation.type === 'paid-father' && this.vacation.totalDays.length > 15) {
          this.$snotify.error('Не може да пуснете повече от 15 календарни дни за избрания тип отпуск');
          return false;
        }
        if (
          this.vacation.type !== 'paid' &&
          this.vacation.type !== 'paid-schedule' &&
          this.vacation.type !== 'unpaid' &&
          this.vacation.type !== 'paid-official' &&
          !this.files.length
        ) {
          this.$snotify.error('За избрания тип отпуск е нужно да прикачите поне 1 файл');
          return false;
        }
      } else if (by === 'first') {
        if (!this.vacation.signatures.user) {
          this.$snotify.error('Служителят не се е подписал');
          return false;
        }
        // if (!this.vacation.signatures.supervisorFirst) {
        //   this.$snotify.error('Прекият ръководител не се е подписал');
        //   return false;
        // }
      } else if (by === 'second') {
        if (!this.vacation.signatures.supervisorSecond) {
          this.$snotify.error('Ръководител ЦП/ОП/ТУ/Цех/Отдел не се е подписал');
          return false;
        }
      } else if (by === 'hr') {
        if (!this.vacation.signatures.hr) {
          this.$snotify.error('Ръководител човешки ресурси не се е подписал');
          return false;
        }
      }
      return true;
    },
    create(draft) {
      const dateFrom = new Date(this.vacation.dateFrom);
      const dateTo = new Date(this.vacation.dateTo);
      dateFrom.setHours(12, 0, 0, 0);
      dateTo.setHours(12, 0, 0, 0);

      if (dateFrom > dateTo) {
        this.$snotify.error('Началната дата не може да е по-голяма от крайната');
        return;
      }

      if (this.isValid()) {
        this.loading = true;
        let fd = new FormData();

        const data = Object.assign(this.vacation, {});

        if (draft) {
          data.draft = true;
        }

        fd.append('vacation', JSON.stringify(data));

        for (let f of this.files) {
          fd.append('files', f);
        }

        this.$apiService
          .post('/vacations', fd)
          .then(() => {
            this.success = true;
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.message) {
              this.$snotify.error(err.response.data.message);
            }
            this.success = false;
          })
          .then(() => {
            setTimeout(() => {
              if (this.success) {
                this.$snotify.success('Отпускът е запазен успешно');
                this.$router.push('/vacations');
              } else {
                this.loading = false;
                this.success = undefined;
              }
            }, 1000);
          });
      }
    },
    // based on 'by' differend props are saved in the db
    // you can see which ones in /api/routes/vacations.js
    update(by) {
      if (by === 'annul') {
        if (!confirm('Сигурни ли сте, че искате да анулирате отпуска?')) {
          return;
        }
      }

      if (!this.currentUser.roles.admin && !this.currentUser.roles.hr) {
        if (this.vacation.dateFrom < this.disabledDates.to) {
          this.$snotify.error('Този отпуск не може да бъде редактиран, тъй като е за изтекъл период');
          return;
        }
      }

      if (this.isValid(by)) {
        this.loading = true;
        this.$apiService
          .put(`/vacations/${by}`, this.vacation)
          .then(() => {
            this.success = true;
          })
          .catch((err) => {
            console.log(err);
            if (err.response.data.message) {
              this.$snotify.error(err.response.data.message);
            }
            this.success = false;
          })
          .then(() => {
            setTimeout(() => {
              if (this.success) {
                this.$snotify.success('Отпускът е запазен успешно');
                this.$router.push('/vacations');
              } else {
                this.loading = false;
                this.success = undefined;
              }
            }, 1000);
          });
      }
    },
    getTemplates() {
      this.$apiService.get(`/templates/common`).then((res) => {
        this.vacation.template = res.data || {};
      });
    },
    allowingDatesEdit() {
      this.allowDatesEdit = true;

      this.$refs.vacation.scrollIntoView({ behavior: 'smooth' });
    },
  },
  mounted() {
    if (this.$route.name === 'newVacation') {
      if (!this.currentUser.roles.admin && !this.currentUser.roles.hr && !this.currentUser.roles.supervisor) {
        this.vacation.user = this.currentUser;
        this.employeeSelected();
      }
      this.getDays();
      this.getTemplates();
    } else if (this.$route.name === 'editVacation') {
      this.getVacation();
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
$contentWidth: 890px;
.vacation {
  align-items: center;
  color: #373737;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 1rem;
  width: 100%;
}
.annulled-p {
  background-color: #d41717;
  border-radius: 2px;
  color: #fff;
  font-size: $px16;
  font-weight: 700;
  line-height: $px16;
  margin-bottom: 1rem;
  padding: 4px 5px 2px;
  text-align: center;
  width: $contentWidth;
}
.vacation__content {
  background-color: #f3f3f3;
  border-radius: 2px;
  border: 1px solid #d0d0d0;
  padding: 32px 105px;
  width: $contentWidth;
  header {
    display: flex;
    font-size: $px14;
    margin-bottom: 32px;
    width: 100%;

    &.header--reversed {
      justify-content: flex-end;
    }
  }
}
.vacation__content__title {
  font-size: $px16;
  font-weight: 700;
  letter-spacing: 0.369231rem;
  margin-bottom: 7px;
  text-align: center;
  &.vacation__content__title--single {
    letter-spacing: initial;
  }
}
.vacation__content__subtitle {
  font-size: $px14;
  text-align: center;
}

.row {
  align-items: center;
  display: flex;
  &:not(:last-child) {
    margin-bottom: 1rem;
  }
  &.row--date-and-text {
    margin: 3rem 0;
  }
  &.row--space-between {
    justify-content: space-between;
  }
  .input-wrapper {
    width: 100%;
    &:not(:first-child) {
      margin-left: 1rem;
    }
    &.input-wrapper--100 {
      width: 100px;
    }
    &.input-wrapper--1 {
      width: 25%;
    }
    &.input-wrapper--2 {
      width: 50%;
    }
    &.input-wrapper--3 {
      width: 75%;
    }
  }
  .space--25 {
    width: 25%;
  }
  .texts--75 {
    font-size: $px14;
    margin-left: 1rem;
    padding-left: 10%;
    width: 75%;

    p {
      margin: 0.5rem 0;
    }
  }
  .vacation__content__title {
    margin: 0;
    &:not(:first-child) {
      margin-left: 0.5rem;
    }
    & + .input-wrapper {
      margin-left: 0.5rem;
    }
  }
}

.vacation__buttons {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 20px 0;
  width: $contentWidth;
  > div {
    align-items: center;
    display: flex;
    .button:not(:last-child) {
      margin-right: 5px;
    }
  }
}

.vacation__content--order {
  margin-top: 20px;
  .vacation__content__subtitle {
    margin: 3rem 0 1rem;
  }
}
.vacation__content--hr {
  margin-top: 20px;
  .vacation__content__subtitle {
    margin: 0 0 3rem;
  }
}

.password-confirmation {
  border-radius: 2px;
  border: 1px solid #ababab;
  height: 30px;
  margin-right: 5px;
  outline: none;
  padding: 0 5px;
  text-align: center;
  width: 146px;
  @include transition($transition);
  &.invalid {
    box-shadow: 0px 0px 0px 1px $darkRed;
    border: 1px solid $darkRed;
  }
}

@media screen and (max-width: $sm) {
  .vacation {
    background-color: $mainColor;
    padding: 0;
    > div:last-of-type {
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
  }
  .annulled-p {
    margin-bottom: -1rem;
    padding-bottom: 1.25rem;
    width: 100%;
  }
  .vacation__content {
    border: 0;
    border-radius: 10px;
    padding: 9px 9px 20px;
    width: 100%;
  }
  .vacation__content--order,
  .vacation__content--hr {
    margin-top: 9px;
  }
  .vacation__buttons {
    background-color: #f3f3f3;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
    // justify-content: center;
    margin: -1px 0 9px;
    padding: 20px 9px 25px;
    width: 100%;
    > div {
      .button {
        width: initial;
      }
      &:not(:first-child) {
        margin-left: 5px;
      }
    }
  }
  .row {
    &.row--mobile-wrap {
      flex-wrap: wrap;
    }
    &.row--mobile-space-between {
      justify-content: space-between;
    }
    &.row--mobile-equal-inputs {
      align-items: stretch;
      .input-wrapper {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        width: 100%;
      }
    }
    .input-wrapper {
      &.input-wrapper--mobile-40 {
        width: 45%;
      }
      &.input-wrapper--mobile-order-3 {
        margin-left: 0;
        margin-top: 1rem;
        order: 3;
        width: 100%;
      }
    }
    .space--25 {
      display: none;
    }
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .row {
    &.row--date-and-text {
      margin: 1.5rem 0;
      .input-wrapper {
        width: 80%;
      }
    }
    &.row--mobile-signatures {
      flex-direction: column;
      .signature-wrapper {
        &:not(:last-child) {
          margin-bottom: 9px;
        }
      }
    }
    &.row--mobile-column-center {
      align-items: center;
      flex-direction: column;
      justify-content: center;
      .row {
        justify-content: center;
      }
      .permission-switch-wrapper {
        margin-bottom: 2rem;
      }
    }
    .texts--75 {
      padding: 0;
      width: 100%;
    }
  }
  .p--mobile-margin {
    margin: 1rem 0;
  }
}

@media screen and (max-width: $xs) {
  .vacation__buttons {
    flex-direction: column;
    > div {
      .button {
        width: initial;
      }
      &:not(:first-child) {
        margin-top: 5px;
        position: relative;
        .loading {
          margin-right: 5px;
          position: absolute;
          right: 100%;
          top: 50%;
          @include transform(translateY(-50%));
        }
      }
    }
  }
}

@media print {
  .vacation {
    padding: 0;
  }
  .annulled-p {
    width: 100%;
    border-radius: 0;
  }
  .vacation__content {
    background-color: initial;
    border: initial;
    border-radius: initial;
    padding: 1rem 0;
    width: 100%;
  }
  .row {
    &.row--print-center {
      justify-content: center;
    }
    &.row--print-margin {
      margin: 2rem 0;
    }
    .input-wrapper {
      &.input-wrapper--1 {
        width: 100%;
      }
      &.input-wrapper--2 {
        width: 100%;
      }
      &.input-wrapper--3 {
        width: 100%;
      }
      &.input-wrapper--datepicker {
        width: initial;
      }
    }
    .space--25 {
      display: none;
    }
    .texts--75 {
      width: 100%;
    }
  }
  .print-add-margin-top {
    margin-top: 3rem;
  }
}
</style>
