<template>
  <div class="modal-wrapper" @click.self="close">
    <transition name="modal" appear>
      <div class="modal">
        <header>
          <div>
            <img class="icon" src="@/assets/img/reports/reports.svg" alt="" />

            <p>ОТЧЕТ ЗА СВЪРШЕНА РАБОТА НА {{ reportDate | formatDate }}</p>
          </div>

          <img class="icon close" @click="close" src="@/assets/img/reports/close.svg" alt="" />
        </header>

        <main>
          <section class="counter-wrapper">
            <div>
              <p>Изберете броя задачи по които сте работили днес</p>
              <span>Отчитането на работата за деня се извършва на брой свършени задачи и времето за всяка от тях</span>
            </div>

            <div class="counter" :class="{ disabled: disabledReportFields }">
              <img class="icon" @click="remove" src="@/assets/img/reports/minus.svg" alt="" />
              <p>{{ report.tasks.length }}</p>
              <img class="icon" @click="add" src="@/assets/img/reports/plus.svg" alt="" />
            </div>
          </section>

          <section class="tasks-wrapper">
            <section class="task" v-for="(t, i) in report.tasks" :key="`${t}-${i + 1}`">
              <span
                class="delete-task icon"
                v-if="report._id && !report.draft && (currentUser.roles.admin || currentUser.roles.hr)"
                @click="removeTask(i)"
                >{{ '{' }}</span
              >
              <Input
                class="input-description"
                placeholder="Описание на задачата"
                :label="`Задача ${i + 1}`"
                :maxlength="1000"
                :disabled="disabledReportFields"
                :invalid="invalid.description === i"
                @input="invalid.description = false"
                :img="`reports/hours/${i + 1 > 24 ? '25' : i + 1}.svg`"
                v-model="t.description"
              />
              <Input
                class="input-category"
                type="dropdown"
                placeholder="Категория"
                label="Категория на задачата"
                img="reports/category.svg"
                prop="name"
                :identifier="t.category ? '_id' : false"
                :list="categories"
                :disabled="disabledReportFields"
                :invalid="invalid.category === i"
                @input="invalid.category = false"
                @fetch="gethOCategories($event)"
                v-model="t.category"
              />
              <Input
                class="input-time"
                placeholder="Време"
                label="Изработено време"
                type="dropdown"
                img="reports/time.svg"
                timepicker="true"
                :disabled="disabledReportFields"
                :invalid="invalid.time === i"
                @input="invalid.time = false"
                v-model="t.time"
              />
            </section>
          </section>
        </main>

        <footer>
          <Button
            @click="save(true)"
            class="button--lighter-grey"
            :disabled="report.draft === false || disabledReportFields"
            >ЗАПИШИ МЕЖДИНЕН ОТЧЕТ</Button
          >
          <Button @click="save" :disabled="disabledReportFields" class="button--lighter-blue"
            >ФИНАЛЕН ЗАПИС И ИЗПРАЩАНЕ НА ОТЧЕТ</Button
          >
        </footer>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '@/components/Button';
import Input from '@/components/inputs/ReportInput';
// import categories from '@/data/report-categories.json';
export default {
  props: ['selectedDay', 'vh'],
  computed: {
    ...mapGetters(['currentUser']),
    reportDate() {
      return this.selectedDay.date;
    },
    disabledReportFields() {
      if (!(this.currentUser.roles.admin || this.currentUser.roles.hr)) {
        if (this.report._id && !this.report.draft) {
          return true;
        }
      }
      return false;
    },
  },
  components: {
    Button,
    Input,
  },
  data() {
    return {
      days: [],
      report: {
        tasks: [{}],
      },
      invalid: {
        description: false,
        category: false,
        time: false,
      },
      categories: [],
    };
  },
  methods: {
    removeTask(index) {
      if (this.report.tasks.length - 1 === 0) return this.$snotify.warning('Не можете да останете без задачи');
      else if (confirm('Сигурни ли сте, че искате да изтриете отчета')) {
        let remove = this.report.tasks.indexOf(this.report.tasks[index]);
        this.report.tasks.splice(remove, 1);
      }
    },
    close() {
      this.$emit('close');
    },
    remove() {
      if (this.report.tasks.length > 1) {
        this.report.tasks.splice(this.report.tasks.length - 1, 1);
      }
    },
    add() {
      this.report.tasks.push({});
    },
    isValid(isDraft) {
      if (!isDraft) {
        for (let i = 0; i < this.report.tasks.length; i++) {
          let task = this.report.tasks[i];

          if (!task.description) {
            this.invalid.description = i;
            this.$snotify.warning('Описанието е задължително');
            return false;
          } else if (!task.category) {
            this.invalid.category = i;
            this.$snotify.warning('Категорията е задължителна');
            return false;
          } else if (!task.time) {
            this.invalid.time = i;
            this.$snotify.warning('Изработеното време е задължително');
            return false;
          }
        }
      }

      return true;
    },
    save(draft) {
      if (this.isValid(draft)) {
        this.report.draft = draft ? true : false;
        this.report.vacationHourly = this.vh._id;

        let url = '/reports';
        let method = this.report._id ? 'put' : 'post';

        if (this.report._id) {
          url += `/${this.report._id}`;
        }

        this.report.createdOn = this.reportDate;

        this.$apiService[method](url, { report: this.report, vh: this.vh })
          .then(() => {
            this.close(true);
          })
          .catch((err) => {
            this.error = err.response.data.message;
          });
      }
    },
    gethOCategories(search) {
      this.categories = [];
      let url = '/home-office-categories';

      if (search) {
        url += `?search=${search}`;
      }

      this.$apiService.get(url).then((res) => {
        this.categories = res.data;
      });
    },
  },
  mounted() {
    this.gethOCategories();
    if (this.selectedDay.report) {
      this.report = this.selectedDay.report;
    }
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.modal-wrapper {
  align-items: center;
  background-color: rgba($color: #000, $alpha: 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  &.modal-wrapper--sidepanel {
    padding-left: $sidepanel;
  }
}

.modal {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 60px 0 rgba($color: #000, $alpha: 0.2);
  color: #373737;
  max-width: 900px;
  overflow: hidden;
  width: 100%;

  > header {
    align-items: center;
    background: #e4e8ec;
    display: flex;
    padding: 1rem 1.5rem;
    justify-content: space-between;
    margin-bottom: 1rem;

    > div {
      align-items: center;
      display: flex;
      gap: 10px;
    }
    p {
      font-size: $px12;
      font-weight: 700;
    }
    .close {
      font-size: 2rem;
      @include hover();
    }
  }
  > main {
    max-height: 350px;
    min-height: 350px;
    height: 100%;
    margin: 0 1.5rem;
    overflow: hidden;
    .counter-wrapper {
      align-items: center;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1rem;

      p {
        font-weight: bold;
      }
      span {
        color: #373737;
      }

      .counter {
        align-items: center;
        display: flex;
        gap: 5px;

        &.disabled {
          opacity: 0.5;
          pointer-events: none;
        }
        img {
          cursor: pointer;
          @include hover();
        }

        p {
          background: #0c518f 0% 0% no-repeat padding-box;
          border-radius: 3px;
          color: white;
          padding: 3px 10px;
        }
      }
    }
    .tasks-wrapper {
      overflow: auto;
      max-height: 300px;
      @include scrollbar(5px, $mainColor);
    }
    .task {
      display: flex;
      flex-wrap: wrap;
      gap: 5px;
      margin: 10px 0;
      position: relative;
      &:not(:last-of-type) {
        border-bottom: 5px solid #f1f1f1;
        padding-bottom: 10px;
      }
      .delete-task {
        color: rgb(183, 24, 24);
        cursor: pointer;
        font-size: 23px;
        position: absolute;
        top: 18px;
        right: 18px;
        z-index: 1;
      }
      .create-document-wrapper {
        &.input-desription {
          width: 100%;
        }
        &.input-category {
          width: calc(80% - 5px);
        }
        &.input-time {
          width: 20%;
        }
      }
    }
  }
  > footer {
    display: flex;
    flex-direction: column;
    .button {
      border-radius: 0;
      font-size: 15px;
      font-weight: bold;
      height: 40px;
      width: 100%;
    }
  }
}
@media screen and (max-width: $s) {
  .modal-wrapper {
    .modal {
      width: 96%;

      > main {
        max-height: initial;
        min-height: initial;

        .tasks-wrapper {
          max-height: 30vh;
        }
        .task {
          flex-direction: column;
          main {
            min-width: 100%;
          }
        }
      }
    }
  }
}
</style>
