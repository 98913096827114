import VacationsHourly from '@/views/vacations-hourly/VacationsHourly.vue'
import VacationHourly from '@/views/vacations-hourly/VacationHourly.vue'

export default [{
    path: '/vacations-hourly',
    name: "vacationsHourly",
    component: VacationsHourly,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/vacations-hourly/new',
    name: "newVacationHourly",
    component: VacationHourly,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: '/vacations-hourly/:id',
    name: "editVacationHourly",
    component: VacationHourly,
    meta: {
      requiresAuth: true,
    },
  },
]