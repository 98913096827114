<template>
  <div class="vacation__content__types" :class="{ 'vacation__content__types--disabled': disabled }">
    <div
      class="vacation__content__type"
      :class="{ active: value === 'paid' }"
      @click="disabled ? null : $emit('input', 'paid')"
    >
      <span class="icon">Y</span>
      <p>{{ template.nameAndArticle1 }}</p>
      <p>{{ template.nameAndArticle2 }}</p>
    </div>
    <div
      class="vacation__content__type"
      :class="{
        active: value === 'death' || value === 'marriage' || value === 'blood-donation',
      }"
      @click="disabled ? null : (selectPurpose = true)"
    >
      <span class="icon">Y</span>
      <template v-if="value === 'death'">
        <p>{{ template.nameAndArticle5 }}</p>
        <p>{{ template.nameAndArticle6 }}</p>
      </template>
      <template v-else-if="value === 'marriage'">
        <p>{{ template.nameAndArticle7 }}</p>
        <p>{{ template.nameAndArticle8 }}</p>
      </template>
      <template v-else-if="value === 'blood-donation'">
        <p>{{ template.nameAndArticle9 }}</p>
        <p>{{ template.nameAndArticle10 }}</p>
      </template>
      <template v-else>
        <p>{{ template.nameAndArticle3 }}</p>
        <p>{{ template.nameAndArticle4 }}</p>
      </template>
    </div>
    <div
      class="vacation__content__type"
      :class="{ active: value === 'paid-official' }"
      @click="disabled ? null : $emit('input', 'paid-official')"
      v-if="activeCompany === 'КЦМ АД'"
    >
      <span class="icon">Y</span>
      <p>{{ template.nameAndArticle11 }}</p>
      <p>{{ template.nameAndArticle12 }}</p>
    </div>
    <div
      class="vacation__content__type"
      :class="{ active: value === 'paid-father' }"
      @click="disabled ? null : $emit('input', 'paid-father')"
    >
      <span class="icon">Y</span>
      <p>{{ template.nameAndArticle13 }}</p>
      <p>{{ template.nameAndArticle14 }}</p>
    </div>
    <div
      class="vacation__content__type"
      :class="{
        active: value === 'paid-student' || value === 'paid-graduation' || value === 'unpaid-student',
      }"
      @click="disabled ? null : (selectStudent = true)"
    >
      <span class="icon">Y</span>
      <template v-if="value === 'paid-graduation'">
        <p>{{ template.nameAndArticle19 }}</p>
        <p>{{ template.nameAndArticle20 }}</p>
      </template>
      <template v-else-if="value === 'unpaid-student'">
        <p>{{ template.nameAndArticle21 }}</p>
        <p>{{ template.nameAndArticle22 }}</p>
      </template>
      <template v-else-if="value === 'paid-student'">
        <p>{{ template.nameAndArticle17 }}</p>
        <p>{{ template.nameAndArticle18 }}</p>
      </template>
      <template v-else>
        <p>{{ template.nameAndArticle15 }}</p>
        <p>{{ template.nameAndArticle16 }}</p>
      </template>
    </div>
    <div
      class="vacation__content__type"
      :class="{ active: value === 'unpaid' }"
      @click="disabled ? null : $emit('input', 'unpaid')"
    >
      <span class="icon">Y</span>
      <p>{{ template.nameAndArticle23 }}</p>
      <p>{{ template.nameAndArticle24 }}</p>
    </div>
    <div
      class="vacation__content__type"
      :class="{
        active: value === 'paid-others' || value === 'paid-union' || value === 'paid-schedule',
      }"
      @click="toggleOthers"
      v-if="activeCompany === 'КЦМ АД'"
    >
      <span class="icon">Y</span>
      <template v-if="value === 'paid-union'">
        <p>{{ template.nameAndArticle27 }}</p>
        <p>{{ template.nameAndArticle28 }}</p>
      </template>
      <template v-else-if="value === 'paid-schedule'">
        <p>{{ template.nameAndArticle29 }}</p>
        <p>{{ template.nameAndArticle30 }}</p>
      </template>
      <template v-else>
        <p>{{ template.nameAndArticle25 }}</p>
        <p>{{ template.nameAndArticle26 }}</p>
      </template>
    </div>

    <!-- STUDENT SUBTYPES -->
    <transition name="fade">
      <div
        class="modal-wrapper"
        :class="{ 'modal-wrapper--sidepanel': isSidePanelOpen }"
        v-if="selectStudent"
        @click.self="selectStudent = false"
      >
        <transition name="modal" appear>
          <div class="modal">
            <header>
              <p>{{ template.nameAndArticle15 }}</p>
              <span class="icon" @click="selectStudent = false">g</span>
            </header>
            <div
              class="modal__type"
              :class="{ active: value === 'paid-student' }"
              @click="$emit('input', 'paid-student'), (selectStudent = false)"
            >
              {{ template.nameAndArticle17 }}
            </div>
            <div
              class="modal__type"
              :class="{ active: value === 'paid-graduation' }"
              @click="$emit('input', 'paid-graduation'), (selectStudent = false)"
            >
              {{ template.nameAndArticle19 }}
            </div>
            <div
              class="modal__type"
              :class="{ active: value === 'unpaid-student' }"
              @click="$emit('input', 'unpaid-student'), (selectStudent = false)"
            >
              {{ template.nameAndArticle21 }}
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <!-- STUDENT SUBTYPES -->
    <transition name="fade">
      <div
        class="modal-wrapper"
        :class="{ 'modal-wrapper--sidepanel': isSidePanelOpen }"
        v-if="selectPurpose"
        @click.self="selectPurpose = false"
      >
        <transition name="modal" appear>
          <div class="modal">
            <header>
              <p>{{ template.nameAndArticle3 }}</p>
              <span class="icon" @click="selectPurpose = false">g</span>
            </header>
            <div
              class="modal__type"
              :class="{ active: value === 'death' }"
              @click="$emit('input', 'death'), (selectPurpose = false)"
            >
              {{ template.nameAndArticle5 }}
            </div>
            <div
              class="modal__type"
              :class="{ active: value === 'marriage' }"
              @click="$emit('input', 'marriage'), (selectPurpose = false)"
            >
              {{ template.nameAndArticle7 }}
            </div>
            <div
              class="modal__type"
              :class="{ active: value === 'blood-donation' }"
              @click="$emit('input', 'blood-donation'), (selectPurpose = false)"
            >
              {{ template.nameAndArticle9 }}
            </div>
          </div>
        </transition>
      </div>
    </transition>
    <!-- STUDENT SUBTYPES -->
    <transition name="fade">
      <div
        class="modal-wrapper"
        :class="{ 'modal-wrapper--sidepanel': isSidePanelOpen }"
        v-if="selectOthers"
        @click.self="selectOthers = false"
      >
        <transition name="modal" appear>
          <div class="modal">
            <header>
              <p>{{ template.nameAndArticle25 }}</p>
              <span class="icon" @click="selectOthers = false">g</span>
            </header>
            <div
              class="modal__type"
              :class="{ active: value === 'paid-union' }"
              @click="$emit('input', 'paid-union'), (selectOthers = false)"
            >
              {{ template.nameAndArticle27 }}
            </div>
            <div
              class="modal__type"
              :class="{ active: value === 'paid-schedule' }"
              @click="$emit('input', 'paid-schedule'), (selectOthers = false)"
            >
              {{ template.nameAndArticle29 }}
            </div>
          </div>
        </transition>
      </div>
    </transition>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
export default {
  computed: {
    ...mapGetters(['currentUser', 'isSidePanelOpen', 'activeCompany']),
  },
  props: ['template', 'value', 'disabled'],
  data() {
    return {
      selectStudent: false,
      selectPurpose: false,
      selectOthers: false,
    };
  },
  methods: {
    toggleOthers() {
      if (!this.disabled) {
        if (!this.currentUser.roles.hr) {
          this.$snotify.warning('Само потребител с роля ЧР може да избира от тип "Други"');
        } else {
          this.selectOthers = true;
        }
      }
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.vacation__content__types {
  display: flex;
  margin: 1rem 0 3rem;
  width: 100%;
}
.vacation__content__type {
  align-items: center;
  background-color: #e0eaf3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 5px 5px;
  width: 100%;
  @include transition($transition);
  .icon {
    color: #32aae5;
    font-size: 1.5rem;
    @include transition($transition);
  }
  p {
    font-size: 0.7rem;
    line-height: 0.8rem;
    text-align: center;
    &:nth-child(2) {
      font-weight: 700;
      margin: 1.8rem 0;
      text-transform: uppercase;
    }
  }
  &:not(:first-child) {
    margin-left: 10px;
  }
  &.active {
    background-color: #32aae5;
    color: #fff;
    .icon {
      color: #fff;
    }
  }
}

.vacation__content__types:not(.vacation__content__types--disabled) {
  .vacation__content__type {
    cursor: pointer;
    &:hover {
      background-color: #c8ddf1;
    }
    &.active {
      &:hover {
        background-color: #1591ce;
      }
    }
  }
}

.modal-wrapper {
  align-items: center;
  background-color: rgba($color: #000, $alpha: 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  &.modal-wrapper--sidepanel {
    padding-left: $sidepanel;
  }
}

.modal {
  background-color: #fff;
  color: #373737;
  border-radius: 10px;
  box-shadow: 0 0 60px 0 rgba($color: #000, $alpha: 0.2);
  padding: 1rem 1.5rem 1.5rem;
  header {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 2.5rem;
    p {
      font-size: $px12;
      font-weight: 700;
    }
    .icon {
      color: #a8a8a8;
      font-size: 2rem;
      @include hover();
    }
  }
}

.modal__type {
  background-color: #e0eaf3;
  border-radius: 10px;
  border: 1px solid #22769f;
  cursor: pointer;
  padding: 1rem 2rem;
  text-align: center;
  text-transform: lowercase;
  @include noselect();
  @include transition($transition);
  &:not(:last-child) {
    margin-bottom: 6px;
  }
  &:hover {
    background-color: #c8ddf1;
  }

  &.active {
    background-color: #22769f;
    color: #fff;
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .vacation__content__types {
    margin: 1rem 0;
    overflow: auto;
    @include scrollbar(5px, $mainColor);
  }
  .vacation__content__type {
    min-width: 100px;
  }
}

@media print {
  .vacation__content__type {
    flex-direction: row;
    justify-content: center;
    &.active {
      background-color: transparent;
      color: #373737;
      p {
        font-size: $px14;
        &:nth-child(2) {
          margin: 0;
          text-transform: lowercase;
          &::after {
            content: ' п';
            font-weight: 400;
          }
        }
        &:last-child {
          &::before {
            content: 'o ';
          }
        }
      }
    }
    .icon,
    &:not(.active) {
      display: none;
    }
  }
}
</style>
