<template>
  <div
    class="datepicker-modal"
    :class="{ 'datepicker-modal--sidepanel': isSidePanelOpen }"
    @click.self="$emit('close')"
  >
    <transition name="modal" appear>
      <div class="modal">
        <Datepicker
          :language="bg"
          :inline="true"
          :monday-first="true"
          :minimumView="month ?  'month' : 'day'"
          :maximumView="month ?  'year' : 'day'"
          :disabled-dates="disabledDates"
          :full-month-name="true"
          :value="value"
          @selected="$emit('selected', $event)"
        />
      </div>
    </transition>
  </div>
</template>

<script>
import Datepicker from "vuejs-datepicker";
import { bg } from "vuejs-datepicker/dist/locale";
import { mapGetters } from "vuex";
export default {
  props: ["value", "disabledDates", 'month'],
  computed: {
    ...mapGetters(["isSidePanelOpen"]),
  },
  components: {
    Datepicker,
  },
  data() {
    return {
      bg,
    };
  },
};
</script>

<style lang="scss">
@import "@/scss/base.scss";
.datepicker-modal {
  align-items: center;
  background-color: rgba($color: #000, $alpha: 0.4);
  height: 100%;
  display: flex;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  @include transition($transition);

  .modal {
    box-shadow: 0 0 60px 0 rgba($color: #000, $alpha: 0.2);
  }

  .vdp-datepicker__calendar {
    border-radius: 4px;
    @include noselect();
    header {
      span {
        @include transition($transition);
      }
      .month__year_btn {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
      }
      .prev,
      .next {
        border-bottom-right-radius: 4px;
        border-top-left-radius: 4px;
        color: transparent;
        text-indent: initial;
        &::after {
          align-items: center;
          border: 0;
          content: "D";
          color: #000;
          display: flex;
          font-size: 0.8rem;
          font-family: "icons";
          height: 100%;
          justify-content: center;
          left: 0;
          margin: 0;
          opacity: 1;
          top: 0;
          width: 100%;
          @include transform(initial);
        }
        &.disabled::after {
          border: 0;
          opacity: 0.1;
        }
      }
      .next {
        border-radius: 0;
        border-bottom-left-radius: 4px;
        border-top-right-radius: 4px;
        &::after {
          content: "V";
        }
      }
      .prev:not(.disabled):hover,
      .next:not(.disabled):hover,
      .up:not(.disabled):hover {
        background-color: rgba($color: $lightAccent, $alpha: 0.2);
      }
    }
    .cell {
      border-radius: 4px;
      height: 36px;
      line-height: 36px;
      margin: 2px;
      position: relative;
      width: calc(14.285714285714286% - 4px);
      @include transition($transition);
      &.month, &.year {
        width: calc(33.33% - 4px);
      }
      &:not(.blank):not(.disabled):not(.selected).day:hover,
      &:not(.blank):not(.disabled):not(.selected).month:hover,
      &:not(.blank):not(.disabled):not(.selected).year:hover {
        background-color: rgba($color: $lightAccent, $alpha: 0.2);
        border: 1px solid transparent;
      }
      &.selected {
        background-color: $lightAccent;
        color: #fff;
      }
    }
  }
}

@media screen and (min-width: $sm) {
  .datepicker-modal {
    &.datepicker-modal--sidepanel {
      padding-left: $sidepanel;
    }
  }
}
</style>
