import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import schedule from './schedule';
import vacations from './vacations';
import vacationsHourly from './vacations-hourly';
import lps from './lps';
import settings from './settings';
import { loginPath } from '../../config-public.json';

Vue.use(VueRouter);

const routes = [
  {
    path: '/',
    name: 'Home',
    redirect: '/vacations'
  },
  {
    path: '/styleguide',
    name: 'styleguide',
    component: () => import('@/views/Styleguide.vue')
  },
  ...schedule,
  ...vacations,
  ...vacationsHourly,
  ...lps,
  ...settings
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
});

router.beforeEach((to, from, next) => {
  store.dispatch('checkImporting');
  store.dispatch('checkAuth').then(() => {
    // inactive or fired
    // if (store.getters.currentUser.inactive || store.getters.currentUser.fired) {
    if (store.getters.currentUser.fired) {
      store.dispatch('logout').then(() => {
        window.open(loginPath);
        // return next({
        //   path: loginPath
        // });
      });
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (store.getters.isAuthenticated) {
        if (store.getters.currentUser.companies.indexOf(store.getters.activeCompany) < 0) {
          Vue.$snotify.warning(`Нямате достъп до страниците от ${store.getters.activeCompany}`);
          return next({ path: '/vacations' });
        }

        // if only specific roles are allowed
        if (to.meta.rolesAllowed) {
          let match = false;
          for (let r of to.meta.rolesAllowed) {
            if (store.getters.currentUser.roles[r]) {
              match = true;
              break;
            }
          }
          if (!match) {
            return next({
              path: from.path || '/'
            });
          }
        }
        if (to.meta.companiesAllowed) {
          if (to.meta.companiesAllowed.indexOf(store.getters.activeCompany) < 0) {
            return next({
              path: from.path || '/'
            });
          }
        }
        // no role required
        return next();
      } else if (!store.getters.isSidePanelOpen) {
        store.dispatch('toggleSidePanel');
      }
      window.location.href = `${loginPath}?exFrom=${window.location.href}`;
    } else {
      next();
    }
  });
});

export default router;
