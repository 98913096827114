<template>
  <div
    class="vacation__content__types"
    :class="{ 'vacation__content__types--disabled': disabled }"
  >
    <template v-for="n in ['0030', '0025', '0020', '0012', '0045', '0016', '0019', '0046']">
      <div
        class="vacation__content__type"
        :class="{ active: value === n, disabled: availableVacations ? !availableVacations.includes(n) : false }"
        @click="disabled || (availableVacations && !availableVacations.includes(n)) ? null : $emit('input', n)"
        :key="`vacation-hourly-type-${n}`"
      >
        <span class="icon">q</span>
        <p>{{ template[`typeName${n}`] }}</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["currentUser", "isSidePanelOpen"]),
  },
  props: ["template", "value", "disabled", "availableVacations"],
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.vacation__content__types {
  display: flex;
  margin: 1rem 0 0;
  width: 100%;
}
.vacation__content__type {
  align-items: center;
  background-color: #e0eaf3;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px 5px 5px;
  min-width: calc(12.5% - 8.75px);
  max-width: calc(12.5% - 8.75px);
  width: 100%;
  @include transition($transition);
  @include noselect();
  .icon {
    color: #32aae5;
    font-size: 1.5rem;
    @include transition($transition);
  }
  p {
    font-size: 0.6rem;
    line-height: 0.7rem;
    text-align: center;
    &:nth-child(2) {
      font-weight: 700;
      margin: 1.8rem 0 1rem;
      text-transform: uppercase;
    }
  }
  &:not(:first-child) {
    margin-left: 10px;
  }
  &.active {
    background-color: #32aae5;
    color: #fff;
    .icon {
      color: #fff;
    }
  }

  &.disabled {
    cursor: default;
  }
}

.vacation__content__types:not(.vacation__content__types--disabled) {
  .vacation__content__type:not(.disabled) {
    cursor: pointer;
    &:hover {
      background-color: #c8ddf1;
    }
    &.active {
      &:hover {
        background-color: #1591ce;
      }
    }
  }
}

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .vacation__content__types {
    margin: 1rem 0;
    overflow: auto;
    @include scrollbar(5px, $mainColor);
  }
  .vacation__content__type {
    min-width: 100px;
  }
}

@media print {
  .vacation__content__type {
    display: none;
  }
}
</style>
