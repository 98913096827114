<template>
  <div class="sidepanel__templates">
    <Button class="button--big button--grey" @click="backToAllTemplates">
      <span class="icon">B</span>
      НАЗАД
    </Button>
    <template v-if="currentUser._id">
      <div class="sidepanel__texts">
        <p
          class="title"
          v-if="$route.name === 'settingsTemplatesVacationHourlyEdit'"
        >
          Редакция на бланките за почасов отпуск
        </p>
        <p class="title" v-else>Редакция на бланките за отпуск</p>
        <p>Промени по темплейта</p>
      </div>
    </template>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import Button from "../Button";
import types from "@/data/vacation-types.json";
export default {
  components: {
    Button,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  data() {
    return {
      types,
    };
  },
  methods: {
    backToAllTemplates() {
      this.$router.push("/settings/templates");
    },
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.sidepanel__templates {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 70px 10px 10px;
}
</style>
