<template>
  <div class="sidepanel__settings">
    <Button class="button--big button--grey" @click="backToAllVacations">
      <span class="icon">B</span>
      НАЗАД КЪМ ОТПУСКИ
    </Button>
    <!-- TODO: ADD LINK TO SCHEDULES -->
    <template v-if="currentUser._id">
      <div class="sidepanel__texts">
        <p class="title">Настройки</p>
        <p>Настройки и справки</p>
      </div>
      <router-link
        class="sidepanel__link"
        to="/settings/holidays"
        v-if="currentUser.roles.admin || currentUser.roles.hr"
        >ОФИЦИАЛНИ ПРАЗНИЦИ И ПОЧИВНИ ДНИ</router-link
      >
      <template v-if="currentUser.roles.admin || currentUser.roles.hr">
        <router-link
          class="sidepanel__link"
          to="/settings/schedule-groups"
          :class="{
            'router-link-exact-active': $route.name === 'settingsScheduleGroups',
          }"
          v-if="activeCompany === 'КЦМ АД'"
          >ГРАФИЦИ И СМЕНИ</router-link
        >
        <router-link class="sidepanel__link" to="/settings/templates">РЕДАКЦИЯ НА БЛАНКИТЕ ЗА ОТПУСК</router-link>
        <router-link class="sidepanel__link" to="/settings/import-export">ИМПОРТ/ЕКСПОРТ</router-link>
        <router-link class="sidepanel__link" to="/settings/f76-names" v-if="activeCompany === 'КЦМ АД'">
          ФОРМА 76 ИМЕНА
        </router-link>
        <router-link class="sidepanel__link" to="/settings/home-office-categories" v-if="activeCompany === 'КЦМ АД'">
          HOME OFFICE КАТЕГОРИИ
        </router-link>
      </template>
    </template>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Button from '../Button';
export default {
  components: {
    Button,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
  },
  methods: {
    backToAllVacations() {
      this.$router.push('/vacations');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.sidepanel__settings {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 70px 10px 10px;
}
</style>
