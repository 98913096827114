<template>
  <div class="input-search">
    <span class="icon">d</span>
    <input
      type="text"
      :placeholder="placeholder"
      :value="value"
      @input="$emit('input', $event.target.value)"
      @keypress.enter="$emit('enter')"
    />
  </div>
</template>

<script>
export default {
  props: ['value', 'placeholder'],
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.input-search {
  position: relative;
  width: 100%;
  .icon {
    color: #fff;
    font-size: 1.5rem;
    left: 1rem;
    position: absolute;
    top: 50%;
    @include transform(translateY(-50%));
  }
  input {
    background-color: transparent;
    border: none;
    border-bottom: 1px solid #fff;
    color: #fff;
    font-size: $px12;
    height: 46px;
    outline: none;
    padding: 0 1rem 0 4rem;
    width: 100%;
    &::placeholder {
      color: #fff;
    }
  }
}
</style>