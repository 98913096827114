<template>
  <div class="schedule">
    <template v-if="currentSchedule < totalSchedules">
      <div class="loading">
        <img src="@/assets/img/loading_schedule.svg" alt="Зареждане..." />
      </div>
      <p class="center percent">
        {{ Math.floor((currentSchedule / totalSchedules) * 100) }}%
      </p>
    </template>
    <main v-else>
      <table>
        <thead>
          <tr>
            <th v-for="(d, i) in dates" :key="`th-${i}`">
              <p>{{ d.fullDate | formatDateMini }}</p>
              <span>{{ d.fullDate | formatDayName }}</span>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="(s, i) in schedules" :key="`td-shift-${i}`">
            <template v-for="(d, j) in dates">
              <td
                :key="`td-${j}`"
                v-if="
                  d.schedules
                    ? d.schedules[i]
                      ? d.schedules[i].scheduleGroup
                        ? d.schedules[i].scheduleGroup._id
                        : false
                      : false
                    : false
                "
              >
                <p class="center" v-if="d.schedules[i].loading">зареждане...</p>
                <template v-else>
                  <header
                    :class="`header--${d.schedules[i].shift}`"
                    v-if="
                      d.schedules[i].scheduleGroup
                        ? d.schedules[i].scheduleGroup._id
                        : false
                    "
                  >
                    <div class="icon-and-shift">
                      <span class="icon">e</span>
                      <span class="user-schedule">{{
                        d.schedules[i]._id
                      }}</span>
                    </div>
                    <span
                      class="circle circle--solid"
                      :class="`circle--${d.schedules[i].shift}`"
                      :key="`shift-sp-${j}`"
                    >
                      {{
                        d.schedules[i].shift
                          ? d.schedules[i].scheduleGroup.shifts[
                              d.schedules[i].shift - 1
                            ].name
                          : "П"
                      }}
                    </span>
                  </header>
                  <main
                    v-if="
                      d.schedules[i].users ? d.schedules[i].users.length : false
                    "
                  >
                    <p
                      class="user-item"
                      :class="{
                        'user-item--presence': u.presence,
                        'user-item--vacation': u.vacations,
                        'user-item--illness': u.absence === 'illness',
                        'user-item--maternity': u.absence === 'maternity',
                        'user-item--self-release': u.absence === 'self-release',
                        'user-item--shift-change': u.absence === 'shift-change',
                        'user-item--clickable': true,
                      }"
                      :title="u.comment"
                      v-for="u in d.schedules[i].users"
                      :key="`td-${j}-${u._id}`"
                      @click="
                        toggleAbsence(
                          d.fullDate,
                          u,
                          d.schedules[i].scheduleGroup,
                          d.schedules[i]._id,
                          i,
                          d.schedules[i].shift - 1
                        )
                      "
                    >
                      {{ u.name }}
                      <transition name="fade" appear>
                        <span
                          class="circle circle--mini"
                          :class="`circle--orange`"
                          v-if="u.absence === 'shift-change'"
                        >
                          {{ u.shift || "П" }}
                        </span>
                      </transition>
                    </p>
                  </main>
                  <p class="center" v-else>
                    <template v-if="d.schedules[i].scheduleGroup._id"
                      >Няма данни</template
                    >
                  </p>
                </template>
                <!-- </template> -->
              </td>
            </template>
          </tr>
        </tbody>
      </table>
    </main>
    <AbsenceModal
      v-if="selectedDate && selectedUser"
      :user="selectedUser"
      :date="selectedDate"
      :scheduleGroup="selectedScheduleGroup"
      :schedule="selectedSchedule"
      :shift="selectedShift"
      @close="closeAbsence"
    />
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import AbsenceModal from "./AbsenceModal";
export default {
  components: {
    AbsenceModal,
  },
  computed: {
    ...mapGetters(["currentUser"]),
  },
  watch: {
    $route: {
      handler: "getSchedules",
      immediate: true,
    },
  },
  data() {
    return {
      today: new Date(),
      date: null,
      schedules: [],
      dates: [],
      selectedDate: null,
      selectedUser: null,
      selectedSchedule: null,
      selectedScheduleGroup: null,
      selectedShift: null,
      indexOfSelectedSchedule: null,

      totalSchedules: 0,
      currentSchedule: 0,
      initializations: 0,
    };
  },
  methods: {
    getSchedules() {
      this.initializations += 1;
      this.schedules = [];
      let url = `/users/all-schedules?date=${this.$route.query.date}`;
      if (this.$route.name === "scheduleShiftAll") url += "&all=true";
      this.$apiService.get(url).then((res) => {
        for (let schedule of res.data) {
          this.schedules.push(schedule);
        }

        this.getDates();
      });
    },
    sleep(ms) {
      return new Promise((resolve) => setTimeout(resolve, ms));
    },
    getDates() {
      const initialization = this.initializations;
      this.dates = [];
      this.totalSchedules = 0;
      this.currentSchedule = 0;
      this.date = this.$route.query.date
        ? new Date(this.$route.query.date)
        : new Date();

      this.$apiService.get(`/days/week/${this.date}`).then(async (res) => {
        this.dates = res.data;

        this.totalSchedules = this.schedules.length * this.dates.length;

        for (let d of this.dates) {
          this.$set(d, "schedules", []);
          if (this.initializations !== initialization) break;

          for (let schedule of this.schedules) {
            if (this.initializations !== initialization) break;
            const s = { ...schedule };
            d.schedules.push(s);

            await this.getScheduleGroupForDate(d, s);

            // await this.sleep(50);

            this.currentSchedule += 1;
          }
        }
      });
    },
    getScheduleGroupForDate(d, s) {
      return new Promise((res) => {
        this.$set(s, "loading", true);
        let url = `/schedule-groups/${s._id}/${d.fullDate}`;

        this.$apiService
          .get(url)
          .then(async (resp) => {
            this.$set(s, "scheduleGroup", resp.data || {});

            if (s.scheduleGroup._id) {
              this.generatePattern(d, s);
              await this.getUsers(d, s);
            }
          })
          .then(() => {
            this.$set(s, "loading", false);
            res();
          });
      });
    },
    generatePattern(d, s) {
      if (d.holiday && s.scheduleGroup.skipHolidays) {
        return this.$set(s, "shift", null);
      }

      const firstDayOfScheduleGroup = new Date(s.scheduleGroup.dateFrom);
      const firstDayOfWeek = new Date(d.fullDate);
      const difference = Math.ceil(
        Math.abs(firstDayOfWeek - firstDayOfScheduleGroup) /
          (1000 * 60 * 60 * 24)
      );
      const offset = difference % s.scheduleGroup.pattern.length;

      let position = offset
        ? offset - 1
        : s.scheduleGroup.schedules.pattern.length - 1;

      this.$set(s, "shift", s.scheduleGroup.schedules.pattern[position]);
    },
    getUsers(d, s) {
      return new Promise((res, rej) => {
        let url = `/schedule-groups/${s._id}/${d.fullDate}/users`;
        url += `?weekend=${d.weekend}`;
        url += `&shift=${s.shift ? true : false}`;

        if (this.$route.name === "scheduleShiftAll") url += "&all=true";
        this.$apiService
          .get(url)
          .then((resp) => {
            this.$set(s, "users", resp.data || []);
            res();
          })
          .catch((err) => rej(err));
      });
    },
    toggleAbsence(d, u, s, schedule, i, sh) {
      this.selectedDate = d;
      this.selectedUser = u;
      this.selectedScheduleGroup = s;
      this.selectedSchedule = schedule;
      this.indexOfSelectedSchedule = i;

      if (sh > -1) {
        this.selectedShift = s.shifts[sh].name;
      } else this.selectedShift = null;
    },
    closeAbsence(update) {
      if (update) {
        for (let d of update.totalDays) {
          const date = this.dates.find((x) => {
            return x.fullDate === d;
          });

          if (date) {
            if (date.schedules[this.indexOfSelectedSchedule].users) {
              const u = date.schedules[this.indexOfSelectedSchedule].users.find(
                (x) => {
                  return x._id === update.user;
                }
              );

              if (u) {
                if (update.deleted) {
                  this.$set(u, "absence", null);
                  this.$set(u, "shift", null);
                  this.$set(u, "comment", null);
                } else {
                  this.$set(u, "absence", update.type);
                  this.$set(u, "comment", update.comment);
                  if (update.type === "shift-change") {
                    this.$set(u, "shift", update.shift);
                  }
                }
              }
            }
          }
        }
      }
      this.toggleAbsence();
    },
  },
  mounted() {
    this.today.setHours(0, 0, 0, 0);
  },
  destroyed() {
    this.initializations = 0;
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.schedule {
  height: 100%;
  width: 100%;
  padding: 10px;
  position: relative;
  > main {
    table {
      // border: 1px solid red;
      border-collapse: collapse;
      width: 100%;
      th,
      td {
        border: 1px solid #d3d3d3;
        // max-width: 1%;
        // width: 1%;
        // max-width: 14.2857143%;
        // width: 14.2857143%;
        width: calc((100% / 7));
        &:first-child {
          border-left: 1px solid transparent;
        }
        &:last-child {
          border-right: 1px solid transparent;
        }
      }
      th {
        border-top: 1px solid transparent;
        font-weight: 500;
        padding: 9px;
        p {
          font-size: $px16;
          margin-bottom: 3px;
        }
        span {
          font-size: $px11;
          text-transform: uppercase;
        }
      }
      td {
        border-bottom: 1px solid transparent;
        padding: 6px 10px;
        vertical-align: top;
        header {
          align-items: center;
          background-color: rgba($color: #ababab, $alpha: 0.2);
          border-radius: 4px;
          display: flex;
          height: 40px;
          justify-content: space-between;
          margin-bottom: $px14;
          padding: 10px;
          .icon-and-shift {
            align-items: center;
            display: flex;
          }
          .icon {
            font-size: 20px;
            margin-right: 10px;
          }
          .user-schedule {
            font-size: $px14;
            font-weight: 500;
            margin-right: 10px;
          }
          .circle {
            margin: 0;
          }
          &.header--1 {
            background-color: rgba($color: #005fc2, $alpha: 0.2);
          }
          &.header--2 {
            background-color: rgba($color: #00c273, $alpha: 0.2);
          }
          &.header--3 {
            background-color: rgba($color: #9600c2, $alpha: 0.2);
          }
        }
        main {
          .user-item {
            border: 1px solid #dedede;
            border-radius: 2px;
            font-size: $px11;
            font-weight: 500;
            margin-bottom: 5px;
            padding: 7px 28px 7px 10px;
            // text-align: center;
            position: relative;
            text-transform: uppercase;
            &.user-item--presence {
              background-color: #edffc8;
              border: 1px solid #6c9f01;
            }
            &.user-item--illness {
              background-color: #ffcccc;
              border: 1px solid #ce614b;
            }
            &.user-item--maternity {
              background-color: #fdcbfb;
              border: 1px solid #8400b2;
            }
            &.user-item--self-release {
              background-color: #d4d4d4;
              border: 1px solid #505050;
            }
            &.user-item--vacation {
              background-color: #cbfdfd;
              border: 1px solid #14d2f9;
            }
            // &.user-item--shift-change {
            // }
            &.user-item--clickable {
              @include hover();
              &:not(.user-item--illness):not(.user-item--self-release):not(.user-item--shift-change):not(.user-item--vacation):not(.user-item--maternity):not(.user-item--presence):hover {
                background-color: rgba(222, 222, 222, 0.5);
              }
            }
            .circle {
              margin: 0;
              position: absolute;
              right: 4px;
              top: 50%;
              @include transform(translateY(-50%));
            }
          }
        }
      }
    }
  }
}
.center {
  font-size: $px11;
  font-style: italic;
  opacity: 0.5;
  text-align: center;
}
.percent {
  position: absolute;
  bottom: 1rem;
  left: 0;
  width: 100%;
}
.loading {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
  img {
    width: 320px;
    max-height: 100%;
  }
}
</style>
