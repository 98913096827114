<template>
  <div class="lps">
    <h1>ЛПС</h1>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
.lps {
  align-items: center;
  display: flex;
  justify-content: center;
  height: 100%;
  width: 100%;
}
</style>