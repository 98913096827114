<template>
  <div
    class="input-wrapper"
    :class="{
      'input-wrapper--invalid': invalid,
      'input-wrapper--datepicker': datepicker,
      'input-wrapper--white':
        $route.name === 'newVacation' || $route.name === 'editVacation',
    }"
  >
    <label v-if="label">
      {{ label }}
    </label>
    <!-- DROPDOWNS -->
    <template v-if="list">
      <div class="dropdown-toggle">
        <template v-if="!value">
          <input
            type="text"
            :placeholder="placeholder"
            :disabled="disabled"
            v-model="search"
            @focus="(open = true), $emit('focus')"
            @blur="open = false"
            ref="dd"
          />
          <span
            class="icon"
            :class="{ icon__reversed: up }"
            @click="$refs.dd.focus()"
          >
            E
          </span>
        </template>
        <template v-else>
          <input
            type="text"
            :value="prop ? value[prop] : value"
            disabled="true"
          />
          <span class="icon" @click="$emit('input', null)" v-if="!disabled"
            >g</span
          >
        </template>
      </div>
      <div
        class="input-wrapper__dropdown"
        :class="{ 'input-wrapper__dropdown--up': up }"
        v-if="open"
      >
        <p
          v-for="(l, i) in filteredList"
          :key="`dd-l-${i}`"
          @mousedown="$emit('input', l), $emit('item-selected'), (open = false)"
        >
          {{ prop ? l[prop] : l }}
        </p>
        <p v-if="!filteredList.length" class="empty">няма резултати</p>
      </div>
    </template>
    <!-- DATEPICKERS -->
    <div
      class="fake-input"
      :class="{
        'fake-input--active': isDatepickerModalOpen,
        'fake-input--disabled': disabled,
      }"
      @click="disabled ? null : (isDatepickerModalOpen = true)"
      v-else-if="datepicker"
    >
      <template v-if="month">
        {{ value | formatDateMonth }}
      </template>
      <template v-else>
        {{ value | formatDate }}
      </template>
    </div>
    <!-- TIMEPICKER -->
    <div
      class="fake-input"
      :class="{
        'fake-input--active': isTimepickerModalOpen,
        'fake-input--disabled': disabled,
      }"
      @click="disabled ? null : (isTimepickerModalOpen = true)"
      v-else-if="timepicker"
    >
      {{ value || "ЧЧ:ММ" }}
    </div>
    <!-- FILES -->
    <label
      class="fake-input fake-input--file"
      :class="{ 'fake-input--active': false, 'fake-input--disabled': disabled }"
      v-else-if="type === 'file'"
      :for="`file-${_uid}`"
    >
      <span v-if="!files.length">Прикачване на документи (ако има такива)</span>
      <span v-else>
        <template v-if="files.length === 1">1 избран файл</template>
        <template v-else>{{ files.length }} избрани файла</template>
      </span>
      <span class="icon">G</span>
      <input
        type="file"
        :id="`file-${_uid}`"
        multiple
        :disabled="disabled"
        @change="filesSelected"
      />
    </label>
    <!-- STANDART INPUT -->
    <textarea
      v-else-if="type === 'textarea'"
      ref="ta"
      :type="type || 'text'"
      :placeholder="placeholder"
      :value="value"
      :maxlength="maxlength ? maxlength : false"
      :disabled="disabled"
      @input="$emit('input', $event.target.value), resize($event)"
      @focus="resize($event)"
    />
    <input
      :type="type || 'text'"
      :placeholder="placeholder"
      :value="value"
      :maxlength="maxlength ? maxlength : false"
      :disabled="disabled"
      @input="$emit('input', $event.target.value)"
      v-else
    />
    <!-- MODALS -->
    <transition name="fade">
      <DatepickerModal
        v-if="isDatepickerModalOpen"
        :value="value"
        :disabledDates="disabledDates"
        :month="month"
        @selected="dateSelected"
        @close="isDatepickerModalOpen = false"
      />
    </transition>
    <transition name="fade">
      <TimepickerModal
        v-if="isTimepickerModalOpen"
        :value="value"
        :min="min"
        :max="max"
        @input="$emit('input', $event)"
        @close="isTimepickerModalOpen = false"
      />
    </transition>
  </div>
</template>

<script>
import DatepickerModal from "./DatepickerModal";
import TimepickerModal from "./TimepickerModal";
export default {
  props: [
    "value",
    "placeholder",
    "list", // should be array of items
    "prop", // prop is the name you wish to display if list contains objects
    "label",
    "type",
    "disabled",
    "disabledDates", // datepicker only
    "month", // datepicker only
    "datepicker",
    "timepicker",
    "min", // used for timepicker only
    "max", // used for timepicker only
    "invalid",
    "files",
    "maxlength",
    "up", // open over the input
  ],
  components: {
    DatepickerModal,
    TimepickerModal,
  },
  computed: {
    filteredList() {
      if (this.prop) {
        return this.list;
      } else {
        return this.list.filter((x) => {
          return (x + "").toLowerCase().includes(this.search.toLowerCase());
        });
      }
    },
  },
  watch: {
    search() {
      this.$emit("search", this.search);
    },
  },
  data() {
    return {
      open: false,
      isDatepickerModalOpen: false,
      isTimepickerModalOpen: false,
      search: "",
    };
  },
  methods: {
    resize(e, m) {
      let textarea = m ? e : e.target;
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight + 5}px`;
    },
    dateSelected(e) {
      this.$emit("input", e);
      this.$emit("date-selected");
      this.isDatepickerModalOpen = false;
    },
    filesSelected(e) {
      if (e.target.files.length <= 5) {
        this.$emit("files-selected", e.target.files);
      } else {
        this.$snotify.error("Не може да избирате повече от 5 файла");
      }
    },
  },
  mounted(){
    if (this.$refs.ta) this.resize(this.$refs.ta, true);
  }
};
</script>

<style lang="scss" scoped>
@import "@/scss/base.scss";
.input-wrapper {
  position: relative;
  width: 205px;
  @include noselect();
  &.input-wrapper--big {
    width: 400px;
  }
  &.input-wrapper--small {
    width: 100px;
  }
  &.input-wrapper--100 {
    width: 100px;
    .fake-input {
      white-space: nowrap;
    }
  }
  label:not(.fake-input) {
    color: #373737;
    display: inline-flex;
    font-size: $px11;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  .fake-input,
  .dropdown-toggle,
  textarea,
  input {
    align-items: center;
    background-color: #f6f6f6;
    border: 1px solid #d3d3d3;
    border-radius: 2px;
    color: #373737;
    display: inline-flex;
    font-size: $px12;
    height: 36px;
    outline: none;
    padding: 0 $px12;
    width: 100%;
    @include transition($transition);
    &:disabled {
      background-color: #fff;
    }
    &::placeholder {
      color: inherit;
      font-style: italic;
    }
    &.fake-input--active,
    &:focus {
      background-color: #fff;
      box-shadow: 0px 0px 0px 1px #378ef0;
      border: 1px solid #378ef0;
    }
  }
  textarea {
    overflow: hidden;
    resize: none;
    padding-top: 7px;
    height: fit-content;
    @include transition(all 0.3s $cubic);
  }
  .dropdown-toggle {
    align-items: center;
    border: 0;
    cursor: pointer;
    display: inline-flex;
    position: relative;
    padding: 0;
    input {
      width: 100%;
    }
    &:hover {
      background-color: rgba($color: #f6f6f6, $alpha: 0.8);
    }
  }

  .fake-input {
    &:not(.fake-input--disabled) {
      cursor: pointer;
    }
    &.fake-input--file {
      position: relative;
      .icon {
        color: #eac282;
        font-size: 1.5rem;
        right: 0.5rem;
      }
    }
  }

  .icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    @include transform(translateY(-50%));
    &.icon__reversed {
      @include transform(translateY(-60%) scaleY(-1));
    }
  }

  .input-wrapper__dropdown {
    border-radius: 2px;
    background-color: #fff;
    // box-shadow: 0px 0px 0px 1px #378ef0;
    // border: 1px solid #378ef0;
    box-shadow: 0px 1px 4px #00000026;
    border: 1px solid #d3d3d3;
    left: 0;
    position: absolute;
    top: calc(100% + 6px);
    width: 100%;
    z-index: 10;
    > p {
      padding: 0.6rem 0.8rem;
      &:not(.empty) {
        cursor: pointer;
        &:hover {
          background-color: #f6f6f6;
        }
      }
    }
    &.input-wrapper__dropdown--up {
      bottom: 42px;
      top: initial;
    }
  }
  &.input-wrapper--invalid {
    .dropdown-toggle,
    .dropdown-toggle--active,
    input,
    input:focus {
      box-shadow: 0px 0px 0px 1px $darkRed;
      border: 1px solid $darkRed;
    }
  }

  input[type="file"] {
    height: 0.01px;
    left: -100vw;
    opacity: 0;
    position: absolute;
    width: 0.01px;
    z-index: -1;
  }

  &.input-wrapper--white {
    .fake-input,
    .dropdown-toggle,
    textarea,
    input {
      background-color: #fff;
    }
  }
}

@media print {
  .input-wrapper {
    align-items: flex-end;
    display: flex;

    &.input-wrapper--no-flex-print {
      align-items: flex-start;
      flex-direction: column;
    }

    .icon {
      display: none;
    }
    label:not(.fake-input) {
      margin: 0 0.5rem 0 0;
      white-space: nowrap;
    }
    .fake-input,
    .dropdown-toggle,
    textarea,
    input {
      background-color: transparent;
      border: none;
      border-bottom: 1px dashed #505050;
      height: 30px;
      &:disabled {
        background-color: transparent;
      }
      &::placeholder {
        color: transparent;
      }
    }
    &.input-wrapper--datepicker {
      .fake-input {
        width: 100px;
      }
    }
  }
}
</style>
