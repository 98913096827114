<template>
  <div class="modal-wrapper" @click.self="close">
    <transition name="modal" appear>
      <div class="modal">
        <header>
          <div>
            <img class="icon" src="@/assets/img/reports/reports.svg" alt="" />

            <p>ОТЧЕТИ ЗА СВЪРШЕНА РАБОТА В ПЕРИОДА {{ dateFrom | formatDate }} - {{ dateTo | formatDate }}</p>
          </div>

          <img class="icon close" @click="close" src="@/assets/img/reports/close.svg" alt="" />
        </header>

        <main>
          <div
            v-for="(d, i) in days"
            v-show="isValidDate(d.date, i)"
            :key="`${d}-${i}`"
            class="report-day"
            :class="setClass(d)"
            @click="openReport(d)"
          >
            <section>
              <p>Отчет {{ d.date | formatDate }}</p>
              <span>{{ getStatus(d) }}</span>
            </section>

            <section>
              <p v-if="!d.report" class="link">Отчитане на задачите</p>
              <p v-else>{{ !d.report.draft ? `Отчетени са ${d.report.tasks.length} задачи` : 'Незавършен отчет' }}</p>
              <span>{{ getStatus(d, true) }}</span>
            </section>
          </div>
        </main>
      </div>
    </transition>

    <ReportModal v-if="isReportModalOpen" @close="closeReportModal" :selectedDay="selectedDay" :vh="vh" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import ReportModal from '@/components/ReportModal';
export default {
  props: ['reports', 'vh'],
  components: {
    ReportModal,
  },
  computed: {
    ...mapGetters(['currentUser', 'activeCompany']),
    dateFrom() {
      return this.vh.dateFrom;
    },
    dateTo() {
      return this.vh.dateTo;
    },
    shifts() {
      return this.vh.shifts;
    },
  },
  watch: {
    $route: {
      handler: 'getDays',
      immediate: true,
    },
    reports() {
      if (this.days.length) {
        this.handleReports();
      }
    },
  },
  data() {
    return {
      days: [],
      isReportModalOpen: false,
      selectedDay: '',
      today: new Date(),
    };
  },
  methods: {
    isValidDate(d, index) {
      let date = new Date(d).toString();
      if (this.activeCompany === 'КЦМ 2000' && !date.includes('Sun') && !date.includes('Sat')) return true;
      if (this.activeCompany === 'КЦМ АД') return this.shifts[index] ? true : false;
    },
    close() {
      this.$emit('close');
    },
    closeReportModal() {
      this.isReportModalOpen = false;
      this.$emit('getReports');
    },
    setClass(d) {
      // you cant make reports that are before more than 3 days
      const lastPossibleDate = new Date(this.today);
      lastPossibleDate.setDate(lastPossibleDate.getDate() - 3);
      let dayCopy = new Date(d.date);
      let todayCopy = new Date(this.today);
      let dayMidnight = new Date(dayCopy.setHours(0, 0, 0, 0));
      let todayMidnight = new Date(todayCopy.setHours(0, 0, 0, 0));

      return {
        finished: d.report && !d.report.draft,
        draft: d.report ? d.report.draft : false,
        disabled:
          dayMidnight > todayMidnight ||
          (this.currentUser.roles.admin || this.currentUser.roles.hr
            ? false
            : lastPossibleDate.setHours(0, 0, 0, 0) > d.date && !d.report),
      };
    },
    getStatus(d, secondStatus) {
      let report = d.report;
      const lastPossibleDate = new Date(this.today);
      lastPossibleDate.setDate(lastPossibleDate.getDate() - 3);
      let dayCopy = new Date(d.date);
      let todayCopy = new Date(this.today);
      let dayMidnight = new Date(dayCopy.setHours(0, 0, 0, 0));
      let todayMidnight = new Date(todayCopy.setHours(0, 0, 0, 0));
      let lastPossibleDateMidnight = new Date(lastPossibleDate.setHours(0, 0, 0, 0));

      if (report && !report.draft) {
        return secondStatus ? 'Завършен отчет' : `Отчетен на ${this.$options.filters.formatDate(report.createdAt)}`;
      } else if (report ? report.draft : false) {
        return secondStatus ? 'Незавършен отчет' : 'Започнат';
      } else if (dayMidnight > todayMidnight) {
        return secondStatus ? 'Не може да отчетете бъдещ период' : 'Предстои';
      } else if (dayMidnight.toString() === todayMidnight.toString()) {
        return secondStatus ? 'Започнете нов отчет за деня' : 'Днес';
      } else if (lastPossibleDateMidnight > dayMidnight) {
        return secondStatus
          ? this.currentUser.roles.admin || this.currentUser.roles.hr
            ? 'Започнете нов отчет за деня'
            : 'Не може да отчетете ден, изтекъл преди повече от 3 календарни дни'
          : 'Неотчетен';
      } else if (dayMidnight >= lastPossibleDateMidnight) {
        const lastPossibleDateCopy = new Date(this.today);
        lastPossibleDateCopy.setDate(lastPossibleDateCopy.getDate() - 4);

        let diffTime = Math.abs(lastPossibleDateCopy - dayMidnight);
        let diffDays = Math.ceil(diffTime / (1000 * 60 * 60 * 24));

        return secondStatus
          ? `Неотчетен ден. Имате ${diffDays} календарни дни да се отчетете за този ден`
          : 'Неотчетен';
      }
    },
    openReport(day) {
      this.isReportModalOpen = true;
      this.selectedDay = day;
    },
    getDays() {
      let dateOne = new Date(this.dateFrom);
      let dateTwo = new Date(this.dateTo);

      for (let i = dateOne; i <= dateTwo; i.setDate(i.getDate() + 1)) {
        this.days.push({ date: new Date(i), report: null });
      }

      this.handleReports();
    },
    handleReports() {
      this.days.forEach((element) => {
        for (let report of this.reports) {
          let reportDay = new Date(report.createdOn);
          let day = new Date(element.date);

          let reportDaySetHours = new Date(day.setHours(0, 0, 0, 0));
          let daySetHours = new Date(reportDay.setHours(0, 0, 0, 0));

          if (reportDaySetHours.toString() === daySetHours.toString() && !element.report) {
            element.report = report;
          }
        }
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.modal-wrapper {
  align-items: center;
  background-color: rgba($color: #000, $alpha: 0.4);
  display: flex;
  height: 100%;
  justify-content: center;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 10;
  &.modal-wrapper--sidepanel {
    padding-left: $sidepanel;
  }
}

.modal {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 0 60px 0 rgba($color: #000, $alpha: 0.2);
  color: #373737;
  max-width: 700px;
  overflow: hidden;
  width: 100%;

  header {
    align-items: center;
    background: #e4e8ec;
    display: flex;
    padding: 1rem;
    justify-content: space-between;

    > div {
      align-items: center;
      display: flex;
      gap: 10px;
    }
    p {
      font-size: $px12;
      font-weight: 700;
    }
    .close {
      font-size: 2rem;
      @include hover();
    }
  }
  > main {
    max-height: 30vh;
    overflow: auto;
    padding: 1rem 0;
    @include scrollbar(5px, $mainColor);

    .report-day {
      background: #d6e8f9;
      border: 1px solid #3985be;
      border-radius: 6px;
      cursor: pointer;
      display: flex;
      margin: 5px 30px;
      padding: 10px;

      &.finished {
        background: #e9fff4;
        border: 1px solid #85dbb0;
      }
      &.draft {
        background: #fcf8e1;
        border: 1px solid #dbaa79;
      }
      &.disabled {
        background: #f2f8ff;
        border: 1px solid #dedede;
        pointer-events: none;
        .link {
          opacity: 0.5;
        }
      }

      section {
        text-align: left;
        :first-child {
          min-width: 130px;
        }
        &:nth-of-type(2) {
          margin-left: 10px;
          padding-left: 10px;
          border-left: 1px solid #9eafc1;
        }
        p {
          font-weight: bold;
          &.link {
            color: #0099ff;
            text-decoration: underline;
          }
        }
        span {
          font-size: $px12;
          opacity: 0.5;
        }
      }
    }
  }
}
@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .modal-wrapper {
    .modal {
      width: 96%;

      > main {
        max-height: 50vh;

        .report-day {
          margin: 5px 10px;
        }
      }
    }
  }
}
</style>
