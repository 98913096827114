<template>
  <div class="wrapper" :class="{ 'wrapper--in': isAuthenticated }">
    <Importing v-if="isImporting" />
    <div v-else class="app-wrapper" :class="{ 'app-wrapper--sidepanel': isSidePanelOpen }">
      <div id="app">
        <Sidepanel />
        <transition name="fade" mode="out-in">
          <router-view :key="this.$route.path" />
        </transition>
        <Nav />
      </div>
    </div>
    <vue-snotify></vue-snotify>
    <div style="display: none">is this visible?</div>
    <SelectCompanyModal />
  </div>
</template>

<script>
import Sidepanel from './components/SidePanel';
import SelectCompanyModal from './components/SelectCompanyModal';
import Nav from './components/Nav';
import Importing from './views/Importing';
import { mapGetters } from 'vuex';
export default {
  components: {
    Sidepanel,
    SelectCompanyModal,
    Nav,
    Importing,
  },
  computed: {
    ...mapGetters(['isSidePanelOpen', 'isAuthenticated', 'isImporting']),
  },
  watch: {
    $route() {
      this.$store.dispatch('error', null);
      this.$store.dispatch('refreshActiveCompany');
    },
  },
  mounted() {
    // First we get the viewport height and we multiple it by 1% to get a value for a vh unit
    let vh = window.innerHeight * 0.01;
    // Then we set the value in the --vh custom property to the root of the document
    document.documentElement.style.setProperty('--vh', `${vh}px`);

    window.addEventListener('resize', () => {
      // We execute the same script as before
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty('--vh', `${vh}px`);
    });
  },
};
</script>

<style lang="scss">
@import '~vue-snotify/styles/material';
@import './scss/base.scss';
@import './scss/snotify.scss';
@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Bold.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Bold.woff') format('woff');
  font-weight: bold;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-BoldItalic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-BoldItalic.woff') format('woff');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-MediumItalic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-MediumItalic.woff') format('woff');
  font-weight: 500;
  font-style: italic;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Italic.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Italic.woff') format('woff');
  font-weight: normal;
  font-style: italic;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Medium.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Medium.woff') format('woff');
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: 'Google Sans';
  src: url('./assets/fonts/GoogleSans-Regular.woff2') format('woff2'),
    url('./assets/fonts/GoogleSans-Regular.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'icons';
  src: url('./assets/fonts/icons.eot');
  src: url('./assets/fonts/icons.eot?#iefix') format('embedded-opentype'),
    url('./assets/fonts/icons.woff') format('woff'), url('./assets/fonts/icons.ttf') format('truetype'),
    url('./assets/fonts/icons.svg#icons') format('svg');
  font-weight: normal;
  font-style: normal;
}
[class*='icon-']:before {
  display: inline-block;
  font-family: 'icons';
  font-style: normal;
  font-weight: normal;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.icon {
  font-family: 'icons';
}
html {
  background: #fff;
  box-sizing: border-box;
  color: #000;
  font-size: 13px;
  font-family: 'Google Sans', sans-serif;

  *,
  *::before,
  *::after {
    box-sizing: inherit;
  }
}

body {
  height: 100vh;
  margin: 0;
  min-width: 320px;
  padding: 0;

  &.noscroll {
    overflow: hidden;
  }
}

input,
textarea {
  font-family: Arial, Helvetica, sans-serif;
}

.wrapper {
  height: 100%;
  padding: 4px;
  @include transition($transition);
  &.wrapper--in {
    background-color: $mainColor;
    height: calc(100% - #{$nav});
  }
}
.app-wrapper {
  background-color: #fff;
  border-radius: 10px;
  height: 100%;
  overflow: auto;
  @include transition($transition);
}
.app-wrapper--sidepanel {
  padding-left: $sidepanel;
}

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin: 0;
}

a {
  text-decoration: none;
}

.error {
  color: #ff3333;
}

@import './scss/tiny.scss';
@import './scss/circle.scss';

@media screen and (max-width: $s), (orientation: landscape) and (max-width: $landscape) {
  .wrapper {
    &.wrapper--in {
      height: calc(100% - #{$nav-s});
      padding-top: $header-mobile;
    }
  }
}

@media screen and (max-width: $sm) {
  .app-wrapper--sidepanel {
    padding-left: 0;
  }
}

@media print {
  .wrapper {
    height: initial;
    padding: 0;
    &.wrapper--in {
      background-color: initial;
      height: initial;
    }
  }
  .app-wrapper {
    border-radius: 0;
    height: initial;
    overflow: initial;
  }
  .app-wrapper--sidepanel {
    padding-left: 0;
  }

  .no-print {
    display: none !important;
  }
}
</style>
