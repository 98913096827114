<template>
  <div class="table-wrapper">
    <table class="table">
      <slot></slot>
    </table>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss" scoped>
@import '@/scss/base.scss';
.table-wrapper {
  overflow: auto;
  width: 100%;
  @include scrollbar(5px, $mainColor);
}
.table {
  border-collapse: collapse;
  width: 100%;
  th,
  td {
    border-bottom: 1px solid #d3d3d3;
    padding: 14px 10px;
    text-align: left;
    @include transition($transition);
  }
  tbody {
    tr {
      cursor: pointer;
      &:hover {
        td {
          background-color: rgba($color: $lightAccent, $alpha: 0.1);
        }
      }
    }
  }
}
</style>